define("open-event-frontend/templates/components/widgets/forms/ui-checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="{{@class}}" ...attributes>
    {{#if @label}}
      <label>{{@label}}</label>
    {{/if}}
    {{#each this.options as |op|}}
      <div class="menu">
        <UiCheckbox
          @value={{op.value}}
          @name={{this.name}}
          @type={{this.type}}
          @onChange={{action 'childOnChange'}}
          @checked={{op.checked}}/>
            {{t-var op.label}}
      </div>
    {{/each}}
  </div>
        
  */
  {
    "id": "XvqTBJa7",
    "block": "{\"symbols\":[\"op\",\"@label\",\"@class\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,3]]]],[17,4],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[32,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n      \"],[8,\"ui-checkbox\",[],[[\"@value\",\"@name\",\"@type\",\"@onChange\",\"@checked\"],[[32,1,[\"value\"]],[32,0,[\"name\"]],[32,0,[\"type\"]],[30,[36,0],[[32,0],\"childOnChange\"],null],[32,1,[\"checked\"]]]],null],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"label\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n      \"]],\"hasEval\":false,\"upvars\":[\"action\",\"t-var\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/widgets/forms/ui-checkbox-group.hbs"
    }
  });
  _exports.default = _default;
});