define("open-event-frontend/transforms/array", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import moment from 'moment';
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }
      // return moment(serialized);
      return JSON.parse(serialized);
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }
      return JSON.stringify(deserialized);
    }
  });
  _exports.default = _default;
});