define("open-event-frontend/utils/dictionary/payment", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentCurrencies = _exports.paymentCountries = void 0;
  var paymentCountries = ['US', 'AL', 'AR', 'AU', 'AT', 'BE', 'BR', 'CA', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HK', 'HU', 'IE', 'IL', 'IN', 'IT', 'JP', 'LV', 'LT', 'LU', 'MY', 'MT', 'MX', 'NL', 'NZ', 'NO', 'PH', 'PL', 'PT', 'SG', 'SK', 'SI', 'ES', 'SE', 'CH', 'TW', 'GB'];
  _exports.paymentCountries = paymentCountries;
  var paymentCurrencies = [{
    paypal: true,
    code: 'PLN',
    symbol: 'zł',
    name: _text.tn.t('Polish zloty'),
    stripe: true,
    alipay: true,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'NZD',
    symbol: 'NZ$',
    name: _text.tn.t('New Zealand dollar'),
    stripe: true,
    alipay: true,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'INR',
    symbol: '₹',
    name: _text.tn.t('Indian rupee'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: true
  }, {
    paypal: true,
    code: 'BRL',
    symbol: 'R$',
    name: _text.tn.t('Brazilian real'),
    stripe: true,
    alipay: true,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'SGD',
    symbol: 'SG$',
    name: _text.tn.t('Singapore dollar'),
    stripe: true,
    alipay: true,
    omise: true,
    paytm: false
  }, {
    paypal: true,
    code: 'CAD',
    symbol: 'C$',
    name: _text.tn.t('Canadian dollar'),
    stripe: true,
    alipay: true,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'HKD',
    symbol: 'HK$',
    name: _text.tn.t('Hong Kong dollar'),
    stripe: true,
    alipay: true,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'CZK',
    symbol: 'Kč',
    name: _text.tn.t('Czech koruna'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'DKK',
    symbol: 'Kr',
    name: _text.tn.t('Danish krone'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'NOK',
    symbol: 'kr',
    name: _text.tn.t('Norwegian krone'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'SEK',
    symbol: 'kr',
    name: _text.tn.t('Swedish krona'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'TWD',
    symbol: 'NT$',
    name: _text.tn.t('New Taiwan dollar'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'USD',
    symbol: '$',
    name: _text.tn.t('United States dollar'),
    stripe: true,
    alipay: true,
    omise: true,
    paytm: false
  }, {
    paypal: true,
    code: 'HUF',
    symbol: 'Ft',
    name: _text.tn.t('Hungarian forint'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'JPY',
    symbol: '‎¥‎',
    name: _text.tn.t('Japanese yen'),
    stripe: true,
    alipay: true,
    omise: true,
    paytm: false
  }, {
    paypal: true,
    code: 'ILS',
    symbol: '₪',
    name: _text.tn.t('Israeli new sheqel'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'EUR',
    symbol: '€',
    name: _text.tn.t('European Euro'),
    stripe: true,
    alipay: true,
    omise: true,
    paytm: false
  }, {
    paypal: true,
    code: 'MXN',
    symbol: '$',
    name: _text.tn.t('Mexican peso'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'PHP',
    symbol: '₱',
    name: _text.tn.t('Philippine peso'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'AUD',
    symbol: 'A$',
    name: _text.tn.t('Australian dollar'),
    stripe: true,
    alipay: true,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'GBP',
    symbol: '£',
    name: _text.tn.t('British pound'),
    stripe: true,
    alipay: true,
    omise: true,
    paytm: false
  }, {
    paypal: true,
    code: 'THB',
    symbol: '฿',
    name: _text.tn.t('Thai baht'),
    stripe: true,
    alipay: false,
    omise: true,
    paytm: false
  }, {
    paypal: true,
    code: 'RUB',
    symbol: 'R',
    name: _text.tn.t('Russian ruble'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'MYR',
    symbol: 'RM',
    name: _text.tn.t('Malaysian ringgit'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }, {
    paypal: true,
    code: 'CHF',
    symbol: 'Sf.',
    name: _text.tn.t('Swiss franc'),
    stripe: true,
    alipay: false,
    omise: false,
    paytm: false
  }];
  _exports.paymentCurrencies = paymentCurrencies;
});