define("open-event-frontend/templates/components/ui-table/cell/cell-user-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if @extraRecords.user.isProfilePublic}}
      <img class="ui tiny image" src="{{or @record '/images/placeholders/avatar.png'}}" alt="Logo">
  {{else}}
      <img class="ui tiny image" src="{{'/images/placeholders/avatar.png'}}" alt="Logo">
  {{/if}}
  
  */
  {
    "id": "uhFeP1ws",
    "block": "{\"symbols\":[\"@record\",\"@extraRecords\"],\"statements\":[[6,[37,1],[[32,2,[\"user\",\"isProfilePublic\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[14,0,\"ui tiny image\"],[15,\"src\",[31,[[30,[36,0],[[32,1],\"/images/placeholders/avatar.png\"],null]]]],[14,\"alt\",\"Logo\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[14,0,\"ui tiny image\"],[15,\"src\",[31,[\"/images/placeholders/avatar.png\"]]],[14,\"alt\",\"Logo\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-user-image.hbs"
    }
  });
  _exports.default = _default;
});