define("open-event-frontend/components/forms/orders/order-form", ["exports", "open-event-frontend/mixins/form", "moment-timezone", "lodash-es", "open-event-frontend/utils/validators", "open-event-frontend/utils/dictionary/genders", "open-event-frontend/utils/dictionary/age-groups", "open-event-frontend/utils/dictionary/demography", "open-event-frontend/utils/dictionary/native-language", "open-event-frontend/utils/dictionary/fluent-language", "open-event-frontend/utils/dictionary/home-wikis", "open-event-frontend/utils/dictionary/boolean_complex", "open-event-frontend/utils/dictionary/wiki-scholarship"], function (_exports, _form, _momentTimezone, _lodashEs, _validators, _genders, _ageGroups, _demography, _nativeLanguage, _fluentLanguage, _homeWikis, _boolean_complex, _wikiScholarship) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    router: Ember.inject.service(),
    autoScrollToErrors: false,
    buyerFirstName: Ember.computed.oneWay('buyerHasFirstName'),
    buyerLastName: Ember.computed.oneWay('buyerHasLastName'),
    buyer: Ember.computed.readOnly('data.user'),
    buyerHasFirstName: Ember.computed.readOnly('data.user.firstName'),
    buyerHasLastName: Ember.computed.readOnly('data.user.lastName'),
    holders: Ember.computed('data.attendees', 'buyer', function () {
      return this.data.attendees;
    }),
    isPaidOrder: Ember.computed('data', function () {
      if (!this.data.amount) {
        this.data.set('paymentMode', 'free');
        return false;
      }
      return true;
    }),
    isBillingInfoNeeded: Ember.computed('event', 'data.isBillingEnabled', function () {
      return this.event.isBillingInfoMandatory || this.data.isBillingEnabled;
    }),
    getRemainingTime: Ember.computed('settings', function () {
      var orderExpiryTime = this.settings.orderExpiryTime;
      var willExpireAt = this.data.createdAt.add(orderExpiryTime, 'minutes');
      this.timer(willExpireAt, this.data.identifier);
    }),
    timer: function timer(willExpireAt, orderIdentifier) {
      var _this = this;
      Ember.run.later(function () {
        if (_this.session.currentRouteName !== 'orders.new') {
          return;
        }
        var currentTime = (0, _momentTimezone.default)();
        var diff = _momentTimezone.default.duration(willExpireAt.diff(currentTime));
        if (diff > 0) {
          _this.set('getRemainingTime', _momentTimezone.default.utc(diff.asMilliseconds()).format('mm:ss'));
          _this.timer(willExpireAt, orderIdentifier);
        } else {
          _this.set('getRemainingTime', '00:00');
          _this.data.set('status', 'expired');
          _this.data.reload();
          _this.router.transitionTo('orders.expired', orderIdentifier);
        }
      }, 1000);
    },
    getValidationRules: function getValidationRules() {
      var _this2 = this;
      var firstNameValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your first name.')
        }]
      };
      var lastNameValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your last name.')
        }]
      };
      var emailValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your email.')
        }, {
          type: 'regExp',
          value: _validators.validEmail,
          prompt: this.l10n.t('Please enter a valid email address.')
        }]
      };
      var genderValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select categories that describe your gender identity.')
        }]
      };
      var ageGroupValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select your age group.')
        }]
      };
      var acceptReceiveEmailsValidation = {
        rules: [{
          type: 'checked',
          value: false,
          prompt: this.l10n.t('You need to agree to the condition of the organizer to receive emails in order to continue the order process.')
        }]
      };
      var acceptVideoRecordingValidation = {
        rules: [{
          type: 'checked',
          value: false,
          prompt: this.l10n.t('In order to complete the order process you need to agree to the photo & video & text consent.')
        }]
      };
      var acceptShareDetailsValidation = {
        rules: [{
          type: 'checked',
          value: false,
          prompt: this.l10n.t('You need to agree to the condition of the organizer to share information among event partners in order to continue the order process. Such a requirement could be necessary in order to provide the event services.')
        }]
      };
      var addressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your address.')
        }]
      };
      var cityValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your city.')
        }]
      };
      var stateValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your state.')
        }]
      };
      var countryValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select your country.')
        }]
      };
      var jobTitleValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your job title.')
        }]
      };
      var phoneValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter a mobile number.')
        }]
      };
      var taxBusinessInfoValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your tax business info.')
        }]
      };
      var billingAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your billing address.')
        }]
      };
      var homeAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your home address.')
        }]
      };
      var homeWikiValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your home wiki.')
        }]
      };
      var wikiScholarshipValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your wiki scholarship.')
        }]
      };
      var shippingAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your shipping address.')
        }]
      };
      var companyValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your organisation.')
        }]
      };
      var workAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your work address.')
        }]
      };
      var workPhoneValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your work phone.')
        }]
      };
      var websiteRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter URL of website.')
        }, {
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid URL.')
        }]
      };
      var blogRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter URL of website.')
        }, {
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid URL.')
        }]
      };
      var twitterRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Twitter link.')
        }, {
          type: 'regExp',
          value: _validators.validTwitterProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Twitter profile URL.')
        }]
      };
      var facebookRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Facebook link.')
        }, {
          type: 'regExp',
          value: _validators.validFacebookProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Facebook account URL.')
        }]
      };
      var githubRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter GitHub link.')
        }, {
          type: 'regExp',
          value: _validators.validGithubProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid GitHub profile URL.')
        }]
      };
      var instagramRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Instagram link')
        }, {
          type: 'regExp',
          value: _validators.validInstagramProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Instagram account URL.')
        }]
      };
      var linkedinRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Linkedin link.')
        }, {
          type: 'regExp',
          value: _validators.validLinkedinProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Linkedin account URL.')
        }]
      };
      var isConsentFormFieldValidation = {
        rules: [{
          type: 'checked',
          prompt: this.l10n.t('Please enter Code of conduct consent.')
        }]
      };
      var isConsentOfRefundPolicyValidation = {
        optional: true,
        rules: [{
          type: 'checked',
          prompt: this.l10n.t('Please consent to the Refund Policy.')
        }]
      };
      var nativeLanguageValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Language Form.')
        }]
      };
      var fluentLanguageValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Language Form.')
        }]
      };
      var validationRules = {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          email: {
            identifier: 'email',
            rules: [{
              type: 'regExp',
              value: _validators.validEmail,
              prompt: this.l10n.t('Please enter a valid email address.')
            }]
          },
          country: {
            identifier: 'country',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select your country.')
            }]
          },
          taxBusinessInfo: {
            identifier: 'taxBusinessInfo',
            optional: true
          },
          address: {
            identifier: 'address',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your address.')
            }]
          },
          city: {
            identifier: 'city',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your city.')
            }]
          },
          zipCode: {
            identifier: 'zip_code',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your ZIP code.')
            }]
          },
          payVia: {
            identifier: 'payment_mode',
            rules: [{
              type: 'checked',
              prompt: this.l10n.t('Please specify your choice of payment method.')
            }]
          }
        }
      };
      var validationMap = {
        firstname: firstNameValidation,
        lastname: lastNameValidation,
        email: emailValidation,
        gender: genderValidation,
        ageGroups: ageGroupValidation,
        address: addressValidation,
        acceptReceiveEmails: acceptReceiveEmailsValidation,
        acceptVideoRecording: acceptVideoRecordingValidation,
        acceptShareDetails: acceptShareDetailsValidation,
        city: cityValidation,
        state: stateValidation,
        country: countryValidation,
        jobTitle: jobTitleValidation,
        phone: phoneValidation,
        taxBusinessInfo: taxBusinessInfoValidation,
        billingAddress: billingAddressValidation,
        homeAddress: homeAddressValidation,
        homeWiki: homeWikiValidation,
        wikiScholarship: wikiScholarshipValidation,
        shippingAddress: shippingAddressValidation,
        company: companyValidation,
        workAddress: workAddressValidation,
        workPhone: workPhoneValidation,
        website: websiteRequiredValidation,
        blog: blogRequiredValidation,
        twitter: twitterRequiredValidation,
        facebook: facebookRequiredValidation,
        github: githubRequiredValidation,
        instagram: instagramRequiredValidation,
        linkedin: linkedinRequiredValidation,
        native_language: nativeLanguageValidation,
        fluent_language: fluentLanguageValidation,
        is_consent_form_field: isConsentFormFieldValidation,
        is_consent_of_refund_policy: isConsentOfRefundPolicyValidation
      };
      this.allFields.attendee.forEach(function (field, index) {
        var fieldIdentifier = field.fieldIdentifier;
        var validationRuleKey = "".concat(fieldIdentifier, "_required_").concat(index);
        if (validationMap[fieldIdentifier]) {
          validationRules.fields[validationRuleKey] = validationMap[fieldIdentifier];
        } else {
          if (field.type === 'checkbox') {
            validationRules.fields["".concat(field.fieldIdentifier, "_required_").concat(index)] = {
              rules: [{
                type: 'checked',
                prompt: _this2.l10n.t('Please select your {{field}}.', {
                  field: field.name
                })
              }]
            };
          } else {
            validationRules.fields["".concat(field.fieldIdentifier, "_required_").concat(index)] = {
              rules: [{
                type: 'empty',
                prompt: _this2.l10n.t('Please enter your {{field}}.', {
                  field: field.name
                })
              }]
            };
          }
        }
      });
      return validationRules;
    },
    allFields: Ember.computed('fields', function () {
      var _this$fields$toArray, _this$fields$toArray2;
      var requiredFixed = (_this$fields$toArray = this.fields.toArray()) === null || _this$fields$toArray === void 0 ? void 0 : _this$fields$toArray.filter(function (field) {
        return field.isFixed;
      });
      var current_locale = this.cookies.read('current_locale');
      var customFields = (0, _lodashEs.orderBy)((_this$fields$toArray2 = this.fields.toArray()) === null || _this$fields$toArray2 === void 0 ? void 0 : _this$fields$toArray2.filter(function (field) {
        var _field$translations;
        var isFixed = field.isFixed,
          main_language = field.main_language;
        field.nameConvert = field.name;
        if (field.name === 'Consent of refund policy') {
          field.nameConvert = 'I agree to the terms of the refund policy of the event.';
        }
        if (main_language && main_language.split('-')[0] === current_locale || !field.translations || !field.translations.length) {
          field.transName = field.name;
        } else if ((_field$translations = field.translations) !== null && _field$translations !== void 0 && _field$translations.length) {
          var transName = field.translations.filter(function (trans) {
            return trans.language_code.split('-')[0] === current_locale;
          });
          if (transName.length) {
            field.transName = transName[0].name;
          } else {
            field.transName = field.name;
          }
        } else {
          field.transName = field.name;
        }
        return !isFixed;
      }), ['position']);
      return (0, _lodashEs.groupBy)(requiredFixed.concat(customFields), function (field) {
        return field.get('form');
      });
    }),
    genders: (0, _lodashEs.orderBy)(_genders.genders, 'position'),
    ageGroups: (0, _lodashEs.orderBy)(_ageGroups.ageGroups, 'position'),
    countries: (0, _lodashEs.orderBy)(_demography.countries, 'name'),
    nativeLanguage: (0, _lodashEs.orderBy)(_nativeLanguage.nativeLanguage, 'position'),
    fluentLanguage: (0, _lodashEs.orderBy)(_fluentLanguage.fluentLanguage, 'position'),
    homeWikis: (0, _lodashEs.orderBy)(_homeWikis.homeWikis, 'item'),
    wikiScholarship: (0, _lodashEs.orderBy)(_wikiScholarship.wikiScholarship, 'position'),
    booleanComplex: (0, _lodashEs.orderBy)(_boolean_complex.booleanComplex, 'position'),
    currentLocale: Ember.computed('cookies.current_locale', function () {
      return this.cookies.read('current_locale');
    }),
    getData: function getData() {
      return 'hello';
    },
    actions: {
      submit: function submit(data) {
        var _this3 = this;
        this.onValid(function () {
          var currentUser = _this3.data.user;
          currentUser.set('firstName', _this3.buyerFirstName);
          currentUser.set('lastName', _this3.buyerLastName);
          _this3.sendAction('save', data);
        });
      },
      triggerSameAsBuyerOption: function triggerSameAsBuyerOption(holder) {
        holder.set('sameAsBuyer', !holder.sameAsBuyer);
        if (holder.sameAsBuyer) {
          holder.set('firstname', this.buyerFirstName);
          holder.set('lastname', this.buyerLastName);
          holder.set('email', this.buyer.content.email);
        } else {
          holder.set('firstname', '');
          holder.set('lastname', '');
          holder.set('email', '');
        }
      },
      updateLanguageFormsSelection: function updateLanguageFormsSelection(checked, changed, selectedOptions, holder, field) {
        holder.set(field.fieldIdentifier, selectedOptions.map(function (select) {
          return select.value;
        }).join(','));
      },
      updateGendersSelection: function updateGendersSelection(checked, changed, selectedOptions, holder, field) {
        holder.set(field.fieldIdentifier, selectedOptions.map(function (select) {
          return select.value;
        }).join(','));
      }
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this._getRemainingTimeId);
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});