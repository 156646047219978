define("open-event-frontend/helpers/value-field-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.valueFieldLink = valueFieldLink;
  function valueFieldLink(data) {
    var field = '';
    if (data) {
      field = data;
    }
    return Ember.String.htmlSafe(field);
  }
  var _default = Ember.Helper.helper(valueFieldLink);
  _exports.default = _default;
});