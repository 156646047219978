define("open-event-frontend/models/users-events-role", ["exports", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    event: (0, _relationships.belongsTo)('event'),
    role: (0, _relationships.belongsTo)('role'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});