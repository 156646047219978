define("open-event-frontend/templates/groups/edit/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <GroupNav @group={{this.model.group}} />
  <div class="row">
    <div class="sixteen wide column">
      <Forms::group::GroupSettingsForm
        @group={{this.model.group}}
        @events={{this.model.filteredEvents}}
        @isLoading={{this.isLoading}} />
    </div>
  </div>
  
  */
  {
    "id": "Cj4gfK8k",
    "block": "{\"symbols\":[],\"statements\":[[8,\"group-nav\",[],[[\"@group\"],[[32,0,[\"model\",\"group\"]]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"forms/group/group-settings-form\",[],[[\"@group\",\"@events\",\"@isLoading\"],[[32,0,[\"model\",\"group\"]],[32,0,[\"model\",\"filteredEvents\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/groups/edit/settings.hbs"
    }
  });
  _exports.default = _default;
});