define("open-event-frontend/helpers/rich-text-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.richTextLink = richTextLink;
  function richTextLink(data) {
    var fieldName = data[0];
    if (data[1]) {
      fieldName += " - ".concat(data[1]);
    }
    if (data[2]) {
      fieldName += '<div class="ui icon d-inline" data-tooltip="Custom Field"><i class="info icon"></i></div>';
    }
    return Ember.String.htmlSafe("<span>".concat(fieldName, "</span>"));
  }
  var _default = Ember.Helper.helper(richTextLink);
  _exports.default = _default;
});