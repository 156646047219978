define("open-event-frontend/templates/components/modals/event-transfer-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="header">
    {{t 'Are you sure you would like to transfer this event?'}}
    <div class="muted small text">
      {{t 'Transferring the event to another user will lead to you losing all the owner rights.'}}
    </div>
    <div class="muted small text">
      {{t 'On successful transfer of event you will be downgraded to Organizer.'}}
    </div>
  </div>
  <div class="content">
    <div class="ui {{if this.isLoading 'loading'}} form" autocomplete="off">
      <div class="field">
        <div class="label">
          {{t 'Please enter the event-name to confirm that you want to transfer the event.'}}
        </div>
        <Input @type="text" @name="confirm_name" @value={{this.confirmEventName}} required={{true}} />
      </div>
    </div>
  </div>
  <div class="actions">
    <button type="button" class="ui black button" {{action 'close'}}>
      {{t 'Cancel'}}
    </button>
    <button {{action this.openConfirmEventTransferModal}} class="ui red button {{if this.isNameDifferent 'disabled'}}">
      {{t 'Proceed'}}
    </button>
  </div>
  
  */
  {
    "id": "zLTgUJPP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Are you sure you would like to transfer this event?\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"muted small text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Transferring the event to another user will lead to you losing all the owner rights.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"muted small text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"On successful transfer of event you will be downgraded to Organizer.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,1],[[32,0,[\"isLoading\"]],\"loading\"],null],\" form\"]]],[14,\"autocomplete\",\"off\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Please enter the event-name to confirm that you want to transfer the event.\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"input\",[[16,\"required\",true]],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"confirm_name\",[32,0,[\"confirmEventName\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"ui red button \",[30,[36,1],[[32,0,[\"isNameDifferent\"]],\"disabled\"],null]]]],[4,[38,2],[[32,0],[32,0,[\"openConfirmEventTransferModal\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Proceed\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/event-transfer-modal.hbs"
    }
  });
  _exports.default = _default;
});