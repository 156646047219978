define("open-event-frontend/templates/components/ui-table/cell/cell-code-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui vertical compact basic buttons">
    <UiPopup @content={{t "Edit"}} @class="ui icon button" @position="left center">
      <i class="edit icon"></i>
    </UiPopup>
    <UiPopup @content={{t "Deactivate"}} @class="ui icon button" @position="left center">
      <i class="remove outline icon"></i>
    </UiPopup>
    <UiPopup @content={{t "Delete"}} @class="ui icon button" @position="left center">
      <i class="trash icon"></i>
    </UiPopup>
  </div>
  
  */
  {
    "id": "FT6RE7B0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui vertical compact basic buttons\"],[12],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@position\"],[[30,[36,0],[\"Edit\"],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"edit icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@position\"],[[30,[36,0],[\"Deactivate\"],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"remove outline icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@position\"],[[30,[36,0],[\"Delete\"],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"trash icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-code-buttons.hbs"
    }
  });
  _exports.default = _default;
});