define("open-event-frontend/templates/events/view/sessions/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="column sixteen wide">
    <h2 class="ui header center aligned">
      {{t 'Create Session'}}
    </h2>
    <div class="ui container">
      <Forms::SessionSpeakerForm
        @addNewSpeaker={{this.addNewSpeaker}}
        @fields={{this.model.form}}
        @data={{this.model}}
        @speakers={{this.model.speakers}}
        @event={{this.model.event}}
        @speakersDetails={{this.model.session.speakers}}
        @viewSpeakerOrder={{sort-by 'position' this.model.session.speakers}}
        @save={{action "save"}}
        @includeSession={{true}}
        @isSessionSpeaker={{true}}
        @isLoading={{this.isLoading}} />
    </div>
  </div>
  
  */
  {
    "id": "cATICjYn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Create Session\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n    \"],[8,\"forms/session-speaker-form\",[],[[\"@addNewSpeaker\",\"@fields\",\"@data\",\"@speakers\",\"@event\",\"@speakersDetails\",\"@viewSpeakerOrder\",\"@save\",\"@includeSession\",\"@isSessionSpeaker\",\"@isLoading\"],[[32,0,[\"addNewSpeaker\"]],[32,0,[\"model\",\"form\"]],[32,0,[\"model\"]],[32,0,[\"model\",\"speakers\"]],[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"session\",\"speakers\"]],[30,[36,1],[\"position\",[32,0,[\"model\",\"session\",\"speakers\"]]],null],[30,[36,2],[[32,0],\"save\"],null],true,true,[32,0,[\"isLoading\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"sort-by\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/sessions/create.hbs"
    }
  });
  _exports.default = _default;
});