define("open-event-frontend/templates/admin/events/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="sixteen wide column">
    <Events::EventImportSection />
    <Events::ImportsHistorySection />
  </div>
  
  */
  {
    "id": "fX9S82ST",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[8,\"events/event-import-section\",[],[[],[]],null],[2,\"\\n  \"],[8,\"events/imports-history-section\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/events/import.hbs"
    }
  });
  _exports.default = _default;
});