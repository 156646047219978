define("open-event-frontend/templates/components/ui-table/cell/events/cell-download-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <a href="{{@extraRecords.invoicePdfUrl}}" target="_blank" rel="noopener noreferrer">
    {{@record}}
  </a>
  
  */
  {
    "id": "gx2VHWn8",
    "block": "{\"symbols\":[\"@extraRecords\",\"@record\"],\"statements\":[[10,\"a\"],[15,6,[31,[[32,1,[\"invoicePdfUrl\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n  \"],[1,[32,2]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/cell-download-invoice.hbs"
    }
  });
  _exports.default = _default;
});