define("open-event-frontend/templates/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui container">
    <UnverifiedUserMessage />
    {{#if this.model.event.name}}
      <h2 class="weight-300">{{t 'Creating'}} <span class="weight-500">{{this.model.event.name}}</span></h2>
    {{else}}
      <h2 class="weight-300">{{t 'Create an Event'}}</h2>
    {{/if}}
    <div class="ui one column centered stacked grid event wizard">
      <div class="column mobile hidden">
        <Widgets::StepsIndicator @steps={{this.model.steps}} @currentStep={{1}} @enableAll={{false}} @disableLinks={{true}} />
      </div>
      <div class="column">
        <Forms::Wizard::BasicDetailsStep @data={{this.model}} @save="save" @move="move" @isCreate={{true}} @isLoading={{this.isLoading}} />
      </div>
    </div>
  </div>
  
  */
  {
    "id": "q1WFglfg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[8,\"unverified-user-message\",[],[[],[]],null],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"event\",\"name\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"h2\"],[14,0,\"weight-300\"],[12],[1,[30,[36,0],[\"Creating\"],null]],[2,\" \"],[10,\"span\"],[14,0,\"weight-500\"],[12],[1,[32,0,[\"model\",\"event\",\"name\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"h2\"],[14,0,\"weight-300\"],[12],[1,[30,[36,0],[\"Create an Event\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"ui one column centered stacked grid event wizard\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column mobile hidden\"],[12],[2,\"\\n      \"],[8,\"widgets/steps-indicator\",[],[[\"@steps\",\"@currentStep\",\"@enableAll\",\"@disableLinks\"],[[32,0,[\"model\",\"steps\"]],1,false,true]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[8,\"forms/wizard/basic-details-step\",[],[[\"@data\",\"@save\",\"@move\",\"@isCreate\",\"@isLoading\"],[[32,0,[\"model\"]],\"save\",\"move\",true,[32,0,[\"isLoading\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/create.hbs"
    }
  });
  _exports.default = _default;
});