define("open-event-frontend/templates/admin/sales/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui unstackable grid">
    <div class="row">
      <div class="column">
        <h2 class="ui header">
          {{t 'Invoices'}}
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <Tables::Default
          @columns={{this.columns}}
          @rows={{this.model.data}}
          @currentPage={{this.page}}
          @pageSize={{this.per_page}}
          @searchQuery={{this.search}}
          @sortBy={{this.sort_by}}
          @sortDir={{this.sort_dir}}
          @metaData={{this.model.meta}}
          @filterOptions={{this.filterOptions}}
          @widthConstraint="eq-container"
          @resizeMode="fluid"
          @fillMode="equal-column" />    
      </div>
    </div>
  </div>
  
  */
  {
    "id": "6TRZz0pg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui unstackable grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"ui header\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Invoices\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"model\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"model\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"    \\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/sales/invoices.hbs"
    }
  });
  _exports.default = _default;
});