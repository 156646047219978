define("open-event-frontend/serializers/session", ["exports", "open-event-frontend/serializers/application", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _application, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend(_customPrimaryKey.default, {
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      var attributes = json.data.attributes;
      if (attributes['complex-field-values'] && Object.keys(attributes['complex-field-values']).length === 0) {
        // If object is empty, remove it so that validations on server aren't triggered
        try {
          delete attributes['complex-field-values'];
        } catch (_unused) {} // eslint-disable-line no-empty
      }

      return json;
    }
  });
  _exports.default = _default;
});