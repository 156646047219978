define("open-event-frontend/templates/components/currency-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{this.modifiedAmount}}
  
  */
  {
    "id": "gR5S5O93",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"modifiedAmount\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/currency-amount.hbs"
    }
  });
  _exports.default = _default;
});