define("open-event-frontend/templates/components/ui-table/cell/cell-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <UrlBox @url={{this.record}} />
  
  */
  {
    "id": "K2p/AYIq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"url-box\",[],[[\"@url\"],[[32,0,[\"record\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-link.hbs"
    }
  });
  _exports.default = _default;
});