define("open-event-frontend/utils/string", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pascalCase = void 0;
  var pascalCase = function pascalCase(string) {
    string = (0, _lodashEs.camelCase)(string);
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  _exports.pascalCase = pascalCase;
});