define("open-event-frontend/utils/dictionary/levels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.levels = void 0;
  var levels = [{
    name: 'Beginner',
    position: 1
  }, {
    name: 'Intermediate',
    position: 2
  }, {
    name: 'Advanced',
    position: 3
  }, {
    name: 'Expert',
    position: 4
  }];
  _exports.levels = levels;
});