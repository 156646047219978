define("open-event-frontend/components/public/event-venue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @event.online}}
    <div style="color: #000000AD">
      <i class="wifi icon"></i>
      {{t 'Online Event'}}
    </div>
  {{/if}}
  {{#if @event.shortLocationName}}
      <span style="color: #000000AD">
        <i class="map marker alternate icon"></i>
      </span>
      <SmartOverflow @class="d-inline description">
        {{@event.shortLocationName}}
      </SmartOverflow>
  {{/if}}
  
  */
  {
    "id": "kj3XisZN",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[6,[37,1],[[32,1,[\"online\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,5,\"color: #000000AD\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"wifi icon\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,0],[\"Online Event\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,1,[\"shortLocationName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,5,\"color: #000000AD\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"map marker alternate icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"smart-overflow\",[],[[\"@class\"],[\"d-inline description\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"shortLocationName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/components/public/event-venue.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});