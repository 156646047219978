define("open-event-frontend/templates/events/view/session/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui container">
    <div class="column sixteen wide">
      <h2 class="ui header center aligned">
        {{t 'Edit Session'}}
      </h2>
      <div class="ui divider"></div>
      <div class="ui container">
        <Forms::SessionSpeakerForm
          @addNewSpeaker={{this.addNewSpeaker}}
          @speakers={{this.model.speakers}}
          @fields={{this.model.form}}
          @data={{this.model}}
          @event={{this.model.event}}
          @save={{action "save"}}
          @isSession={{true}}
          @speakersDetails={{this.model.session.speakers}}
          @viewSpeakerOrder={{sort-by 'position' this.model.session.speakers}}
          @includeSession={{true}}
          @isSessionSpeaker={{true}}
          @isLoading={{this.isLoading}}
          @isEditForm={{true}} />
      </div>
    </div>
  </div>
  
  */
  {
    "id": "CeVtDagd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Edit Session\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui divider\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n      \"],[8,\"forms/session-speaker-form\",[],[[\"@addNewSpeaker\",\"@speakers\",\"@fields\",\"@data\",\"@event\",\"@save\",\"@isSession\",\"@speakersDetails\",\"@viewSpeakerOrder\",\"@includeSession\",\"@isSessionSpeaker\",\"@isLoading\",\"@isEditForm\"],[[32,0,[\"addNewSpeaker\"]],[32,0,[\"model\",\"speakers\"]],[32,0,[\"model\",\"form\"]],[32,0,[\"model\"]],[32,0,[\"model\",\"event\"]],[30,[36,1],[[32,0],\"save\"],null],true,[32,0,[\"model\",\"session\",\"speakers\"]],[30,[36,2],[\"position\",[32,0,[\"model\",\"session\",\"speakers\"]]],null],true,true,[32,0,[\"isLoading\"]],true]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"sort-by\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/session/edit.hbs"
    }
  });
  _exports.default = _default;
});