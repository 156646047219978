define("open-event-frontend/templates/components/ui-table/cell/admin/users/cell-user-verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <UiCheckbox @class="toggle" @checked={{@record}} @onChange={{action @props.actions.toggleVerify @extraRecords.id}} />
  
  */
  {
    "id": "l1j50ei6",
    "block": "{\"symbols\":[\"@record\",\"@extraRecords\",\"@props\"],\"statements\":[[8,\"ui-checkbox\",[],[[\"@class\",\"@checked\",\"@onChange\"],[\"toggle\",[32,1],[30,[36,0],[[32,0],[32,3,[\"actions\",\"toggleVerify\"]],[32,2,[\"id\"]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/users/cell-user-verify.hbs"
    }
  });
  _exports.default = _default;
});