define("open-event-frontend/models/admin-sales-by-location", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    locationName: (0, _attr.default)('string'),
    sales: (0, _attr.default)()
  });
  _exports.default = _default;
});