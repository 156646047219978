define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.record}}
      <div class="ui inverted segment link" style={{this.attendeeTagColor}}>
          <div>{{this.attendeeTagName}}</div>
          <i class="close icon" role="button" onclick={{action 'removeTag'}}></i>
      </div>
  {{/if}}
  
  */
  {
    "id": "+C6vaNoY",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"record\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui inverted segment link\"],[15,5,[32,0,[\"attendeeTagColor\"]]],[12],[2,\"\\n        \"],[10,\"div\"],[12],[1,[32,0,[\"attendeeTagName\"]]],[13],[2,\"\\n        \"],[10,\"i\"],[14,0,\"close icon\"],[14,\"role\",\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"removeTag\"],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-tag.hbs"
    }
  });
  _exports.default = _default;
});