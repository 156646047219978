define("open-event-frontend/templates/components/scheduler/unscheduled-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui segment">
    <span class="text">
      {{this.session.title}} |
    </span>
    {{#each this.session.speakers as |speaker|}}
      <span class="text speaker">
        {{speaker.name}}
      </span>
    {{/each}}
  </div>
  */
  {
    "id": "UiJN9MAy",
    "block": "{\"symbols\":[\"speaker\"],\"statements\":[[10,\"div\"],[14,0,\"ui segment\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text\"],[12],[2,\"\\n    \"],[1,[32,0,[\"session\",\"title\"]]],[2,\" |\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"session\",\"speakers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"text speaker\"],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/scheduler/unscheduled-session.hbs"
    }
  });
  _exports.default = _default;
});