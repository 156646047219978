define("open-event-frontend/components/forms/wizard/badge-field-form", ["exports", "lodash-es", "open-event-frontend/mixins/form", "open-event-frontend/utils/dictionary/boolean_text_type", "open-event-frontend/utils/dictionary/badge-field"], function (_exports, _lodashEs, _form, _boolean_text_type, _badgeField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    router: Ember.inject.service(),
    autoScrollToErrors: false,
    isExpanded: true,
    booleanTextType: (0, _lodashEs.orderBy)(_boolean_text_type.booleanTextType, 'position'),
    badgeFieldRotate: (0, _lodashEs.orderBy)(_badgeField.badgeFieldRotate),
    badgeFieldFontWeight: _badgeField.badgeFieldFontWeight,
    badgeFieldFontSize: _badgeField.badgeFieldFontSize,
    getCustomFields: Ember.computed('includeCustomField', function () {
      var validForms = this.includeCustomField.map(function (item) {
        if (item.isComplex) {
          return {
            'isComplex': item.name
          };
        } else {
          return {
            'isFixed': item.name
          };
        }
      });
      return (0, _lodashEs.union)(validForms);
    }),
    getQrFields: Ember.computed('qrFields', function () {
      return (0, _lodashEs.union)(this.qrFields, 'name');
    }),
    getWarningFields: Ember.computed('data.custom_field', 'selectedTickets', function () {
      var _this = this;
      var warningFields = [];
      if (this.data.custom_field !== 'QR') {
        this.selectedTickets.forEach(function (ticket) {
          var listCFields = _this.customForms.filter(function (form) {
            return ticket.formID === form.formID && form.isIncluded || form.isFixed;
          }).map(function (form) {
            return form.name;
          });
          if (_this.data.custom_field && !listCFields.includes(_this.data.custom_field)) {
            warningFields.pushObject({
              field: _this.data.custom_field,
              ticket: ticket.name
            });
          }
        });
      }
      return warningFields;
    }),
    getFieldComplex: Ember.computed('data.custom_field', function () {
      var _this2 = this;
      var custom_field = this.data.custom_field;
      var isComplex = false;
      if (custom_field !== 'QR') {
        this.selectedTickets.forEach(function (ticket) {
          var listCFields = _this2.customForms.filter(function (form) {
            return ticket.formID === form.formID && form.isIncluded || form.isFixed;
          });
          if (custom_field) {
            listCFields.forEach(function (field) {
              var fieldIsComplex = field.isComplex;
              if (field.name === custom_field) {
                isComplex = fieldIsComplex;
              }
            });
          }
        });
      }
      return isComplex;
    }),
    getWarningQRFields: Ember.computed('data.qr_custom_field.@each', 'selectedTickets', function () {
      var _this3 = this;
      if (this.data.qr_custom_field) {
        var warningFields = [];
        this.selectedTickets.forEach(function (ticket) {
          var listCFields = (0, _lodashEs.union)(_this3.customForms.filter(function (form) {
            return ticket.formID === form.formID && form.isIncluded || form.isFixed;
          }), 'fieldIdentifier');
          _this3.data.qr_custom_field.forEach(function (field) {
            var warningField = listCFields.map(function (item) {
              return item.fieldIdentifier;
            }).includes(field);
            if (!warningField) {
              warningFields.pushObject({
                field: _this3.customForms.find(function (item) {
                  return item.fieldIdentifier === field;
                }).name,
                ticket: ticket.name
              });
            }
          });
        });
        return warningFields;
      }
    }),
    get fieldFont() {
      return (0, _lodashEs.orderBy)(_badgeField.fieldFontName, 'name');
    },
    get fontStyle() {
      var font_style = [];
      if (this.data.font_weight) {
        font_style = this.data.font_weight.map(function (item) {
          return item.name;
        });
      }
      return font_style;
    },
    get fontStyleSelected() {
      if (this.fontStyle.length > 0) {
        return this.fontStyle.join(', ');
      } else {
        return 'Normal';
      }
    },
    actions: {
      toggleSetting: function toggleSetting() {
        if (!this.data.is_field_expanded) {
          this.set('data.is_field_expanded', true);
        } else {
          this.set('data.is_field_expanded', false);
        }
      },
      removeForm: function removeForm() {
        if (this.removeBadgeField) {
          this.set('data.is_deleted', true);
          this.removeBadgeField(this.data);
        }
      },
      toggleTextAlignments: function toggleTextAlignments(item) {
        this.set('data.text_alignment', item);
      },
      onChangeCustomField: function onChangeCustomField(code) {
        if (this.onChange) {
          this.onChange(this.data.custom_field, code);
          this.set('data.custom_field', code);
          if (code === 'QR') {
            this.set('data.field_identifier', 'QR');
          } else {
            var cfield = this.includeCustomField.filter(function (item) {
              return item.name === code;
            })[0];
            if (cfield) {
              this.set('data.field_identifier', cfield.fieldIdentifier);
            }
          }
        }
      },
      onChangeTextTransform: function onChangeTextTransform(value) {
        this.set('data.text_type', value);
      },
      onChangeFontName: function onChangeFontName(value) {
        this.set('data.font_name', value);
      },
      onChangeTextRotate: function onChangeTextRotate(value) {
        this.set('data.text_rotation', value);
      },
      onChangeTextFontWeight: function onChangeTextFontWeight(value) {
        if (this.data.font_weight == null) {
          this.set('data.font_weight', []);
        }
        if (this.data.font_weight.map(function (item) {
          return item.name;
        }).includes(value.name)) {
          this.data.font_weight.removeObject(this.data.font_weight.find(function (item) {
            return item.name === value.name;
          }));
        } else {
          this.data.font_weight.pushObject(value);
        }
      }
    }
  });
  _exports.default = _default;
});