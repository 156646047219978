define("open-event-frontend/sentry", ["@sentry/browser", "@sentry/integrations", "open-event-frontend/config/environment", "@sentry/tracing"], function (Sentry, _integrations, _environment, _tracing) {
  "use strict";

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  if (!_environment.default.sentry.dsn.includes('dummy')) {
    Sentry.init(_objectSpread({
      integrations: [new _integrations.Ember(), new _integrations.Dedupe(), new _integrations.CaptureConsole({
        levels: ['error']
      }), new _tracing.Integrations.BrowserTracing()],
      beforeSend: function beforeSend(event) {
        var _event$exception, _event$exception$valu, _exception$mechanism;
        var exception = (_event$exception = event.exception) === null || _event$exception === void 0 ? void 0 : (_event$exception$valu = _event$exception.values) === null || _event$exception$valu === void 0 ? void 0 : _event$exception$valu[0];
        var errorValue = exception === null || exception === void 0 ? void 0 : exception.value;
        if (errorValue !== null && errorValue !== void 0 && errorValue.includes('Ember Data Request')) {
          if (errorValue !== null && errorValue !== void 0 && errorValue.includes('404')) {
            // Ignore 404 errors from Ember Data because
            // I don't know how to turn them off
            return null;
          }
        }
        if (errorValue !== null && errorValue !== void 0 && errorValue.includes('TransitionAborted') && exception !== null && exception !== void 0 && (_exception$mechanism = exception.mechanism) !== null && _exception$mechanism !== void 0 && _exception$mechanism.handled) {
          // Every page load has a handled TransitionAborted for some reason
          return null;
        }
        return event;
      }
    }, _environment.default.sentry));
    Sentry.configureScope(function (scope) {
      function addAdapterError(error, event) {
        if (error !== null && error !== void 0 && error.isAdapterError) {
          event.extra = _objectSpread(_objectSpread({}, event.extra), {}, {
            adapter_errors: error.errors,
            adapter_errors_json: JSON.stringify(error.errors)
          });
        }
        try {
          if (!event) {
            return;
          }
          // Try to store JSON of error for diagnosing bugs
          event.extra = _objectSpread(_objectSpread({}, event.extra), {}, {
            error_json: JSON.stringify(error)
          });
        } catch (_unused) {
          // Ignore error to prevent stackoverflow
        }
      }
      scope.addEventProcessor(function (event, hints) {
        var _event$extra;
        addAdapterError(hints.originalException, event);
        var args = ((_event$extra = event.extra) === null || _event$extra === void 0 ? void 0 : _event$extra.arguments) || [];
        var _iterator = _createForOfIteratorHelper(args),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var arg = _step.value;
            addAdapterError(arg, event);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        return event;
      });
    });
  }
});