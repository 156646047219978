define("open-event-frontend/templates/components/errors/forbidden-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <br>
  <h1 class="text muted weight-300 no bottom margin">403 {{t 'Forbidden'}}</h1>
  <h1 class="weight-300 description">
    {{t 'Oops, you don\'t have the permission'}}<br>
    {{t 'to access this page'}}
  </h1>
  <h3 class="weight-300">
    {{t 'You may want to head back to the home page.'}}<br>
    {{t 'If you think you shouldn\'t be seeing this, please contact the administrator.'}}
  </h3>
  <br>
  <a class="ui secondary basic button" href="{{href-to 'index'}}">{{t 'Go Back Home'}}</a>
  
  */
  {
    "id": "hbBAgV7F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"h1\"],[14,0,\"text muted weight-300 no bottom margin\"],[12],[2,\"403 \"],[1,[30,[36,0],[\"Forbidden\"],null]],[13],[2,\"\\n\"],[10,\"h1\"],[14,0,\"weight-300 description\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Oops, you don't have the permission\"],null]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"to access this page\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"h3\"],[14,0,\"weight-300\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"You may want to head back to the home page.\"],null]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"If you think you shouldn't be seeing this, please contact the administrator.\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"a\"],[14,0,\"ui secondary basic button\"],[15,6,[31,[[30,[36,1],[\"index\"],null]]]],[12],[1,[30,[36,0],[\"Go Back Home\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"href-to\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/errors/forbidden-error.hbs"
    }
  });
  _exports.default = _default;
});