define("open-event-frontend/models/badge-field-form", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships", "open-event-frontend/utils/dictionary/badge-field"], function (_exports, _attr, _base, _relationships, _badgeField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    /**
     * Attributes
     */
    badge_field_id: (0, _attr.default)('number'),
    badge_id: (0, _attr.default)('string'),
    field_identifier: (0, _attr.default)('string'),
    custom_field: (0, _attr.default)('string'),
    sample_text: (0, _attr.default)('string', {
      defaultValue: 'Sample Text'
    }),
    font_size: (0, _attr.default)('number', {
      defaultValue: 14
    }),
    font_name: (0, _attr.default)('string', {
      defaultValue: 'Arial'
    }),
    font_color: (0, _attr.default)('string', {
      defaultValue: '#000000'
    }),
    text_rotation: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    margin_top: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    margin_bottom: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    margin_left: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    margin_right: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    font_weight: (0, _attr.default)(),
    text_alignment: (0, _attr.default)('string', {
      defaultValue: 'center'
    }),
    text_type: (0, _attr.default)('string', {
      defaultValue: 'None'
    }),
    is_deleted: (0, _attr.default)('boolean'),
    qr_custom_field: (0, _attr.default)(),
    is_field_expanded: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    /**
     * Relationships
     */
    event: (0, _relationships.belongsTo)('event'),
    ticket: (0, _relationships.hasMany)('ticket'),
    getFieldStyle: Ember.computed('font_size', 'font_name', 'font_color', 'text_rotation', 'margin_top', 'margin_bottom', 'margin_left', 'margin_right', 'font_weight.@each', 'text_alignment', 'text_type', 'isDeleted', 'this', function () {
      var _this = this;
      var font_name = _badgeField.fieldFontName.find(function (item) {
        return item.name === _this.font_name;
      });
      if (font_name) {
        font_name = font_name.value;
      }
      var font_weight = [];
      var font_style = [];
      var text_decoration = [];
      if (this.font_weight) {
        this.font_weight.forEach(function (element) {
          if (element.font_weight) {
            font_weight.addObject(element.font_weight);
          }
          if (element.font_style) {
            font_style.addObject(element.font_style);
          }
          if (element.text_decoration) {
            text_decoration.addObject(element.text_decoration);
          }
        });
      }
      return Ember.String.htmlSafe('font-family: ' + font_name + '; font-size: ' + this.font_size + 'px; text-align: ' + this.text_alignment + '; text-transform: ' + this.text_type + '; color:' + this.font_color + '; font-weight:' + font_weight.join(',') + '; font-style:' + font_style.join(',') + '; text-decoration: ' + text_decoration.join(',') + '; -webkit-transform: rotate(' + this.text_rotation + 'deg); -moz-transform: rotate(' + this.text_rotation + 'deg); -o-transform: rotate(' + this.text_rotation + 'deg); writing-mode: lr-tb; margin-top:' + this.margin_top + 'mm; margin-bottom:' + this.margin_bottom + 'mm; margin-left:' + this.margin_left + 'mm; margin-right:' + this.margin_right + 'mm;');
    })
  });
  _exports.default = _default;
});