define("open-event-frontend/utils/dictionary/badge-field", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fieldFontName = _exports.badgeSize = _exports.badgeOrientation = _exports.badgeFieldRotate = _exports.badgeFieldFontWeight = _exports.badgeFieldFontSize = void 0;
  var badgeSize = [{
    name: '3 x 4 inch (76.2 x 101.6mm )',
    height: '4',
    lineHeight: '3',
    position: 1
  }, {
    name: '4 x 6 inch (101.6 x 152.4 mm)',
    height: '6',
    lineHeight: '4',
    position: 2
  }];
  _exports.badgeSize = badgeSize;
  var badgeOrientation = [{
    name: _text.tn.t('Portrait'),
    position: 1
  }, {
    name: _text.tn.t('Landscape'),
    position: 2
  }];
  _exports.badgeOrientation = badgeOrientation;
  var fieldFontName = [{
    name: 'Arial',
    value: 'Arial'
  }, {
    name: 'Helvetica',
    value: 'Helvetica'
  }, {
    name: 'Times New Roman',
    value: 'Times New Roman'
  }, {
    name: 'Courier New',
    value: 'Courier New'
  }, {
    name: 'Lato',
    value: 'Lato'
  }, {
    name: 'Rubik',
    value: '"Rubik variant1", Tofu'
  }, {
    name: 'Hind Siliguri',
    value: '"Hind_Siliguri variant1", Tofu'
  }, {
    name: 'Noto Sans Hong Kong',
    value: '"Noto_Sans_HK variant1", Tofu'
  }, {
    name: 'Noto Sans Simplified Chinese',
    value: '"Noto_Sans_SC variant1", Tofu'
  }, {
    name: 'Noto Sans Traditional Chinese',
    value: '"Noto_Sans_TC variant1", Tofu'
  }, {
    name: 'Noto Sans Japanese',
    value: '"Noto_Sans_JP variant0", Tofu'
  }, {
    name: 'Noto Sans Korean',
    value: '"Noto_Sans_KR variant1", Tofu'
  }, {
    name: 'Roboto',
    value: '"Roboto variant1", Tofu'
  }, {
    name: 'Kanit',
    value: '"Kanit variant1", Tofu'
  }];
  _exports.fieldFontName = fieldFontName;
  var badgeFieldRotate = [{
    value: 0
  }, {
    value: 45
  }, {
    value: -45
  }, {
    value: 90
  }, {
    value: -90
  }];
  _exports.badgeFieldRotate = badgeFieldRotate;
  var badgeFieldFontWeight = [{
    name: 'Bold',
    font_weight: 'bold'
  }, {
    name: 'Italic',
    font_style: 'italic'
  }, {
    name: 'Underline',
    text_decoration: 'underline'
  }];
  _exports.badgeFieldFontWeight = badgeFieldFontWeight;
  var badgeFieldFontSize = [{
    value: 8
  }, {
    value: 9
  }, {
    value: 10
  }, {
    value: 11
  }, {
    value: 12
  }, {
    value: 14
  }, {
    value: 16
  }, {
    value: 18
  }, {
    value: 20
  }, {
    value: 22
  }, {
    value: 24
  }, {
    value: 26
  }, {
    value: 28
  }, {
    value: 36
  }, {
    value: 48
  }, {
    value: 72
  }];
  _exports.badgeFieldFontSize = badgeFieldFontSize;
});