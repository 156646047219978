define("open-event-frontend/templates/components/ui-table/cell/admin/messages/cell-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <UiCheckbox @class="toggle" @checked={{this.record}} @onChange={{action (mut this.record)}} />
  
  */
  {
    "id": "zOaxp2sY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-checkbox\",[],[[\"@class\",\"@checked\",\"@onChange\"],[\"toggle\",[32,0,[\"record\"]],[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"record\"]]],null]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/messages/cell-options.hbs"
    }
  });
  _exports.default = _default;
});