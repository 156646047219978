define("open-event-frontend/templates/components/tables/utilities/page-size-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span>
    {{t 'Show'}}
    <UiDropdown @class="inline" @onChange={{action (mut this.pageSize)}} @forceSelection={{true}}>
      <div class="default text">{{if (eq this.pageSize 0) 'All' this.pageSize}}</div>
      <i class="dropdown icon"></i>
      <div class="menu">
        {{#each this.sizes as |size|}}
          <div class="item" data-value="{{if (eq size 'All') 0 size}}">
            {{size}}
          </div>
        {{/each}}
      </div>
    </UiDropdown>
    {{t 'entries per page'}}
  </span>
  
  */
  {
    "id": "XE6p9g3o",
    "block": "{\"symbols\":[\"size\"],\"statements\":[[10,\"span\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"Show\"],null]],[2,\"\\n  \"],[8,\"ui-dropdown\",[],[[\"@class\",\"@onChange\",\"@forceSelection\"],[\"inline\",[30,[36,4],[[32,0],[30,[36,3],[[32,0,[\"pageSize\"]]],null]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"default text\"],[12],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"pageSize\"]],0],null],\"All\",[32,0,[\"pageSize\"]]],null]],[13],[2,\"\\n    \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"sizes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"item\"],[15,\"data-value\",[31,[[30,[36,1],[[30,[36,0],[[32,1],\"All\"],null],0,[32,1]],null]]]],[12],[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,2],[\"entries per page\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"t\",\"mut\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/utilities/page-size-input.hbs"
    }
  });
  _exports.default = _default;
});