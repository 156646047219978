define("open-event-frontend/serializers/user", ["exports", "open-event-frontend/serializers/application", "lodash-es"], function (_exports, _application, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);
      if (snapshot.id) {
        var attributesToOmit = [];
        if (!snapshot.adapterOptions || !snapshot.adapterOptions.includePassword) {
          attributesToOmit.push('password');
        }
        json.data.attributes = (0, _lodashEs.omit)(json.data.attributes, attributesToOmit);
      } else if (options && options.includeId) {
        json.data.attributes = (0, _lodashEs.pick)(json.data.attributes, ['email', 'password', 'was-registered-with-order']);
      }
      return json;
    }
  });
  _exports.default = _default;
});