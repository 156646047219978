define("open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-lock-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.extraRecords.isLocked}}
    <UiPopup @content={{t "Unlock Session"}} @class="ui basic icon button" @click={{action this.props.actions.lockSession this.record false}} @position="left center">
      <i class="lock icon"></i>		
    </UiPopup>
  {{else}}
    <UiPopup @content={{t "Lock Session"}} @class="ui basic icon button" @click={{action this.props.actions.lockSession this.record true}} @position="left center">
      <i class="unlock icon"></i>		
    </UiPopup>
  {{/if}}
  */
  {
    "id": "QhtDOTVB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"extraRecords\",\"isLocked\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@click\",\"@position\"],[[30,[36,0],[\"Unlock Session\"],null],\"ui basic icon button\",[30,[36,1],[[32,0],[32,0,[\"props\",\"actions\",\"lockSession\"]],[32,0,[\"record\"]],false],null],\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"lock icon\"],[12],[13],[2,\"\\t\\t\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@click\",\"@position\"],[[30,[36,0],[\"Lock Session\"],null],\"ui basic icon button\",[30,[36,1],[[32,0],[32,0,[\"props\",\"actions\",\"lockSession\"]],[32,0,[\"record\"]],true],null],\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"unlock icon\"],[12],[13],[2,\"\\t\\t\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-lock-session.hbs"
    }
  });
  _exports.default = _default;
});