define("open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-recording", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#each @record as |recording|}}
    <div class="d-flex-wrap">
      {{t 'Number of Participants'}}: {{recording.participants}}<br/>
      {{t 'Start time'}}: <UiTable::Cell::CellDate @record={{recording.startTime}}/>
      {{t 'End time'}}: <UiTable::Cell::CellDate @record={{recording.endTime}}/>
      {{t 'Duration'}}: <UiTable::Cell::CellDuration @record={{recording.endTime}} @extraRecords={{recording}}/>
      {{t 'URL'}}: <UiTable::Cell::Events::View::Videoroom::CellVideoRecording @record={{recording.url}}/>
    </div>
  {{/each}}
  
  */
  {
    "id": "XH6k6VwZ",
    "block": "{\"symbols\":[\"recording\",\"@record\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"d-flex-wrap\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Number of Participants\"],null]],[2,\": \"],[1,[32,1,[\"participants\"]]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,0],[\"Start time\"],null]],[2,\": \"],[8,\"ui-table/cell/cell-date\",[],[[\"@record\"],[[32,1,[\"startTime\"]]]],null],[2,\"\\n    \"],[1,[30,[36,0],[\"End time\"],null]],[2,\": \"],[8,\"ui-table/cell/cell-date\",[],[[\"@record\"],[[32,1,[\"endTime\"]]]],null],[2,\"\\n    \"],[1,[30,[36,0],[\"Duration\"],null]],[2,\": \"],[8,\"ui-table/cell/cell-duration\",[],[[\"@record\",\"@extraRecords\"],[[32,1,[\"endTime\"]],[32,1]]],null],[2,\"\\n    \"],[1,[30,[36,0],[\"URL\"],null]],[2,\": \"],[8,\"ui-table/cell/events/view/videoroom/cell-video-recording\",[],[[\"@record\"],[[32,1,[\"url\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-recording.hbs"
    }
  });
  _exports.default = _default;
});