define("open-event-frontend/templates/admin/content/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Forms::Admin::Content::SocialLinksForm @socials={{this.model}} @save="saveSocials" @isLoading={{this.isLoading}} />
  
  */
  {
    "id": "s9oR60aZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/admin/content/social-links-form\",[],[[\"@socials\",\"@save\",\"@isLoading\"],[[32,0,[\"model\"]],\"saveSocials\",[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/content/index.hbs"
    }
  });
  _exports.default = _default;
});