define("open-event-frontend/templates/admin/video/recordings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
      <div class="sixteen wide column">
      <div class="mb-4">
        <h2 class="header">{{t 'Video Recordings'}}</h2>
      </div>
      <Tables::Default
        @columns={{this.recordingColumns}}
        @rows={{this.model.data}}
        @currentPage={{this.page}}
        @hideSearchBox={{true}}
        @pageSize={{this.per_page}}
        @metaData={{this.model.meta}}
        @widthConstraint="eq-container"
        @resizeMode="fluid"
        @fillMode="equal-column" />
    </div>
  */
  {
    "id": "X/OJoj9T",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mb-4\"],[12],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"header\"],[12],[1,[30,[36,0],[\"Video Recordings\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@hideSearchBox\",\"@pageSize\",\"@metaData\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"recordingColumns\"]],[32,0,[\"model\",\"data\"]],[32,0,[\"page\"]],true,[32,0,[\"per_page\"]],[32,0,[\"model\",\"meta\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n  \"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/video/recordings.hbs"
    }
  });
  _exports.default = _default;
});