define("open-event-frontend/mixins/admin-sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    totalCompletedTickets: Ember.computed(function () {
      var sum = 0;
      this.model.forEach(function (data) {
        sum += data.sales.completed.ticket_count;
      });
      return sum;
    }),
    totalCompletedSales: Ember.computed(function () {
      var sum = 0;
      this.model.forEach(function (data) {
        sum += data.sales.completed.sales_total;
      });
      return sum;
    }),
    totalPlacedTickets: Ember.computed(function () {
      var sum = 0;
      this.model.forEach(function (data) {
        sum += data.sales.placed.ticket_count;
      });
      return sum;
    }),
    totalPlacedSales: Ember.computed(function () {
      var sum = 0;
      this.model.forEach(function (data) {
        sum += data.sales.placed.sales_total;
      });
      return sum;
    }),
    totalPendingTickets: Ember.computed(function () {
      var sum = 0;
      this.model.forEach(function (data) {
        sum += data.sales.pending.ticket_count;
      });
      return sum;
    }),
    totalPendingSales: Ember.computed(function () {
      var sum = 0;
      this.model.forEach(function (data) {
        sum += data.sales.pending.sales_total;
      });
      return sum;
    })
  });
  _exports.default = _default;
});