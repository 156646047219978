define("open-event-frontend/templates/components/smart-overflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span>{{yield}}</span>
  
  */
  {
    "id": "lqpoHWUu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[12],[18,1,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/smart-overflow.hbs"
    }
  });
  _exports.default = _default;
});