define("open-event-frontend/templates/public/stream/view/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.model.token}}
    <Public::Stream::Chat @token={{this.model.token}} />
  {{/if}}
  {{#if (not this.model.success)}}
    <h3>Unable to login to chat. Please contact the administrator</h3>
  {{/if}}
  
  <Public::Stream::SidePanel @event={{this.model.event}} @videoStream={{this.model.stream}} @currentRoute={{this.session.currentRouteName}} />
  
  */
  {
    "id": "7tIsTZk5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"model\",\"token\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"public/stream/chat\",[],[[\"@token\"],[[32,0,[\"model\",\"token\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[30,[36,1],[[32,0,[\"model\",\"success\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h3\"],[12],[2,\"Unable to login to chat. Please contact the administrator\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"public/stream/side-panel\",[],[[\"@event\",\"@videoStream\",\"@currentRoute\"],[[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"stream\"]],[32,0,[\"session\",\"currentRouteName\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"not\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/stream/view/chat.hbs"
    }
  });
  _exports.default = _default;
});