define("open-event-frontend/templates/components/widgets/forms/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    
  {{#if this.icon}}
    <i class="calendar icon"></i>
  {{/if}}
  <Input @type="text" @value={{value}} autocomplete="off" placeholder={{this.placeholder}} @name={{name}} @focus-out={{action "onChange"}} />
  
  */
  {
    "id": "C4i/aXSH",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"calendar icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"input\",[[24,\"autocomplete\",\"off\"],[16,\"placeholder\",[32,0,[\"placeholder\"]]]],[[\"@type\",\"@value\",\"@name\",\"@focus-out\"],[\"text\",[34,1],[34,2],[30,[36,3],[[32,0],\"onChange\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"value\",\"name\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/widgets/forms/date-picker.hbs"
    }
  });
  _exports.default = _default;
});