define("open-event-frontend/templates/admin/video/channels/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="sixteen wide column">
    <div class="ui grid space-between items-center mt-4 mb-4">
        <div>
          <h2 class="header">{{t 'Video Channels'}}</h2>
        </div>
        <div>
          <LinkTo @route="admin.video.channels.create" class="ui blue button">
            {{t 'Add Video Channel'}}
          </LinkTo>
        </div>
      </div>
    <Tables::Default
      @columns={{this.columns}}
      @rows={{this.model.data}}
      @currentPage={{this.page}}
      @hideSearchBox={{true}}
      @pageSize={{this.per_page}}
      @sortBy={{this.sort_by}}
      @metaData={{this.model.meta}}
      @widthConstraint="eq-container"
      @resizeMode="fluid"
      @fillMode="equal-column" />
  </div>
  
  */
  {
    "id": "OkXtQdEn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui grid space-between items-center mt-4 mb-4\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"header\"],[12],[1,[30,[36,0],[\"Video Channels\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"ui blue button\"]],[[\"@route\"],[\"admin.video.channels.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Add Video Channel\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@hideSearchBox\",\"@pageSize\",\"@sortBy\",\"@metaData\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"model\",\"data\"]],[32,0,[\"page\"]],true,[32,0,[\"per_page\"]],[32,0,[\"sort_by\"]],[32,0,[\"model\",\"meta\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/video/channels/index.hbs"
    }
  });
  _exports.default = _default;
});