define("open-event-frontend/helpers/random", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (items) {
    if (Array.isArray(items[0])) {
      items = items[0];
    }
    return items[items.length * Math.random() | 0];
  });
  _exports.default = _default;
});