define("open-event-frontend/templates/components/ui-table/cell/admin/sales/cell-first-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <LinkTo @route="admin.users.view" @model={{this.extraRecords.ownerId}}>
      {{this.record}}    
  </LinkTo>
  */
  {
    "id": "ShWVQs7W",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"admin.users.view\",[32,0,[\"extraRecords\",\"ownerId\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"record\"]]],[2,\"    \\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/sales/cell-first-name.hbs"
    }
  });
  _exports.default = _default;
});