define("open-event-frontend/templates/components/ui-table/cell/cell-speakers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui list">
    {{#each this.record as |speaker|}}
      <div class="item">
        {{speaker.name}}
      </div>
    {{/each}}
  </div>
  
  */
  {
    "id": "lexwzziL",
    "block": "{\"symbols\":[\"speaker\"],\"statements\":[[10,\"div\"],[14,0,\"ui list\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"record\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-speakers.hbs"
    }
  });
  _exports.default = _default;
});