define("open-event-frontend/templates/admin/sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <TabbedNavigation>
    <LinkTo @route="admin.sales.index" @model="live" class={{if (eq this.session.currentRouteName 'admin.sales.index') 'active item' 'item'}}>
      {{t 'Orders'}}
    </LinkTo>
    <LinkTo @route="admin.sales.invoices" class="item">
      {{t 'Invoices'}}
    </LinkTo>
  </TabbedNavigation>
  <br>
  {{outlet}}
  */
  {
    "id": "xQgWT901",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"link-to\",[[16,0,[30,[36,1],[[30,[36,0],[[32,0,[\"session\",\"currentRouteName\"]],\"admin.sales.index\"],null],\"active item\",\"item\"],null]]],[[\"@route\",\"@model\"],[\"admin.sales.index\",\"live\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[\"Orders\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.sales.invoices\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[\"Invoices\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/sales.hbs"
    }
  });
  _exports.default = _default;
});