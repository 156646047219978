define("open-event-frontend/templates/public/cfs/edit-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui container mb-4">
    <div class="column sixteen wide">
      <h2 class="ui header center aligned">
        {{t 'Edit Session'}}
      </h2>
      <div class="ui container">
        <Forms::SessionSpeakerForm
          @fields={{this.model.forms}}
          @data={{this.model}}
          @isLoading={{this.isLoading}}
          @save={{action "save"}}
          @isSession={{true}}
          @includeSession={{true}}
          @isCfsPage={{true}}
          @isSessionSpeaker={{true}} />
      </div>
    </div>
  </div>
  
  */
  {
    "id": "BDKOxEvX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container mb-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Edit Session\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n      \"],[8,\"forms/session-speaker-form\",[],[[\"@fields\",\"@data\",\"@isLoading\",\"@save\",\"@isSession\",\"@includeSession\",\"@isCfsPage\",\"@isSessionSpeaker\"],[[32,0,[\"model\",\"forms\"]],[32,0,[\"model\"]],[32,0,[\"isLoading\"]],[30,[36,1],[[32,0],\"save\"],null],true,true,true,true]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/cfs/edit-session.hbs"
    }
  });
  _exports.default = _default;
});