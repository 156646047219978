define("open-event-frontend/templates/admin/reports/system-logs/notification-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Tables::Default
    @columns={{this.columns}}
    @rows={{this.model.data}}
    @currentPage={{this.page}}
    @pageSize={{this.per_page}}
    @searchQuery={{this.search}}
    @sortBy={{this.sort_by}}
    @sortDir={{this.sort_dir}}
    @metaData={{this.model.meta}}
    @filterOptions={{this.filterOptions}}
    @widthConstraint="eq-container"
    @resizeMode="fluid"
    @fillMode="equal-column" />
  
  */
  {
    "id": "uDCvVXv6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"model\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"model\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/reports/system-logs/notification-logs.hbs"
    }
  });
  _exports.default = _default;
});