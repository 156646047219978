define("open-event-frontend/helpers/url-encode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.urlEncode = urlEncode;
  /**
   * Helper to URL encode a string
   * @param params
   * @returns {*}
   */
  function urlEncode(params) {
    if (!params || params.length === 0) {
      return '';
    }
    return encodeURIComponent(params[0]);
  }
  var _default = Ember.Helper.helper(urlEncode);
  _exports.default = _default;
});