define("open-event-frontend/components/ui-table/cell/events/view/videoroom/cell-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @record as |moderator|}}
    <span>{{moderator.email}}</span>
    <br>
  {{/each}}
  
  */
  {
    "id": "yPJbT4rO",
    "block": "{\"symbols\":[\"moderator\",\"@record\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/components/ui-table/cell/events/view/videoroom/cell-email.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});