define("open-event-frontend/templates/my-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid">
    {{outlet}}
  </div>
  
  */
  {
    "id": "1CbWecaA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/my-groups.hbs"
    }
  });
  _exports.default = _default;
});