define("open-event-frontend/templates/components/events/view/overview/event-apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="content">
    <div class="header">{{t 'Website Generator'}}</div>
  </div>
  <div class="content ui grid">
    <div class="row pt-0">
      {{t 'You would like to have a dedicated event website with all details, supporters listing, speakers and sessions and the entire schedule?'}}
      {{t 'Simply use the website generator.'}}
      {{t 'It transfers the data of the event via an API to our website tool and creates a website for you.'}}
      {{t 'You can download the entire site as a Zip file and upload it to your own web hosting service.'}}
      {{t 'No database set up or framework required.'}}
      {{t 'This feature is still in Alpha stage, but is already used actively by a number of events.'}}
      {{t 'If you press the below button a tab will open where your website is generated.'}}
    </div>
    <div class="row p-0">
      <a href="{{this.webAppGeneratorUrl}}" target="_blank" class="ui labeled icon button" rel="noopener noreferrer"><i class="cogs icon"></i>{{t 'Create Website'}}</a>
    </div>
  </div>
  
  */
  {
    "id": "Xf4lGk4U",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[1,[30,[36,0],[\"Website Generator\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row pt-0\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"You would like to have a dedicated event website with all details, supporters listing, speakers and sessions and the entire schedule?\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[\"Simply use the website generator.\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[\"It transfers the data of the event via an API to our website tool and creates a website for you.\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[\"You can download the entire site as a Zip file and upload it to your own web hosting service.\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[\"No database set up or framework required.\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[\"This feature is still in Alpha stage, but is already used actively by a number of events.\"],null]],[2,\"\\n    \"],[1,[30,[36,0],[\"If you press the below button a tab will open where your website is generated.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row p-0\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[[32,0,[\"webAppGeneratorUrl\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"ui labeled icon button\"],[14,\"rel\",\"noopener noreferrer\"],[12],[10,\"i\"],[14,0,\"cogs icon\"],[12],[13],[1,[30,[36,0],[\"Create Website\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/events/view/overview/event-apps.hbs"
    }
  });
  _exports.default = _default;
});