define("open-event-frontend/templates/my-tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid">
    <div class="row">
      <div class="sixteen wide column">
        <h1>
          {{t 'My tickets'}}
        </h1>
        <TabbedNavigation>
          <LinkTo @route="my-tickets.upcoming" class="item">
            {{t 'Upcoming Events'}}
          </LinkTo>
          <LinkTo @route="my-tickets.past" @model="completed" class="item">
            {{t 'Past Events'}}
          </LinkTo>
        </TabbedNavigation>
      </div>
    </div>
    {{outlet}}
  </div>
  
  */
  {
    "id": "3de0sLkA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"My tickets\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"my-tickets.upcoming\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Upcoming Events\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"my-tickets.past\",\"completed\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Past Events\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/my-tickets.hbs"
    }
  });
  _exports.default = _default;
});