define("open-event-frontend/templates/account/billing/payment-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui basic {{if this.isLoading 'loading' ''}} segment">
    <Forms::UserPaymentInfoForm />
  </div>
  */
  {
    "id": "4BjjrT7k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui basic \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\",\"\"],null],\" segment\"]]],[12],[2,\"\\n  \"],[8,\"forms/user-payment-info-form\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/account/billing/payment-info.hbs"
    }
  });
  _exports.default = _default;
});