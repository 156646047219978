define("open-event-frontend/templates/events/view/videoroom/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Forms::Events::View::VideoroomForm
    @data={{this.model}}
    @rooms={{this.model.rooms}}
    @channels={{this.model.channels}}
    @isLoading={{this.isLoading}} />
  
  */
  {
    "id": "zKXYrgNN",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/events/view/videoroom-form\",[],[[\"@data\",\"@rooms\",\"@channels\",\"@isLoading\"],[[32,0,[\"model\"]],[32,0,[\"model\",\"rooms\"]],[32,0,[\"model\",\"channels\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/videoroom/edit.hbs"
    }
  });
  _exports.default = _default;
});