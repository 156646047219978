define("open-event-frontend/templates/admin/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid stackable">
    {{#if this.onSessionRoute}}
      <div class="row">
        <div class="sixteen wide column">
          <TabbedNavigation @isNonPointing={{true}}>
            <LinkTo @route="admin.users.list" @model="all" class="item">
              {{t 'All Users'}}
            </LinkTo>
            <LinkTo @route="admin.users.list" @model="active" class="item">
              {{t 'Active Users'}}
            </LinkTo>
            <LinkTo @route="admin.users.list" @model="inactive" class="item">
              {{t 'Inactive Users'}}
            </LinkTo>
            <LinkTo @route="admin.users.list" @model="deleted" class="item">
              {{t 'Deleted Users'}}
            </LinkTo>
          </TabbedNavigation>
        </div>
      </div>
    {{/if}}
    <div class="row">
      {{outlet}}
    </div>
  </div>
  
  */
  {
    "id": "oJOGrv7F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid stackable\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"onSessionRoute\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n        \"],[8,\"tabbed-navigation\",[],[[\"@isNonPointing\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.list\",\"all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"All Users\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.list\",\"active\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Active Users\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.list\",\"inactive\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Inactive Users\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.list\",\"deleted\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Deleted Users\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/users.hbs"
    }
  });
  _exports.default = _default;
});