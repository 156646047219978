define("open-event-frontend/templates/components/ui-table/cell/cell-simple-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui vertical compact basic buttons">
    <UiPopup @content={{t "View Session"}} @class="ui icon button" @click={{action this.props.actions.viewSession this.record}} @position="left center">
      <i class="unhide icon"></i>
    </UiPopup>
    <UiPopup @content={{t "Edit Session"}} @class="ui icon button" @click={{action this.props.actions.editSession this.record this.extraRecords.event.id}} @position="left center">
      <i class="edit icon"></i>
    </UiPopup>
    <UiPopup @content={{t "Delete Session"}} @click={{action (confirm (t "Are you sure you would like to delete this Session?") (action this.props.actions.deleteSession this.record))}} @class="ui icon button" @position="left center">
      <i class="trash icon"></i>
    </UiPopup>
  </div>
  
  */
  {
    "id": "I+dfCl8Z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui vertical compact basic buttons\"],[12],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@click\",\"@position\"],[[30,[36,0],[\"View Session\"],null],\"ui icon button\",[30,[36,1],[[32,0],[32,0,[\"props\",\"actions\",\"viewSession\"]],[32,0,[\"record\"]]],null],\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"unhide icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@click\",\"@position\"],[[30,[36,0],[\"Edit Session\"],null],\"ui icon button\",[30,[36,1],[[32,0],[32,0,[\"props\",\"actions\",\"editSession\"]],[32,0,[\"record\"]],[32,0,[\"extraRecords\",\"event\",\"id\"]]],null],\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"edit icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@click\",\"@class\",\"@position\"],[[30,[36,0],[\"Delete Session\"],null],[30,[36,1],[[32,0],[30,[36,2],[[30,[36,0],[\"Are you sure you would like to delete this Session?\"],null],[30,[36,1],[[32,0],[32,0,[\"props\",\"actions\",\"deleteSession\"]],[32,0,[\"record\"]]],null]],null]],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"trash icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"confirm\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-simple-buttons.hbs"
    }
  });
  _exports.default = _default;
});