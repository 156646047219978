define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span>
    {{#if (eq this.extraRecords.type 'percent')}}
      {{this.record}} %
    {{else if (eq this.extraRecords.type 'amount')}}
      <CurrencyAmount @currency={{this.extraRecords.event.paymentCurrency}} @amount={{this.record}}/>
    {{/if}}
  </span>
  
  */
  {
    "id": "7NXL5e1x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,0,[\"extraRecords\",\"type\"]],\"percent\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"record\"]]],[2,\" %\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"extraRecords\",\"type\"]],\"amount\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"currency-amount\",[],[[\"@currency\",\"@amount\"],[[32,0,[\"extraRecords\",\"event\",\"paymentCurrency\"]],[32,0,[\"record\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/discount-codes/cell-value.hbs"
    }
  });
  _exports.default = _default;
});