define("open-event-frontend/components/h-captcha", ["exports", "ember-h-captcha/components/h-captcha"], function (_exports, _hCaptcha) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hCaptcha.default;
    }
  });
});