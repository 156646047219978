define("open-event-frontend/templates/components/errors/server-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <br>
  <h1 class="text muted weight-300 no bottom margin">500 {{t 'Internal Server Error'}}</h1>
  <h1 class="weight-300 description">
    {{t 'Oops, the page you are'}}<br>
    {{t 'looking for caused an error'}}<br>
    {{t 'on our servers.'}}
  </h1>
  <h3 class="weight-300">
    {{t 'You may want to head back to the home page.'}}<br>
    {{t 'If you think something is broken, try again.'}}
  </h3>
  <br>
  <a class="ui secondary basic button" href="{{href-to 'index'}}">{{t 'Go Back Home'}}</a>
  
  */
  {
    "id": "avv7Dq/2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"h1\"],[14,0,\"text muted weight-300 no bottom margin\"],[12],[2,\"500 \"],[1,[30,[36,0],[\"Internal Server Error\"],null]],[13],[2,\"\\n\"],[10,\"h1\"],[14,0,\"weight-300 description\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Oops, the page you are\"],null]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"looking for caused an error\"],null]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"on our servers.\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"h3\"],[14,0,\"weight-300\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"You may want to head back to the home page.\"],null]],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"If you think something is broken, try again.\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"a\"],[14,0,\"ui secondary basic button\"],[15,6,[31,[[30,[36,1],[\"index\"],null]]]],[12],[1,[30,[36,0],[\"Go Back Home\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"href-to\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/errors/server-error.hbs"
    }
  });
  _exports.default = _default;
});