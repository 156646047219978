define("open-event-frontend/templates/events/view/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="sixteen wide column">
    <Forms::AddTagForm @data={{this.model}}/>
  </div>
  */
  {
    "id": "rDzE4R7S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[8,\"forms/add-tag-form\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/tags.hbs"
    }
  });
  _exports.default = _default;
});