define("open-event-frontend/templates/components/ui-table/cell/events/cell-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{currency-symbol this.extraRecords.event.paymentCurrency}} {{format-money this.record}}
  */
  {
    "id": "sq+6IlAn",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"extraRecords\",\"event\",\"paymentCurrency\"]]],null]],[2,\" \"],[1,[30,[36,1],[[32,0,[\"record\"]]],null]]],\"hasEval\":false,\"upvars\":[\"currency-symbol\",\"format-money\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/cell-amount.hbs"
    }
  });
  _exports.default = _default;
});