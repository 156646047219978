define("open-event-frontend/models/mail", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    action: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    recipient: (0, _attr.default)('string'),
    time: (0, _attr.default)('string')
  });
  _exports.default = _default;
});