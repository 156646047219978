define("open-event-frontend/templates/components/ui-table/cell/events/view/speakers/cell-is-featured", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <UiCheckbox @class="toggle" @checked={{this.extraRecords.isFeatured}} @onChange={{action this.props.actions.toggleFeatured this.record}} />
  */
  {
    "id": "7mFtTPPH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-checkbox\",[],[[\"@class\",\"@checked\",\"@onChange\"],[\"toggle\",[32,0,[\"extraRecords\",\"isFeatured\"]],[30,[36,0],[[32,0],[32,0,[\"props\",\"actions\",\"toggleFeatured\"]],[32,0,[\"record\"]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/speakers/cell-is-featured.hbs"
    }
  });
  _exports.default = _default;
});