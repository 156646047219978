define("open-event-frontend/templates/components/widgets/forms/places-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    
  */
  {
    "id": "eAKCexlG",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/widgets/forms/places-autocomplete.hbs"
    }
  });
  _exports.default = _default;
});