define("open-event-frontend/models/social-link", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships", "open-event-frontend/utils/dictionary/social-media"], function (_exports, _attr, _base, _relationships, _socialMedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    link: (0, _attr.default)('string'),
    identifier: (0, _attr.default)('string'),
    // used for providing css id for URL validations.

    event: (0, _relationships.belongsTo)('event'),
    normalizedName: Ember.computed('site', function () {
      return this.site === 'website' ? 'globe' : this.site;
    }),
    site: Ember.computed('name', function () {
      var _this$name;
      // Even though name is required for social links and is non-nullable
      // and non-null name is being sent from API, for some reason, for certain events,
      // this throws an error, so we check first if name exists
      // https://github.com/fossasia/open-event-frontend/issues/4777

      var normalizedName = (_this$name = this.name) === null || _this$name === void 0 ? void 0 : _this$name.trim().toLowerCase();
      if (!_socialMedia.socialMediaIdentifiers.includes(normalizedName)) {
        return 'website';
      }
      return normalizedName;
    }),
    isTwitter: Ember.computed.equal('normalizedName', 'twitter'),
    isCustom: Ember.computed('normalizedName', function () {
      return !_socialMedia.socialMediaIdentifiers.includes(this.normalizedName);
    }),
    isSocial: Ember.computed('isCustom', function () {
      return !this.isCustom;
    }),
    displayName: Ember.computed('normalizedName', function () {
      var _socialMediaMap$this$, _socialMediaMap$this$2;
      return (_socialMediaMap$this$ = (_socialMediaMap$this$2 = _socialMedia.socialMediaMap[this.normalizedName]) === null || _socialMediaMap$this$2 === void 0 ? void 0 : _socialMediaMap$this$2.name) !== null && _socialMediaMap$this$ !== void 0 ? _socialMediaMap$this$ : this.name;
    }),
    buttonColor: Ember.computed('normalizedName', function () {
      return _socialMedia.buttonColor[this.normalizedName];
    })
  });
  _exports.default = _default;
});