define("open-event-frontend/models/page", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    language: (0, _attr.default)('string'),
    index: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    place: (0, _attr.default)('string')
  });
  _exports.default = _default;
});