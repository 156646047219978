define("open-event-frontend/templates/public/stream/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Public::Stream::VideoStream @event={{this.model.event}} @videoStream={{this.model.stream}} @streamId={{this.model.streamId}}/>
  
  */
  {
    "id": "Oaq0pnra",
    "block": "{\"symbols\":[],\"statements\":[[8,\"public/stream/video-stream\",[],[[\"@event\",\"@videoStream\",\"@streamId\"],[[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"stream\"]],[32,0,[\"model\",\"streamId\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/stream/view.hbs"
    }
  });
  _exports.default = _default;
});