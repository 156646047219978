define("open-event-frontend/templates/admin/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div>
    <TabbedNavigation @isNonPointing={{true}}>
      <LinkTo @route="admin.permissions.system-roles" class="item">
        {{t 'System Roles'}}
      </LinkTo>
      <LinkTo @route="admin.permissions.event-roles" class="item">
        {{t 'Event Roles'}}
      </LinkTo>
    </TabbedNavigation>
  </div>
  {{outlet}}
  
  */
  {
    "id": "ropxGaba",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[8,\"tabbed-navigation\",[],[[\"@isNonPointing\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.permissions.system-roles\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"System Roles\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.permissions.event-roles\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"Event Roles\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/permissions.hbs"
    }
  });
  _exports.default = _default;
});