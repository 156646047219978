define("open-event-frontend/templates/events/view/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid">
    <div class="row">
      <div class="{{if this.device.isMobile 'sixteen' 'three'}} wide column">
        <TabbedNavigation @isVertical={{true}}>
          <LinkTo @route="events.view.settings.export" class="item">
            {{t 'Export'}}
          </LinkTo>
          <LinkTo @route="events.view.settings.options" class="item">
            {{t 'Options'}}
          </LinkTo>
        </TabbedNavigation>
      </div>
      <div class="{{if this.device.isMobile 'sixteen' 'thirteen'}} wide column">
        {{outlet}}
      </div>
    </div>
  </div>
  
  
  */
  {
    "id": "Ax6WDWDg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,0,[\"device\",\"isMobile\"]],\"sixteen\",\"three\"],null],\" wide column\"]]],[12],[2,\"\\n      \"],[8,\"tabbed-navigation\",[],[[\"@isVertical\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"events.view.settings.export\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[\"Export\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"events.view.settings.options\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,1],[\"Options\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,0,[\"device\",\"isMobile\"]],\"sixteen\",\"thirteen\"],null],\" wide column\"]]],[12],[2,\"\\n      \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/settings.hbs"
    }
  });
  _exports.default = _default;
});