define("open-event-frontend/components/public/sponsor-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui column">
    <a href="{{@sponsor.url}}" target="_blank" rel="noopener nofollow">
      <img src="{{@sponsor.logoUrl}}" height="250" width="250" class="ui image sponsor-image" alt="{{@sponsor.name}}">
    </a>
  </div>
  
  */
  {
    "id": "z2+KyBor",
    "block": "{\"symbols\":[\"@sponsor\"],\"statements\":[[10,\"div\"],[14,0,\"ui column\"],[12],[2,\"\\n  \"],[10,\"a\"],[15,6,[31,[[32,1,[\"url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[31,[[32,1,[\"logoUrl\"]]]]],[14,\"height\",\"250\"],[14,\"width\",\"250\"],[14,0,\"ui image sponsor-image\"],[15,\"alt\",[31,[[32,1,[\"name\"]]]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/components/public/sponsor-item.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});