define("open-event-frontend/templates/admin/users/view/account/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="sixteen wide column">
    <Forms::UserProfileForm @user={{this.model}} @isLoading={{this.isLoading}} />
  </div>
  */
  {
    "id": "ievpm8s1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[8,\"forms/user-profile-form\",[],[[\"@user\",\"@isLoading\"],[[32,0,[\"model\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/users/view/account/profile.hbs"
    }
  });
  _exports.default = _default;
});