define("open-event-frontend/models/custom-form-option", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    value: (0, _attr.default)('string'),
    customForm: (0, _relationships.belongsTo)('customForm')
  });
  _exports.default = _default;
});