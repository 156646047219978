define("open-event-frontend/templates/components/orders/ticket-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if (eq @ticket.type 'free')}}
      {{t "Free"}}
  {{else}}
    <CurrencyAmount @currency={{@currency}} @amount={{@amount}}/>
  {{/if}}
  
  */
  {
    "id": "a78Yr646",
    "block": "{\"symbols\":[\"@currency\",\"@amount\",\"@ticket\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,3,[\"type\"]],\"free\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"Free\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"currency-amount\",[],[[\"@currency\",\"@amount\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/orders/ticket-price.hbs"
    }
  });
  _exports.default = _default;
});