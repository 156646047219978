define("open-event-frontend/serializers/event-topic", ["exports", "open-event-frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    attrs: {
      subTopics: 'event-sub-topics'
    }
  });
  _exports.default = _default;
});