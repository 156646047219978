define("open-event-frontend/templates/components/ui-table/cell/admin/users/cell-system-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui unordered list">
    {{#if this.record}}
      <div class="item">
        {{t 'Super Admin'}}
      </div>
    {{/if}}
    {{#if this.extraRecords.isAdmin}}
      <div class="item">
        {{t 'Admin'}}
      </div>
    {{/if}}
    <div class="item">
      {{#if this.extraRecords.isVerified}}
        {{t 'Verified User'}}
      {{else}}
        {{t 'Unverified User'}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "YWxq61S3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui unordered list\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"record\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Super Admin\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,0,[\"extraRecords\",\"isAdmin\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Admin\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"extraRecords\",\"isVerified\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"Verified User\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"Unverified User\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/users/cell-system-roles.hbs"
    }
  });
  _exports.default = _default;
});