define("open-event-frontend/templates/admin/users/view/events/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Tables::Default
    @columns={{this.columns}}
    @rows={{this.model.data}}
    @currentPage={{this.page}}
    @pageSize={{this.per_page}}
    @searchQuery={{this.search}}
    @sortBy={{this.sort_by}}
    @sortDir={{this.sort_dir}}
    @metaData={{this.model.meta}}
    @filterOptions={{this.filterOptions}}
    @widthConstraint="eq-container"
    @resizeMode="fluid"
    @fillMode="equal-column" />
  <Modals::EventDeleteModal
    @isLoading={{this.isLoading}}
    @isOpen={{this.isEventDeleteModalOpen}}
    @confirmName={{this.confirmName}}
    @eventName={{this.eventName}}
    @deleteEvent={{action "deleteEvent"}} />
  
  
  */
  {
    "id": "1Mbh9j/X",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"model\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"model\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n\"],[8,\"modals/event-delete-modal\",[],[[\"@isLoading\",\"@isOpen\",\"@confirmName\",\"@eventName\",\"@deleteEvent\"],[[32,0,[\"isLoading\"]],[32,0,[\"isEventDeleteModalOpen\"]],[32,0,[\"confirmName\"]],[32,0,[\"eventName\"]],[30,[36,0],[[32,0],\"deleteEvent\"],null]]],null],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/users/view/events/list.hbs"
    }
  });
  _exports.default = _default;
});