define("open-event-frontend/models/role", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    titleName: (0, _attr.default)('string'),
    roleInvites: (0, _relationships.hasMany)('role-invite')
  });
  _exports.default = _default;
});