define("open-event-frontend/templates/components/forms/admin/settings/system/order-expiry-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <h3 class="ui header">
    {{t 'Order Expiry Time'}}
    <div class="sub header">
      {{t 'Set registration time limit for your order in minutes'}}
    </div>
  </h3>
  <div class="field">
    <label class="required">{{t 'Registration Time Limit'}}</label>
    <div class="two wide computer four wide mobile field">
      <Input @type="number" @id="orderExpiryTime" @name="order_expiry_time" @value={{this.settings.orderExpiryTime}} @min="1" @max="60" @step="1" />
    </div>
  </div> 
  
  */
  {
    "id": "x3YnBiai",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"ui header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Order Expiry Time\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sub header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Set registration time limit for your order in minutes\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"required\"],[12],[1,[30,[36,0],[\"Registration Time Limit\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"two wide computer four wide mobile field\"],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@id\",\"@name\",\"@value\",\"@min\",\"@max\",\"@step\"],[\"number\",\"orderExpiryTime\",\"order_expiry_time\",[32,0,[\"settings\",\"orderExpiryTime\"]],\"1\",\"60\",\"1\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\" \\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/admin/settings/system/order-expiry-form.hbs"
    }
  });
  _exports.default = _default;
});