define("open-event-frontend/templates/groups/team/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{outlet}}<table class="ui center aligned stackable celled structured table">
    <thead>
      <tr>
        <th class="center aligned">{{t 'Permissions'}}</th>
        <th class="center aligned">{{t 'Owner'}}</th>
        <th class="center aligned">{{t 'Organizer'}}</th>
      </tr>
    </thead>
    <tbody>
      {{#each this.model.permissions as |permission|}}
        <tr>
          {{#if permission.header}}
            <td colspan="3" class="left aligned" style="background-color: rgba(0, 0, 0, 0.05);">{{t-var permission.title}}</td>
          {{else}}
            <td class="left aligned">{{t-var permission.title}}</td>
            <td>
              {{#if permission.owner}}
                <i class="check icon"></i>
              {{/if}}
            </td>
            <td>
              {{#if permission.organizer}}
                <i class="check icon"></i>
              {{/if}}
            </td>
          {{/if}}
        </tr>
      {{/each}}
    </tbody>
  </table>
  
  */
  {
    "id": "2i4nOhaZ",
    "block": "{\"symbols\":[\"permission\"],\"statements\":[[1,[30,[36,3],[[30,[36,2],null,null]],null]],[10,\"table\"],[14,0,\"ui center aligned stackable celled structured table\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[14,0,\"center aligned\"],[12],[1,[30,[36,4],[\"Permissions\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"center aligned\"],[12],[1,[30,[36,4],[\"Owner\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"center aligned\"],[12],[1,[30,[36,4],[\"Organizer\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"model\",\"permissions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"header\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"td\"],[14,\"colspan\",\"3\"],[14,0,\"left aligned\"],[14,5,\"background-color: rgba(0, 0, 0, 0.05);\"],[12],[1,[30,[36,0],[[32,1,[\"title\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"td\"],[14,0,\"left aligned\"],[12],[1,[30,[36,0],[[32,1,[\"title\"]]],null]],[13],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"owner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"i\"],[14,0,\"check icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"organizer\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"i\"],[14,0,\"check icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t-var\",\"if\",\"-outlet\",\"component\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/groups/team/permissions.hbs"
    }
  });
  _exports.default = _default;
});