define("open-event-frontend/components/ui-table/cell/admin/users/cell-user-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui unordered list">
    {{#unless @record}}
      <div class="item">
        <button type="button" class="w-full ui button" {{action @props.actions.sendVerificationMail @extraRecords.email}}>
          {{t "Resend Verification Email"}}
        </button>
      </div>
    {{/unless}}
    <div class="item">
      <button type="button" class="w-full ui button" {{action @props.actions.resetPasswordMail @extraRecords.email}}>
        {{t "Password Reset Email"}}
      </button>
    </div>
  </div>
  
  */
  {
    "id": "0Z3TuXiZ",
    "block": "{\"symbols\":[\"@extraRecords\",\"@props\",\"@record\"],\"statements\":[[10,\"div\"],[14,0,\"ui unordered list\"],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"w-full ui button\"],[24,4,\"button\"],[4,[38,0],[[32,0],[32,2,[\"actions\",\"sendVerificationMail\"]],[32,1,[\"email\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"Resend Verification Email\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"w-full ui button\"],[24,4,\"button\"],[4,[38,0],[[32,0],[32,2,[\"actions\",\"resetPasswordMail\"]],[32,1,[\"email\"]]],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"Password Reset Email\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "open-event-frontend/components/ui-table/cell/admin/users/cell-user-actions.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});