define("open-event-frontend/components/public/event-map", ["exports", "open-event-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    mapTile: _environment.default.mapboxToken ? "https://api.mapbox.com/styles/v1/mapbox/emerald-v8/tiles/{z}/{x}/{y}?access_token=".concat(_environment.default.mapboxToken) : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    latitude: Ember.computed('event', function () {
      var _this$event;
      return (_this$event = this.event) !== null && _this$event !== void 0 && _this$event.latitude ? this.event.latitude : 20;
    }),
    longitude: Ember.computed('event', function () {
      var _this$event2;
      return (_this$event2 = this.event) !== null && _this$event2 !== void 0 && _this$event2.longitude ? this.event.longitude : 80;
    })
  });
  _exports.default = _default;
});