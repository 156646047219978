define("open-event-frontend/helpers/sanitize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Helper to sanitize a HTML string
   */
  var _default = Ember.Helper.extend({
    sanitizer: Ember.inject.service(),
    compute: function compute(params) {
      return Ember.String.htmlSafe(this.sanitizer.purify(params[0]));
    }
  });
  _exports.default = _default;
});