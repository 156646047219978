define("open-event-frontend/utils/color", ["exports", "google-material-color", "lodash-es"], function (_exports, _googleMaterialColor, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLuma = getLuma;
  _exports.getRandomColor = getRandomColor;
  _exports.getTextColor = getTextColor;
  _exports.isDark = isDark;
  _exports.isLight = isLight;
  var DEFAULT_THRESHOLD = 160;
  function getLuma(hex) {
    // https://stackoverflow.com/a/12043228/3309666
    var c = hex === null || hex === void 0 ? void 0 : hex.toString().substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = rgb >> 16 & 0xff; // extract red
    var g = rgb >> 8 & 0xff; // extract green
    var b = rgb >> 0 & 0xff; // extract blue

    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  }

  function isLight(hex) {
    var threshold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_THRESHOLD;
    return getLuma(hex) > threshold;
  }
  function isDark(hex) {
    var threshold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_THRESHOLD;
    return !isLight(hex, threshold);
  }
  function getTextColor(backgroundColor) {
    var darkColor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '#000';
    var lightColor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '#fff';
    var threshold = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DEFAULT_THRESHOLD;
    return isLight(backgroundColor, threshold) ? darkColor : lightColor;
  }
  function getRandomColor() {
    var shades = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ['600', '700', '800', '900'];
    var color = null;
    while (!color) {
      color = _googleMaterialColor.default.random(shades[(0, _lodashEs.random)(0, 3)]);
    }
    return color;
  }
});