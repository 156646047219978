define("open-event-frontend/templates/components/ui-table/expand-row-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if (exists-in this._expandedRowIndexes this.index)}}
    <a href="#" {{action 'collapseRow' this.index bubbles=false}} class={{this.themeInstance.collapseRow}}><i class="{{this.icons.collapseRow}}"></i></a>
  {{else}}
    <a href="#" {{action 'expandRow' this.index bubbles=false}} class={{this.themeInstance.expandRow}}><i class="{{this.icons.expandRow}}"></i></a>
  {{/if}}
  
  */
  {
    "id": "hMTT6pco",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"_expandedRowIndexes\"]],[32,0,[\"index\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[32,0,[\"themeInstance\",\"collapseRow\"]]],[4,[38,0],[[32,0],\"collapseRow\",[32,0,[\"index\"]]],[[\"bubbles\"],[false]]],[12],[10,\"i\"],[15,0,[31,[[32,0,[\"icons\",\"collapseRow\"]]]]],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[32,0,[\"themeInstance\",\"expandRow\"]]],[4,[38,0],[[32,0],\"expandRow\",[32,0,[\"index\"]]],[[\"bubbles\"],[false]]],[12],[10,\"i\"],[15,0,[31,[[32,0,[\"icons\",\"expandRow\"]]]]],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"exists-in\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/expand-row-cell.hbs"
    }
  });
  _exports.default = _default;
});