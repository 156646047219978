define("open-event-frontend/templates/group-public", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="row">
    <div class="sixteen wide column">
      <Forms::group::GroupView
        @group={{this.model.group}}
        @follower={{this.follower}}
        @followers={{this.followers}} />
    </div>
  </div>
  
  */
  {
    "id": "uaBa9yhm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"forms/group/group-view\",[],[[\"@group\",\"@follower\",\"@followers\"],[[32,0,[\"model\",\"group\"]],[32,0,[\"follower\"]],[32,0,[\"followers\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/group-public.hbs"
    }
  });
  _exports.default = _default;
});