define("open-event-frontend/templates/components/widgets/forms/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Input @type="text" @value={{value}} placeholder={{t "Hex value or pick"}} />
  <button type="button" class="ui icon button picker"><i class="square large icon" style={{css color=this.value}}></i> </button>
  {{yield}}
  
  */
  {
    "id": "EikoJ/3m",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"input\",[[16,\"placeholder\",[30,[36,1],[\"Hex value or pick\"],null]]],[[\"@type\",\"@value\"],[\"text\",[34,0]]],null],[2,\"\\n\"],[10,\"button\"],[14,0,\"ui icon button picker\"],[14,4,\"button\"],[12],[10,\"i\"],[14,0,\"square large icon\"],[15,5,[30,[36,2],null,[[\"color\"],[[32,0,[\"value\"]]]]]],[12],[13],[2,\" \"],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"t\",\"css\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/widgets/forms/color-picker.hbs"
    }
  });
  _exports.default = _default;
});