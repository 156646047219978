define("open-event-frontend/components/forms/orders/editable-fields", ["exports", "open-event-frontend/mixins/form", "moment-timezone", "lodash-es", "open-event-frontend/utils/validators"], function (_exports, _form, _momentTimezone, _lodashEs, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    router: Ember.inject.service(),
    autoScrollToErrors: false,
    buyerFirstName: Ember.computed.oneWay('buyerHasFirstName'),
    buyerLastName: Ember.computed.oneWay('buyerHasLastName'),
    buyer: Ember.computed.readOnly('data.user'),
    buyerHasFirstName: Ember.computed.readOnly('data.user.firstName'),
    buyerHasLastName: Ember.computed.readOnly('data.user.lastName'),
    holders: Ember.computed('data.attendees', 'buyer', function () {
      return this.data.attendees;
    }),
    isPaidOrder: Ember.computed('data', function () {
      if (!this.data.amount) {
        this.data.set('paymentMode', 'free');
        return false;
      }
      return true;
    }),
    isBillingInfoNeeded: Ember.computed('event', 'data.isBillingEnabled', function () {
      return this.event.isBillingInfoMandatory || this.data.isBillingEnabled;
    }),
    getRemainingTime: Ember.computed('settings', function () {
      var orderExpiryTime = this.settings.orderExpiryTime;
      var willExpireAt = this.data.createdAt.add(orderExpiryTime, 'minutes');
      this.timer(willExpireAt, this.data.identifier);
    }),
    timer: function timer(willExpireAt, orderIdentifier) {
      var _this = this;
      Ember.run.later(function () {
        if (_this.session.currentRouteName !== 'orders.new') {
          return;
        }
        var currentTime = (0, _momentTimezone.default)();
        var diff = _momentTimezone.default.duration(willExpireAt.diff(currentTime));
        if (diff > 0) {
          _this.set('getRemainingTime', _momentTimezone.default.utc(diff.asMilliseconds()).format('mm:ss'));
          _this.timer(willExpireAt, orderIdentifier);
        } else {
          _this.set('getRemainingTime', '00:00');
          _this.data.set('status', 'expired');
          _this.data.reload();
          _this.router.transitionTo('orders.expired', orderIdentifier);
        }
      }, 1000);
    },
    getValidationRules: function getValidationRules() {
      var _this2 = this;
      var firstNameValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your first name.')
        }]
      };
      var lastNameValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your last name.')
        }]
      };
      var emailValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your email.')
        }, {
          type: 'regExp',
          value: _validators.validEmail,
          prompt: this.l10n.t('Please enter a valid email address.')
        }]
      };
      var genderValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select categories that describe your gender identity.')
        }]
      };
      var ageGroupValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select your age group.')
        }]
      };
      var acceptReceiveEmailsValidation = {
        rules: [{
          type: 'checked',
          value: false,
          prompt: this.l10n.t('You need to agree to the condition of the organizer to receive emails in order to continue the order process.')
        }]
      };
      var acceptVideoRecordingValidation = {
        rules: [{
          type: 'checked',
          value: false,
          prompt: this.l10n.t('In order to complete the order process you need to agree to the photo & video & text consent.')
        }]
      };
      var acceptShareDetailsValidation = {
        rules: [{
          type: 'checked',
          value: false,
          prompt: this.l10n.t('You need to agree to the condition of the organizer to share information among event partners in order to continue the order process. Such a requirement could be necessary in order to provide the event services.')
        }]
      };
      var addressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your address.')
        }]
      };
      var cityValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your city.')
        }]
      };
      var stateValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your state.')
        }]
      };
      var countryValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please select your country.')
        }]
      };
      var jobTitleValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your job title.')
        }]
      };
      var phoneValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter a mobile number.')
        }]
      };
      var taxBusinessInfoValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your tax business info.')
        }]
      };
      var billingAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your billing address.')
        }]
      };
      var homeAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your home address.')
        }]
      };
      var homeWikiValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your home wiki.')
        }]
      };
      var wikiScholarshipValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your wiki scholarship.')
        }]
      };
      var shippingAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your shipping address.')
        }]
      };
      var companyValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your organisation.')
        }]
      };
      var workAddressValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your work address.')
        }]
      };
      var workPhoneValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter your work phone.')
        }]
      };
      var websiteValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid URL.')
        }]
      };
      var websiteRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter URL of website.')
        }, {
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid URL.')
        }]
      };
      var blogValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid URL.')
        }]
      };
      var blogRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter URL of website.')
        }, {
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please enter a valid URL.')
        }]
      };
      var twitterValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validTwitterProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Twitter profile URL.')
        }]
      };
      var twitterRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Twitter link.')
        }, {
          type: 'regExp',
          value: _validators.validTwitterProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Twitter profile URL.')
        }]
      };
      var facebookValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validFacebookProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Facebook account URL.')
        }]
      };
      var facebookRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Facebook link.')
        }, {
          type: 'regExp',
          value: _validators.validFacebookProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Facebook account URL.')
        }]
      };
      var githubValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validGithubProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid GitHub profile URL.')
        }]
      };
      var githubRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter GitHub link.')
        }, {
          type: 'regExp',
          value: _validators.validGithubProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid GitHub profile URL.')
        }]
      };
      var instagramValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validInstagramProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Instagram account URL.')
        }]
      };
      var instagramRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Instagram link')
        }, {
          type: 'regExp',
          value: _validators.validInstagramProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Instagram account URL.')
        }]
      };
      var linkedinValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.validLinkedinProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Linkedin account URL.')
        }]
      };
      var linkedinRequiredValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Linkedin link.')
        }, {
          type: 'regExp',
          value: _validators.validLinkedinProfileUrlPattern,
          prompt: this.l10n.t('Please enter a valid Linkedin account URL.')
        }]
      };
      var isConsentFormFieldValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Code of conduct consent.')
        }]
      };
      var isConsentOfRefundPolicyValidation = {
        optional: true,
        rules: [{
          type: 'regExp',
          value: _validators.compulsoryProtocolValidUrlPattern,
          prompt: this.l10n.t('Please consent to the Refund Policy.')
        }]
      };
      var nativeLanguageValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Language Form.')
        }]
      };
      var fluentLanguageValidation = {
        rules: [{
          type: 'empty',
          prompt: this.l10n.t('Please enter Language Form.')
        }]
      };
      var validationRules = {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          email: {
            identifier: 'email',
            rules: [{
              type: 'regExp',
              value: _validators.validEmail,
              prompt: this.l10n.t('Please enter a valid email address.')
            }]
          },
          country: {
            identifier: 'country',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select your country.')
            }]
          },
          taxBusinessInfo: {
            identifier: 'taxBusinessInfo',
            optional: true
          },
          address: {
            identifier: 'address',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your address.')
            }]
          },
          city: {
            identifier: 'city',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your city.')
            }]
          },
          zipCode: {
            identifier: 'zip_code',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your ZIP code.')
            }]
          },
          payVia: {
            identifier: 'payment_mode',
            rules: [{
              type: 'checked',
              prompt: this.l10n.t('Please specify your choice of payment method.')
            }]
          }
        }
      };
      this.holders.forEach(function (value, index) {
        validationRules.fields["firstname_required_".concat(index)] = firstNameValidation;
        validationRules.fields["lastname_required_".concat(index)] = lastNameValidation;
        validationRules.fields["email_required_".concat(index)] = emailValidation;
        validationRules.fields["gender_required_".concat(index)] = genderValidation;
        validationRules.fields["ageGroup_required_".concat(index)] = ageGroupValidation;
        validationRules.fields["address_required_".concat(index)] = addressValidation;
        validationRules.fields["acceptReceiveEmails_required_".concat(index)] = acceptReceiveEmailsValidation;
        validationRules.fields["acceptVideoRecording_required_".concat(index)] = acceptVideoRecordingValidation;
        validationRules.fields["acceptShareDetails_required_".concat(index)] = acceptShareDetailsValidation;
        validationRules.fields["city_required_".concat(index)] = cityValidation;
        validationRules.fields["state_required_".concat(index)] = stateValidation;
        validationRules.fields["country_required_".concat(index)] = countryValidation;
        validationRules.fields["jobTitle_required_".concat(index)] = jobTitleValidation;
        validationRules.fields["phone_required_".concat(index)] = phoneValidation;
        validationRules.fields["taxBusinessInfo_required_".concat(index)] = taxBusinessInfoValidation;
        validationRules.fields["billingAddress_required_".concat(index)] = billingAddressValidation;
        validationRules.fields["homeAddress_required_".concat(index)] = homeAddressValidation;
        validationRules.fields["homeWiki_required_".concat(index)] = homeWikiValidation;
        validationRules.fields["wikiScholarship_required_".concat(index)] = wikiScholarshipValidation;
        validationRules.fields["shippingAddress_required_".concat(index)] = shippingAddressValidation;
        validationRules.fields["company_required_".concat(index)] = companyValidation;
        validationRules.fields["workAddress_required_".concat(index)] = workAddressValidation;
        validationRules.fields["workPhone_required_".concat(index)] = workPhoneValidation;
        validationRules.fields["website_".concat(index)] = websiteValidation;
        validationRules.fields["website_required_".concat(index)] = websiteRequiredValidation;
        validationRules.fields["blog_".concat(index)] = blogValidation;
        validationRules.fields["blog_required_".concat(index)] = blogRequiredValidation;
        validationRules.fields["twitter_".concat(index)] = twitterValidation;
        validationRules.fields["twitter_required_".concat(index)] = twitterRequiredValidation;
        validationRules.fields["facebook_".concat(index)] = facebookValidation;
        validationRules.fields["facebook_required_".concat(index)] = facebookRequiredValidation;
        validationRules.fields["github_".concat(index)] = githubValidation;
        validationRules.fields["github_required_".concat(index)] = githubRequiredValidation;
        validationRules.fields["instagram_".concat(index)] = instagramValidation;
        validationRules.fields["instagram_required_".concat(index)] = instagramRequiredValidation;
        validationRules.fields["linkedin_".concat(index)] = linkedinValidation;
        validationRules.fields["linkedin_required_".concat(index)] = linkedinRequiredValidation;
        validationRules.fields["native_language_required_".concat(index)] = nativeLanguageValidation;
        validationRules.fields["fluent_language_required_".concat(index)] = fluentLanguageValidation;
        validationRules.fields["is_consent_form_field_required_".concat(index)] = isConsentFormFieldValidation;
        validationRules.fields["is_consent_of_refund_policy_required_".concat(index)] = isConsentOfRefundPolicyValidation;
        _this2.allFields.attendee.filter(function (field) {
          return field.isComplex && field.isRequired;
        }).forEach(function (field) {
          validationRules.fields["".concat(field.fieldIdentifier, "_required_").concat(index)] = {
            rules: [{
              type: 'empty',
              prompt: _this2.l10n.t('Please enter {{field}}.', {
                field: field.name
              })
            }]
          };
        });
      });
      return validationRules;
    },
    allFields: Ember.computed('fields', function () {
      var _this$fields$toArray, _this$fields$toArray2;
      var requiredFixed = (_this$fields$toArray = this.fields.toArray()) === null || _this$fields$toArray === void 0 ? void 0 : _this$fields$toArray.filter(function (field) {
        return field.isFixed;
      });
      var customFields = (0, _lodashEs.orderBy)((_this$fields$toArray2 = this.fields.toArray()) === null || _this$fields$toArray2 === void 0 ? void 0 : _this$fields$toArray2.filter(function (field) {
        return !field.isFixed;
      }), ['position']);
      return (0, _lodashEs.groupBy)(requiredFixed.concat(customFields), function (field) {
        return field.get('form');
      });
    })
  });
  _exports.default = _default;
});