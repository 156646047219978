define("open-event-frontend/components/forms/wizard/attendee-step", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/mixins/event-wizard", "lodash-es", "ember-uuid"], function (_exports, _form2, _eventWizard, _lodashEs, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form2.default, _eventWizard.default, {
    tickets: [],
    excludeTickets: Ember.A(),
    isOldFormMode: false,
    isInit: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.prepareCustomFormsForShow();
    },
    prepareCustomFormsForShow: function prepareCustomFormsForShow() {
      var _this = this;
      var _this$data = this.data,
        tickets = _this$data.tickets,
        customForms = _this$data.customForms,
        forms = _this$data.forms;
      if (this.isInit || forms.length) {
        return;
      }
      this.isInit = true;
      var noForm = tickets.filter(function (_ticket) {
        return _ticket.formID;
      }).length === 0;
      if (noForm) {
        var _formID = (0, _emberUuid.v4)();
        var _fields = customForms === null || customForms === void 0 ? void 0 : customForms.filter(function (field) {
          if (!field.isFixed && !field.isDeleted) {
            field.formID = _formID;
            return true;
          }
          return false;
        });
        if (_fields.length > 0) {
          tickets.forEach(function (_ticket) {
            _ticket.formID = _formID;
            _this.excludeTickets.pushObject(_ticket);
          });
          forms.pushObject(this.store.createRecord('custom-form-ticket', {
            formID: _formID,
            customForms: _fields,
            ticketsDetails: tickets
          }));
        }
      } else {
        var _forms = {};
        tickets.forEach(function (ticket) {
          var formID = ticket.formID;
          if (formID) {
            if (_forms[formID]) {
              _forms[formID].ticketsDetails.pushObject(ticket);
            } else {
              _forms[formID] = {
                ticketsDetails: [ticket]
              };
            }
            _this.excludeTickets.pushObject(ticket);
          }
        });
        Object.keys(_forms).forEach(function (_id) {
          var selectedTicket = _forms[_id].ticketsDetails;
          forms.pushObject({
            formID: _id,
            ticketsDetails: selectedTicket,
            customForms: customForms.filter(function (_field) {
              return _field.formID === _id;
            })
          });
        });
      }
    },
    prepareCustomFormsForSave: function prepareCustomFormsForSave() {
      var _this2 = this;
      this.set('loading', true);
      this.data.forms.forEach(function (_form) {
        var formID = _form.formID,
          customForms = _form.customForms,
          ticketsDetails = _form.ticketsDetails;
        customForms.forEach(function (field) {
          if (!field.isDeleted) {
            field.formID = formID;
          }
          if (!field.id) {
            _this2.data.customForms.pushObject(field);
          }
        });
        ticketsDetails.forEach(function (ticket) {
          ticket.formID = formID;
        });
      });
    },
    fixedFields: Ember.computed('data.customForms.@each', function () {
      var _this$data$customForm;
      return (_this$data$customForm = this.data.customForms) === null || _this$data$customForm === void 0 ? void 0 : _this$data$customForm.filter(function (field) {
        return field.isFixed;
      });
    }),
    selectableTickets: Ember.computed('excludeTickets.@each', 'form.@each', function () {
      var _this3 = this;
      return this.data.tickets.filter(function (ticket) {
        return !_this3.excludeTickets.includes(ticket);
      });
    }),
    editableFields: Ember.computed('data.customForms.@each', function () {
      var _this$data$customForm2;
      var filteredFields = (_this$data$customForm2 = this.data.customForms) === null || _this$data$customForm2 === void 0 ? void 0 : _this$data$customForm2.filter(function (field) {
        return !field.isFixed;
      });
      var fields = (0, _lodashEs.sortBy)(filteredFields, ['isComplex', 'name']);
      return (0, _lodashEs.sortBy)(fields, ['position']);
    }),
    revertChanges: Ember.observer('data.event.isTicketFormEnabled', function () {
      if (!this.data.event.isTicketFormEnabled) {
        this.editableFields.forEach(function (field) {
          return field.set('isRequired', false);
        });
      }
    }),
    showEditColumn: Ember.computed('editableFields.@each', function () {
      var _this$editableFields;
      return (_this$editableFields = this.editableFields) === null || _this$editableFields === void 0 ? void 0 : _this$editableFields.some(function (field) {
        return field.isComplex;
      });
    }),
    actions: {
      removeField: function removeField(field) {
        field.deleteRecord();
      },
      addMoreForm: function addMoreForm() {
        var ticketsDetails = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        var _formID = (0, _emberUuid.v4)();
        this.data.forms.pushObject(this.store.createRecord('custom-form-ticket', {
          formID: _formID,
          customForms: this.getCustomAttendeeForm(this.data.event, _formID),
          ticketsDetails: ticketsDetails
        }));
      },
      onFormUpdateTicket: function onFormUpdateTicket(changedData) {
        var added = changedData.added,
          changed = changedData.changed,
          formID = changedData.formID;
        changed.forEach(function (ticket) {
          ticket.formID = added ? formID : '';
        });
        if (added) {
          this.excludeTickets.pushObjects(changed);
        } else {
          this.excludeTickets.removeObjects(changed);
        }
      },
      saveDraft: function saveDraft() {
        this.prepareCustomFormsForSave();
        this._super();
      },
      saveForm: function saveForm() {
        this.prepareCustomFormsForSave();
        this._super();
      },
      move: function move(direction) {
        this.prepareCustomFormsForSave();
        this._super(direction);
      },
      onRemoveForm: function onRemoveForm(_id) {
        var deleteForm = this.data.forms.find(function (_form) {
          return _form.formID === _id;
        });
        if (deleteForm) {
          var ticketsDetails = deleteForm.ticketsDetails,
            customForms = deleteForm.customForms;
          ticketsDetails.forEach(function (ticket) {
            ticket.formID = '';
          });
          customForms.forEach(function (field) {
            field.deleteRecord();
          });
          this.excludeTickets.removeObjects(deleteForm.ticketsDetails);
          this.data.forms.removeObject(deleteForm);
        }
      }
    }
  });
  _exports.default = _default;
});