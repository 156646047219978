define("open-event-frontend/components/stripe-checkout", ["exports", "ember-cli-stripe/components/stripe-checkout"], function (_exports, _stripeCheckout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stripeCheckout.default;
    }
  });
});