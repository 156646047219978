define("open-event-frontend/models/import-job", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    resultStatus: (0, _attr.default)('string'),
    result: (0, _attr.default)('string'),
    task: (0, _attr.default)('string'),
    startsAt: (0, _attr.default)('moment', {
      readOnly: true
    })
  });
  _exports.default = _default;
});