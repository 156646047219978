define("open-event-frontend/models/admin-sales-by-event", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    owner: (0, _attr.default)(),
    type: (0, _attr.default)(),
    endsAt: (0, _attr.default)('moment'),
    startsAt: (0, _attr.default)('moment'),
    paymentCurrency: (0, _attr.default)('string'),
    completedOrderSales: (0, _attr.default)('number'),
    placedOrderSales: (0, _attr.default)('number'),
    pendingOrderSales: (0, _attr.default)('number'),
    completedOrderTickets: (0, _attr.default)('number'),
    placedOrderTickets: (0, _attr.default)('number'),
    pendingOrderTickets: (0, _attr.default)('number'),
    sales: (0, _attr.default)(),
    ownerId: (0, _attr.default)()
  });
  _exports.default = _default;
});