define("open-event-frontend/templates/components/events/event-import-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="sixteen wide column">
    <form class="ui form" {{action 'submit' on='submit'}}>
      <div class="field {{if this.device.isMobile 'sixteen' 'five'}} wide column">
        <label class="required" for="file"> {{t 'Select event source file to import'}}</label>
        <input accept='.zip,.xml,.ical,.ics,.xcal' type="file" disabled={{this.disabled}} onchange={{action 'onFileSelected' value='target.files'}}>
      </div>
      <div class="field {{if this.device.isMobile 'sixteen' 'five'}} wide column">
        <span class="text muted">
          <b>{{t 'Supported formats:'}}</b>
          {{t 'Open Event compatible json package (.zip), PentabarfXML (.xml), iCalendar (.ical, .ics), XML Representation of iCalendar (.xcal)'}}
        </span>
      </div>
      <button class="ui small primary button" type="submit" disabled={{this.isImporting}}>{{t 'Import Event'}}</button>
      <div style="margin-top: 10px;">
        <span>{{this.importStatus}}</span><br>
        <span class="red">{{this.importError}}</span>
      </div>
    </form>
  </div>
  
  */
  {
    "id": "mopH4iCF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[11,\"form\"],[24,0,\"ui form\"],[4,[38,0],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"field \",[30,[36,1],[[32,0,[\"device\",\"isMobile\"]],\"sixteen\",\"five\"],null],\" wide column\"]]],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"required\"],[14,\"for\",\"file\"],[12],[2,\" \"],[1,[30,[36,2],[\"Select event source file to import\"],null]],[13],[2,\"\\n      \"],[10,\"input\"],[14,\"accept\",\".zip,.xml,.ical,.ics,.xcal\"],[15,\"disabled\",[32,0,[\"disabled\"]]],[15,\"onchange\",[30,[36,0],[[32,0],\"onFileSelected\"],[[\"value\"],[\"target.files\"]]]],[14,4,\"file\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"field \",[30,[36,1],[[32,0,[\"device\",\"isMobile\"]],\"sixteen\",\"five\"],null],\" wide column\"]]],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text muted\"],[12],[2,\"\\n        \"],[10,\"b\"],[12],[1,[30,[36,2],[\"Supported formats:\"],null]],[13],[2,\"\\n        \"],[1,[30,[36,2],[\"Open Event compatible json package (.zip), PentabarfXML (.xml), iCalendar (.ical, .ics), XML Representation of iCalendar (.xcal)\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"button\"],[14,0,\"ui small primary button\"],[15,\"disabled\",[32,0,[\"isImporting\"]]],[14,4,\"submit\"],[12],[1,[30,[36,2],[\"Import Event\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,5,\"margin-top: 10px;\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[1,[32,0,[\"importStatus\"]]],[13],[10,\"br\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"red\"],[12],[1,[32,0,[\"importError\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/events/event-import-section.hbs"
    }
  });
  _exports.default = _default;
});