define("open-event-frontend/templates/components/modals/user-delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="header">
    {{t 'Are you sure you would like to delete your account?'}}
    <div class="muted small text">
      {{t 'Deleting the account will delete all the data associated with it.'}}
    </div>
  </div>
  <div class="content">
    <div class="ui {{if this.isLoading 'loading'}} form" autocomplete="off">
      <div class="field">
        <div class="label">
          {{t 'Please enter your Email ID to confirm that you want to delete the account.'}}
        </div>
        <Input @type="text" @name="confirm_email" @value={{this.confirmEmail}} required={{true}} />
      </div>
    </div>
  </div>
  <div class="actions">
    <button type="button" class="ui black button" {{action 'close'}}>
      {{t 'Cancel'}}
    </button>
    <button {{action this.openConfirmDeleteUserModal}} class="ui red button {{if this.isEmailDifferent 'disabled'}}">
      {{t 'Proceed'}}
    </button>
  </div>
  
  */
  {
    "id": "N2E+1dNq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Are you sure you would like to delete your account?\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"muted small text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Deleting the account will delete all the data associated with it.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,1],[[32,0,[\"isLoading\"]],\"loading\"],null],\" form\"]]],[14,\"autocomplete\",\"off\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Please enter your Email ID to confirm that you want to delete the account.\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"input\",[[16,\"required\",true]],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"confirm_email\",[32,0,[\"confirmEmail\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"ui red button \",[30,[36,1],[[32,0,[\"isEmailDifferent\"]],\"disabled\"],null]]]],[4,[38,2],[[32,0],[32,0,[\"openConfirmDeleteUserModal\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Proceed\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/user-delete-modal.hbs"
    }
  });
  _exports.default = _default;
});