define("open-event-frontend/templates/events/view/videoroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{outlet}}
  
  */
  {
    "id": "FyLdovvR",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/videoroom.hbs"
    }
  });
  _exports.default = _default;
});