define("open-event-frontend/templates/components/orders/organizer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui segments">
    <div class="ui secondary segment">
      <h3 class="weight-400">{{t 'Organizer'}}</h3>
    </div>
    <div class="ui padded segment">
      <h3>{{this.data.event.ownerName}}</h3>
      <br>
      {{sanitize this.data.event.ownerDescription}}
    </div>
  </div>
  
  */
  {
    "id": "tg47sysS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui segments\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui secondary segment\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"weight-400\"],[12],[1,[30,[36,0],[\"Organizer\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui padded segment\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[32,0,[\"data\",\"event\",\"ownerName\"]]],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"data\",\"event\",\"ownerDescription\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"sanitize\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/orders/organizer-info.hbs"
    }
  });
  _exports.default = _default;
});