define("open-event-frontend/templates/public/exhibition/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Tables::Utilities::SearchBox
    class="mb-2 mr-2"
    @searchQuery={{this.search}}
    @size="large"
  />
  <div class="ui grid container {{if this.device.isMobile 'one' 'three'}} column">
    {{#each this.model.exhibitors as |exhibitor|}}
      <div class="column speaker-column">
        <a href="{{href-to 'public.exhibition.view' exhibitor.id}}">
          <Public::ExhibitorItem @exhibitor={{exhibitor}} />
        </a>
      </div>
    {{/each}}
    <div class="sixteen wide column">
      <InfinityLoader @infinityModel={{this.model.exhibitors}}>
        <div class="ui loading very padded basic segment">
        </div>
        {{this.infintyModel.reachedInfinity}}
      </InfinityLoader>
    </div>
  </div>
  
  */
  {
    "id": "QQMJ4y6A",
    "block": "{\"symbols\":[\"exhibitor\"],\"statements\":[[8,\"tables/utilities/search-box\",[[24,0,\"mb-2 mr-2\"]],[[\"@searchQuery\",\"@size\"],[[32,0,[\"search\"]],\"large\"]],null],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"ui grid container \",[30,[36,1],[[32,0,[\"device\",\"isMobile\"]],\"one\",\"three\"],null],\" column\"]]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"model\",\"exhibitors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"column speaker-column\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[[30,[36,0],[\"public.exhibition.view\",[32,1,[\"id\"]]],null]]]],[12],[2,\"\\n        \"],[8,\"public/exhibitor-item\",[],[[\"@exhibitor\"],[[32,1]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"infinity-loader\",[],[[\"@infinityModel\"],[[32,0,[\"model\",\"exhibitors\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui loading very padded basic segment\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[32,0,[\"infintyModel\",\"reachedInfinity\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"href-to\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/exhibition/index.hbs"
    }
  });
  _exports.default = _default;
});