define("open-event-frontend/templates/events/view/settings/export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui warning message mb-8 mr--4">
    <div class="header">
      {{t 'This feature is still in alpha stage.'}}
    </div>
    {{t 'Please use with care.'}} {{t 'Thank You.'}}
  </div>
  <div class="ui grid stackable">
    <div class="eight wide column">
      <Events::View::Export::DownloadZip @startGeneration={{action "startGeneration"}} @isDownloadDisabled={{this.isDownloadDisabled}} @eventExportStatus={{this.eventExportStatus}} @eventDownloadUrl={{this.eventDownloadUrl}} @data={{this.data}} @isLoading={{this.isLoading}} />
      <div class="ui divider"></div>
      <Events::View::Export::DownloadCommon @downloadType="Pentabarf XML" @downloadUrl="pentabarf.xml" @model={{this.model}} />
      <div class="ui divider"></div>
      <Events::View::Export::DownloadCommon @downloadType="iCalendar" @downloadUrl="calendar.ics" @model={{this.model}} />
      <div class="ui divider"></div>
      <Events::View::Export::DownloadCommon @downloadType="iCalendar XML (xCal)" @downloadUrl="calendar.xcs" @model={{this.model}} />
    </div>
    <div class="eight wide column">
      <Events::View::Export::ApiResponse @eventId={{this.model.id}} @event={{this.model}} />
    </div>
  </div>
  
  */
  {
    "id": "Mq0XR3QD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui warning message mb-8 mr--4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"This feature is still in alpha stage.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,0],[\"Please use with care.\"],null]],[2,\" \"],[1,[30,[36,0],[\"Thank You.\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui grid stackable\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"eight wide column\"],[12],[2,\"\\n    \"],[8,\"events/view/export/download-zip\",[],[[\"@startGeneration\",\"@isDownloadDisabled\",\"@eventExportStatus\",\"@eventDownloadUrl\",\"@data\",\"@isLoading\"],[[30,[36,1],[[32,0],\"startGeneration\"],null],[32,0,[\"isDownloadDisabled\"]],[32,0,[\"eventExportStatus\"]],[32,0,[\"eventDownloadUrl\"]],[32,0,[\"data\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui divider\"],[12],[13],[2,\"\\n    \"],[8,\"events/view/export/download-common\",[],[[\"@downloadType\",\"@downloadUrl\",\"@model\"],[\"Pentabarf XML\",\"pentabarf.xml\",[32,0,[\"model\"]]]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui divider\"],[12],[13],[2,\"\\n    \"],[8,\"events/view/export/download-common\",[],[[\"@downloadType\",\"@downloadUrl\",\"@model\"],[\"iCalendar\",\"calendar.ics\",[32,0,[\"model\"]]]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui divider\"],[12],[13],[2,\"\\n    \"],[8,\"events/view/export/download-common\",[],[[\"@downloadType\",\"@downloadUrl\",\"@model\"],[\"iCalendar XML (xCal)\",\"calendar.xcs\",[32,0,[\"model\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"eight wide column\"],[12],[2,\"\\n    \"],[8,\"events/view/export/api-response\",[],[[\"@eventId\",\"@event\"],[[32,0,[\"model\",\"id\"]],[32,0,[\"model\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/settings/export.hbs"
    }
  });
  _exports.default = _default;
});