define("open-event-frontend/templates/components/forms/admin/settings/system/start-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <h3 class="ui header">
    {{t 'Start Page'}}
    <div class="sub header">
      {{t 'To customize the default start page'}}
    </div>
  </h3>
  <div class="field">
    <UiRadio @label={{t "Use default start page"}} @name="start"  @value="default" @current={{@settings.startPgEnabled}}  @onChange={{action (mut @settings.startPgEnabled)}} />
  </div>
  <div class="field">
    <UiRadio @label={{t "Use event as start page with ID (Enter event ID here)"}} @name="start" @value="event" @current={{@settings.startPgEnabled}}   @onChange={{action (mut @settings.startPgEnabled)}}  />
  </div>
  {{#if (eq @settings.startPgEnabled 'event') }}
    <div class="field">
      <label>
        {{t 'Use event as start page with ID (Enter event ID here)'}}
      </label>
      <Input @type="text" @name="start_page" @value={{@settings.startPgEventId}} />
    </div>
  {{/if}}
  
  */
  {
    "id": "n+nRlmHU",
    "block": "{\"symbols\":[\"@settings\"],\"statements\":[[10,\"h3\"],[14,0,\"ui header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Start Page\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sub header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"To customize the default start page\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[8,\"ui-radio\",[],[[\"@label\",\"@name\",\"@value\",\"@current\",\"@onChange\"],[[30,[36,0],[\"Use default start page\"],null],\"start\",\"default\",[32,1,[\"startPgEnabled\"]],[30,[36,2],[[32,0],[30,[36,1],[[32,1,[\"startPgEnabled\"]]],null]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n  \"],[8,\"ui-radio\",[],[[\"@label\",\"@name\",\"@value\",\"@current\",\"@onChange\"],[[30,[36,0],[\"Use event as start page with ID (Enter event ID here)\"],null],\"start\",\"event\",[32,1,[\"startPgEnabled\"]],[30,[36,2],[[32,0],[30,[36,1],[[32,1,[\"startPgEnabled\"]]],null]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"startPgEnabled\"]],\"event\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Use event as start page with ID (Enter event ID here)\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"start_page\",[32,1,[\"startPgEventId\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/admin/settings/system/start-page.hbs"
    }
  });
  _exports.default = _default;
});