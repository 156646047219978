define("open-event-frontend/templates/groups/edit/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <GroupNav @group={{this.model.group}} />
  <div class="row">
    <div class="sixteen wide column">
      <Forms::group::GroupEventsForm
        @group={{this.model.group}}
        @groupEvents={{this.model.groupEvents}}
        @upcomingEvents={{this.model.upcomingEvents}}
        @pastEvents={{this.model.pastEvents}}
        @isLoading={{this.isLoading}} />
    </div>
  </div>
  
  */
  {
    "id": "fKV4iGx+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"group-nav\",[],[[\"@group\"],[[32,0,[\"model\",\"group\"]]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"forms/group/group-events-form\",[],[[\"@group\",\"@groupEvents\",\"@upcomingEvents\",\"@pastEvents\",\"@isLoading\"],[[32,0,[\"model\",\"group\"]],[32,0,[\"model\",\"groupEvents\"]],[32,0,[\"model\",\"upcomingEvents\"]],[32,0,[\"model\",\"pastEvents\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/groups/edit/events.hbs"
    }
  });
  _exports.default = _default;
});