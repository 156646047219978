define("open-event-frontend/templates/components/ui-table/cell/events/view/speakers/cell-simple-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui list">
    {{#each this.record as |session|}}
      {{#if (not this.eq session.deletedAt)}}
        <div class="item">
          <div class="item">
            <div class="ui {{session-color session.state}} horizontal label">{{session.state}}</div>
            {{session.title}}
          </div>
        </div>
      {{/if}}
    {{/each}}
  </div>
  */
  {
    "id": "sHFOp1hV",
    "block": "{\"symbols\":[\"session\"],\"statements\":[[10,\"div\"],[14,0,\"ui list\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"record\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"eq\"]],[32,1,[\"deletedAt\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n          \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,0],[[32,1,[\"state\"]]],null],\" horizontal label\"]]],[12],[1,[32,1,[\"state\"]]],[13],[2,\"\\n          \"],[1,[32,1,[\"title\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"session-color\",\"not\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/speakers/cell-simple-sessions.hbs"
    }
  });
  _exports.default = _default;
});