define("open-event-frontend/utils/dictionary/badge-custom-fields", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.badgeCustomFields = void 0;
  var badgeCustomFields = [{
    name: _text.tn.t('Name'),
    position: 1
  }, {
    name: _text.tn.t('Organisation'),
    position: 2
  }, {
    name: _text.tn.t('Position'),
    position: 3
  }, {
    name: _text.tn.t('QR'),
    position: 4
  }];
  _exports.badgeCustomFields = badgeCustomFields;
});