define("open-event-frontend/models/admin-sales-invoice", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    identifier: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    createdAt: (0, _attr.default)('moment'),
    completedAt: (0, _attr.default)('moment'),
    eventName: (0, _attr.default)('string'),
    sentTo: (0, _attr.default)('string')
  });
  _exports.default = _default;
});