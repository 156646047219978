define("open-event-frontend/templates/events/view/edit/basic-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Forms::Wizard::BasicDetailsStep @data={{this.model}} @move="move" @save="save" @isLoading={{this.isLoading}} />
  
  */
  {
    "id": "sh4yMQ2m",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/wizard/basic-details-step\",[],[[\"@data\",\"@move\",\"@save\",\"@isLoading\"],[[32,0,[\"model\"]],\"move\",\"save\",[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/edit/basic-details.hbs"
    }
  });
  _exports.default = _default;
});