define("open-event-frontend/serializers/order", ["exports", "open-event-frontend/serializers/application", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _application, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend(_customPrimaryKey.default, {
    primaryKey: 'attributes.identifier',
    normalize: function normalize() {
      var payload = this._super.apply(this, arguments);
      payload.data = this.addLinks(payload.data);
      return payload;
    },
    addLinks: function addLinks(order) {
      order.relationships.attendees = {
        links: {
          related: "/v1/orders/".concat(order.id, "/attendees"),
          self: "/v1/orders/".concat(order.id, "/attendees")
        }
      };
      order.relationships.tickets = {
        links: {
          related: "/v1/orders/".concat(order.id, "/tickets"),
          self: "/v1/orders/".concat(order.id, "/tickets")
        }
      };
      order.relationships.event = {
        links: {
          related: "/v1/orders/".concat(order.id, "/event"),
          self: "/v1/orders/".concat(order.id, "/event")
        }
      };
      return order;
    },
    serialize: function serialize(snapshot) {
      var json = this._super.apply(this, arguments);
      var attendeeSnapshots = snapshot._hasManyRelationships.attendees;
      var attendees = {
        data: []
      };
      if (attendeeSnapshots) {
        attendeeSnapshots.forEach(function (snapshot) {
          attendees.data.push({
            type: 'attendee',
            id: snapshot.id
          });
        });
      }
      json.data.relationships.attendees = attendees;
      return json;
    }
  });
  _exports.default = _default;
});