define("open-event-frontend/utils/dictionary/languages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.languages = void 0;
  var languages = [{
    name: 'English'
  }, {
    name: 'Chinese'
  }, {
    name: 'French'
  }, {
    name: 'German'
  }, {
    name: 'Indonesian'
  }, {
    name: 'Korean'
  }, {
    name: 'Polish'
  }, {
    name: 'Spanish'
  }, {
    name: 'Thai'
  }, {
    name: 'Vietnamese'
  }, {
    name: 'Hindi'
  }, {
    name: 'Japanese'
  }, {
    name: 'Russian'
  }];
  _exports.languages = languages;
});