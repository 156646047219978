define("open-event-frontend/templates/components/modals/confirm-user-delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="header">
    {{t 'You are deleting this account. This action cannot be undone.'}}
    <div class="muted small text">
      {{t 'All user data will be deleted'}}
    </div>
  </div>
  <div class="content">
    <form class="ui {{if this.isLoading 'loading'}} form" id="confirm-delete-form" autocomplete="off" {{action this.deleteUser on='submit' preventDefault=true}}>
      <UiCheckbox @label={{t "Please tick the box to agree and press DELETE"}} @type="checkbox" @checked={{this.checked}} @onChange={{action (mut this.checked)}} />
    </form>
  </div>
  <div class="actions">
    <button type="button" class="ui black button" {{action 'close'}}>
      {{t 'Cancel'}}
    </button>
    <button type="submit" form="confirm-delete-form" class="ui red button {{if (not this.checked) 'disabled'}}">
      {{t 'Delete'}}
    </button>
  </div>
  
  */
  {
    "id": "/e5fu2Tu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"You are deleting this account. This action cannot be undone.\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"muted small text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"All user data will be deleted\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[11,\"form\"],[16,0,[31,[\"ui \",[30,[36,1],[[32,0,[\"isLoading\"]],\"loading\"],null],\" form\"]]],[24,1,\"confirm-delete-form\"],[24,\"autocomplete\",\"off\"],[4,[38,2],[[32,0],[32,0,[\"deleteUser\"]]],[[\"on\",\"preventDefault\"],[\"submit\",true]]],[12],[2,\"\\n    \"],[8,\"ui-checkbox\",[],[[\"@label\",\"@type\",\"@checked\",\"@onChange\"],[[30,[36,0],[\"Please tick the box to agree and press DELETE\"],null],\"checkbox\",[32,0,[\"checked\"]],[30,[36,2],[[32,0],[30,[36,3],[[32,0,[\"checked\"]]],null]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,\"form\",\"confirm-delete-form\"],[15,0,[31,[\"ui red button \",[30,[36,1],[[30,[36,4],[[32,0,[\"checked\"]]],null],\"disabled\"],null]]]],[14,4,\"submit\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Delete\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\",\"mut\",\"not\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/confirm-user-delete-modal.hbs"
    }
  });
  _exports.default = _default;
});