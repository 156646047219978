define("open-event-frontend/serializers/groups", ["exports", "open-event-frontend/serializers/application", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _application, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend(_customPrimaryKey.default, {
    primaryKey: 'attributes.identifier',
    attrs: {
      followers: 'user-follow-group',
      follower: 'user-follow-group',
      socialLinks: 'social-links'
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      var relationships = json.data.relationships;
      try {
        delete relationships.follower;
      } catch (_unused) {} // eslint-disable-line no-empty

      try {
        delete relationships.followers;
      } catch (_unused2) {} // eslint-disable-line no-empty

      try {
        delete relationships['social-links'];
      } catch (_unused3) {} // eslint-disable-line no-empty

      return json;
    }
  });
  _exports.default = _default;
});