define("open-event-frontend/templates/components/ui-table/cell/cell-speakers-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.record.speakers.total}}
    <div class="ui list">
      <div class="item">{{t 'Total'}}: {{this.record.speakers.total}}</div>
      <div class="item">{{t 'Accepted'}}: {{this.record.speakers.accepted}}</div>
      <div class="item">{{t 'Confirmed'}}: {{this.record.speakers.confirmed}}</div>
      <div class="item">{{t 'Pending'}}: {{this.record.speakers.pending}}</div>
      <div class="item">{{t 'Rejected'}}: {{this.record.speakers.rejected}}</div>
      <div class="item">{{t 'Withdrawn'}}: {{this.record.speakers.withdrawn}}</div>
      <div class="item">{{t 'Canceled'}}: {{this.record.speakers.canceled}}</div>
    </div>
  {{else}}
    {{t 'No Speaker Added Yet'}}
  {{/if}}
  
  */
  {
    "id": "qYC1kUfS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"record\",\"speakers\",\"total\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui list\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Total\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"speakers\",\"total\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Accepted\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"speakers\",\"accepted\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Confirmed\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"speakers\",\"confirmed\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Pending\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"speakers\",\"pending\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Rejected\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"speakers\",\"rejected\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Withdrawn\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"speakers\",\"withdrawn\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[30,[36,0],[\"Canceled\"],null]],[2,\": \"],[1,[32,0,[\"record\",\"speakers\",\"canceled\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"No Speaker Added Yet\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-speakers-dashboard.hbs"
    }
  });
  _exports.default = _default;
});