define("open-event-frontend/components/widgets/forms/language-form-check-box", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    selectedFields: [],
    value1: [],
    languageForms: Ember.A([]),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      onChange1: function onChange1(checked, value, valueList) {
        this.value1 = valueList;
      },
      valueChange: function valueChange() {},
      onChange: function onChange() {}
    }
  });
  _exports.default = _default;
});