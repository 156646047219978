define("open-event-frontend/templates/admin/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid">
    {{#if (or (eq this.router.currentRoute.name 'admin.video.channels.index') (eq this.router.currentRoute.name 'admin.video.recordings') )}}
      <div class="row">
        <div class="sixteen wide column">
          <TabbedNavigation @isNonPointing={{true}}>
            <LinkTo @route="admin.video.recordings" class="item">
              {{t 'Video Recordings'}}
            </LinkTo>
            <LinkTo @route="admin.video.channels.index" class="item">
              {{t 'Video Channels'}}
            </LinkTo>
          </TabbedNavigation>
        </div>
      </div>
    {{/if}}
    <div class="row">
      <div class="sixteen wide column">
        {{outlet}}
      </div>
    </div>
  </div>
  */
  {
    "id": "FbODn/1S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,1],[[32,0,[\"router\",\"currentRoute\",\"name\"]],\"admin.video.channels.index\"],null],[30,[36,1],[[32,0,[\"router\",\"currentRoute\",\"name\"]],\"admin.video.recordings\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n        \"],[8,\"tabbed-navigation\",[],[[\"@isNonPointing\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.video.recordings\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Video Recordings\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.video.channels.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Video Channels\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"or\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/video.hbs"
    }
  });
  _exports.default = _default;
});