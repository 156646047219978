define("open-event-frontend/templates/components/ui-table/cell/admin/groups/cell-group-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span>{{ @record }}</span>
  <div class="ui hidden divider"></div>
  <div class="ui horizontal compact basic buttons">
    <LinkTo @route="groups.edit.settings" @model={{@extraRecords.id}}>
      <UiPopup @content={{t "Edit"}}  @class="ui icon button">
        <i class="edit icon"></i>
      </UiPopup>
    </LinkTo>
    {{#if (not @extraRecords.deletedAt)}}
      <UiPopup @content={{t "Delete"}} @class="ui icon button" @click={{action @props.actions.deleteGroup @extraRecords.id}}>
        <i class="trash icon"></i>
      </UiPopup>
    {{/if}}
    {{#if @extraRecords.deletedAt}}
      <UiPopup @content={{t "Restore"}} @class="ui icon button" @click={{action @props.actions.restoreGroup @extraRecords.id}}>
        <i class="redo icon"></i>
      </UiPopup>
    {{/if}}
  </div>
  
  */
  {
    "id": "HpC2Uvmu",
    "block": "{\"symbols\":[\"@extraRecords\",\"@props\",\"@record\"],\"statements\":[[10,\"span\"],[12],[1,[32,3]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui hidden divider\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui horizontal compact basic buttons\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"groups.edit.settings\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\"],[[30,[36,0],[\"Edit\"],null],\"ui icon button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"edit icon\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"deletedAt\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@click\"],[[30,[36,0],[\"Delete\"],null],\"ui icon button\",[30,[36,1],[[32,0],[32,2,[\"actions\",\"deleteGroup\"]],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"trash icon\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,1,[\"deletedAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@click\"],[[30,[36,0],[\"Restore\"],null],\"ui icon button\",[30,[36,1],[[32,0],[32,2,[\"actions\",\"restoreGroup\"]],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"redo icon\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/groups/cell-group-name.hbs"
    }
  });
  _exports.default = _default;
});