define("open-event-frontend/utils/dictionary/boolean_text_type", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.booleanTextType = void 0;
  var booleanTextType = [{
    name: _text.tn.t('None'),
    position: 1
  }, {
    name: _text.tn.t('Uppercase'),
    position: 2
  }, {
    name: _text.tn.t('Lowercase'),
    position: 3
  }, {
    name: _text.tn.t('Capitalize'),
    position: 4
  }];
  _exports.booleanTextType = booleanTextType;
});