define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div>
      <UiCheckbox class=""
          @checked={{this.record}}
          @onChange={{action (mut this.record)}}/>
  </div>
  
  */
  {
    "id": "wb/5p15w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[8,\"ui-checkbox\",[[24,0,\"\"]],[[\"@checked\",\"@onChange\"],[[32,0,[\"record\"]],[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"record\"]]],null]],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-select.hbs"
    }
  });
  _exports.default = _default;
});