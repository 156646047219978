define("open-event-frontend/templates/components/ui-table/cell/cell-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <img class="ui tiny image" src="{{or @record '/images/placeholders/Other.jpg'}}" alt="Logo">
  
  */
  {
    "id": "qamogHpU",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[10,\"img\"],[14,0,\"ui tiny image\"],[15,\"src\",[31,[[30,[36,0],[[32,1],\"/images/placeholders/Other.jpg\"],null]]]],[14,\"alt\",\"Logo\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-image.hbs"
    }
  });
  _exports.default = _default;
});