define("open-event-frontend/helpers/text-color", ["exports", "open-event-frontend/utils/color"], function (_exports, _color) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // inputs: bg color, dark color (chosen if bg is light), light color (chosen if bg is dark)
  var _default = Ember.Helper.helper(function (params) {
    var color = params[0];
    if (!color) {
      return '#000';
    }
    return (0, _color.getTextColor)(color, params[1], params[2]);
  });
  _exports.default = _default;
});