define("open-event-frontend/templates/admin/settings/ticket-fees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui basic {{if this.isLoading 'loading' ''}} segment">
    <Forms::Admin::Settings::TicketFeesForm @save={{action "updateSettings"}} @model={{this.model}} />
  </div>
  */
  {
    "id": "ILUILNHG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui basic \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\",\"\"],null],\" segment\"]]],[12],[2,\"\\n  \"],[8,\"forms/admin/settings/ticket-fees-form\",[],[[\"@save\",\"@model\"],[[30,[36,1],[[32,0],\"updateSettings\"],null],[32,0,[\"model\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/settings/ticket-fees.hbs"
    }
  });
  _exports.default = _default;
});