define("open-event-frontend/helpers/is-url-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isUrlField = _exports.default = void 0;
  var isUrlField = function isUrlField(params) {
    return ['website', 'blog', 'twitter', 'facebook', 'github', 'instagram', 'linkedin', 'mastodon'].includes(params[0]);
  };
  _exports.isUrlField = isUrlField;
  var _default = Ember.Helper.helper(isUrlField);
  _exports.default = _default;
});