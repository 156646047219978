define("open-event-frontend/templates/components/public/featured-speaker-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <h2 class="mt-16">{{t 'Featured Speakers'}}</h2>
  <div class="speaker-list ui {{unless device.isMobile 'three column grid'}}">
    {{#each this.speakers as |speaker|}}
      <Public::SpeakerItem @speaker={{speaker}} @timezone={{@timezone}} @class="column" @isFeatured={{true}} />
    {{/each}}
    {{#if (gt (sub this.allSpeakers this.speakers.meta.count) 0)}}
      <div class="ui container">
        <div class="ui centered grid">
          <div class="row">
            <LinkTo @route="public.speakers" class="ui blue button">{{t 'More Speakers'}}</LinkTo>
          </div>
        </div>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "hIjgFI8V",
    "block": "{\"symbols\":[\"speaker\",\"@timezone\"],\"statements\":[[10,\"h2\"],[14,0,\"mt-16\"],[12],[1,[30,[36,0],[\"Featured Speakers\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"speaker-list ui \",[30,[36,2],[[35,1,[\"isMobile\"]],\"three column grid\"],null]]]],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"speakers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"public/speaker-item\",[],[[\"@speaker\",\"@timezone\",\"@class\",\"@isFeatured\"],[[32,1],[32,2],\"column\",true]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,7],[[30,[36,6],[[30,[36,5],[[32,0,[\"allSpeakers\"]],[32,0,[\"speakers\",\"meta\",\"count\"]]],null],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui centered grid\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"ui blue button\"]],[[\"@route\"],[\"public.speakers\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"More Speakers\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"device\",\"unless\",\"-track-array\",\"each\",\"sub\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/featured-speaker-list.hbs"
    }
  });
  _exports.default = _default;
});