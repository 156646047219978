define("open-event-frontend/templates/components/modals/paytm-otp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="header">
    {{t 'Amount to be paid:'}} <CurrencyAmount @currency={{this.currency}} @amount={{amount}}/>
  </div>
  
  <div class="content">
    {{t 'Enter OTP sent to mobile number'}}
    <form class="ui form" autocomplete="off">
      <div class="field">
        <Input @type="number" @id="otp" @value={{this.otp}} required={{true}} />
      </div>
    </form>
  </div>
  <div class="actions">
    <button type="button" class="ui black button" {{action 'close'}}>
      {{t 'Cancel'}}
    </button>
    <button {{action this.verifyOtp this.otp}} class="ui green button">
      {{t 'Verify'}}
    </button>
  </div>
  
  */
  {
    "id": "0jEWTILu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Amount to be paid:\"],null]],[2,\" \"],[8,\"currency-amount\",[],[[\"@currency\",\"@amount\"],[[32,0,[\"currency\"]],[34,1]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Enter OTP sent to mobile number\"],null]],[2,\"\\n  \"],[10,\"form\"],[14,0,\"ui form\"],[14,\"autocomplete\",\"off\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[8,\"input\",[[16,\"required\",true]],[[\"@type\",\"@id\",\"@value\"],[\"number\",\"otp\",[32,0,[\"otp\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui green button\"],[4,[38,2],[[32,0],[32,0,[\"verifyOtp\"]],[32,0,[\"otp\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Verify\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"amount\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/paytm-otp.hbs"
    }
  });
  _exports.default = _default;
});