define("open-event-frontend/templates/groups/edit/followers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <GroupNav @group={{this.model.group}} />
  <div class="sixteen wide column">
    <div class="mb-4 d-flex items-center space-between">
      <h2 class="header mb-0 pb-0">{{t 'Followers'}}</h2>
      <button class="ui blue button right-floated {{if this.isLoading 'loading'}}" {{action 'export' this.router.currentRoute.params.group_id}} >{{t 'Export as CSV'}}</button>
    </div>
    <div class="mx-4">
      <Tables::Default
        @columns={{this.followersColumn}}
        @rows={{this.model.followers.data}}
        @currentPage={{this.page}}
        @searchQuery={{this.search}}
        @pageSize={{this.per_page}}
        @metaData={{this.model.followers.meta}}
        @filterOptions={{this.filterOptions}}
        @widthConstraint="eq-container"
        @resizeMode="fluid"
        @fillMode="equal-column" />
    </div>
  </div>
  
  */
  {
    "id": "emReQltz",
    "block": "{\"symbols\":[],\"statements\":[[8,\"group-nav\",[],[[\"@group\"],[[32,0,[\"model\",\"group\"]]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-4 d-flex items-center space-between\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"header mb-0 pb-0\"],[12],[1,[30,[36,0],[\"Followers\"],null]],[13],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"ui blue button right-floated \",[30,[36,1],[[32,0,[\"isLoading\"]],\"loading\"],null]]]],[4,[38,2],[[32,0],\"export\",[32,0,[\"router\",\"currentRoute\",\"params\",\"group_id\"]]],null],[12],[1,[30,[36,0],[\"Export as CSV\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mx-4\"],[12],[2,\"\\n    \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@searchQuery\",\"@pageSize\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"followersColumn\"]],[32,0,[\"model\",\"followers\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"search\"]],[32,0,[\"per_page\"]],[32,0,[\"model\",\"followers\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/groups/edit/followers.hbs"
    }
  });
  _exports.default = _default;
});