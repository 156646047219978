define("open-event-frontend/templates/components/n-times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#each (range 0 this.times) as |number|}}
    {{yield number}}
  {{/each}}
  
  */
  {
    "id": "CyvYe5t+",
    "block": "{\"symbols\":[\"number\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[30,[36,0],[0,[32,0,[\"times\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"range\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/n-times.hbs"
    }
  });
  _exports.default = _default;
});