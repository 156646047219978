define("open-event-frontend/components/forms/speaker-section", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/utils/dictionary/demography", "open-event-frontend/utils/dictionary/genders", "lodash-es"], function (_exports, _form, _demography, _genders, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    countries: (0, _lodashEs.orderBy)(_demography.countries, 'name'),
    genders: (0, _lodashEs.orderBy)(_genders.genders, 'position')
  });
  _exports.default = _default;
});