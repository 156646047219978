define("open-event-frontend/components/forms/admin/settings/payment-gateway-form", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/config/environment"], function (_exports, _form, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          stripeClientId: {
            identifier: 'stripe_client_id',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the client ID')
            }]
          },
          stripeSecretKey: {
            identifier: 'stripe_secret_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the secret key')
            }]
          },
          stripePublishableKey: {
            identifier: 'stripe_publishable_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the publishable key')
            }]
          },
          stripeTestSecretKey: {
            identifier: 'stripe_test_secret_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the secret test key')
            }]
          },
          stripeTestPublishableKey: {
            identifier: 'stripe_test_publishable_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the publishable test key')
            }]
          },
          alipaySecretKey: {
            identifier: 'alipay_secret_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the secret key')
            }]
          },
          alipayPublishableKey: {
            identifier: 'alipay_publishable_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the publishable key')
            }]
          },
          paypalSandboxClient: {
            identifier: 'sandbox_client_id',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the sandbox client id')
            }]
          },
          paypalSandboxSecret: {
            identifier: 'sandbox_secret_token',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the sandbox secret token')
            }]
          },
          paypalClient: {
            identifier: 'client_id',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the live client token')
            }]
          },
          paypalSecret: {
            identifier: 'secret_token',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the live secret token')
            }]
          },
          omiseTestPublic: {
            identifier: 'test_public_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the public test key')
            }]
          },
          omiseTestSecret: {
            identifier: 'test_secret_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the secret test key')
            }]
          },
          omiseLivePublic: {
            identifier: 'live_public_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the public live key')
            }]
          },
          omiseLiveSecret: {
            identifier: 'live_secret_key',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the secret live key')
            }]
          },
          paytmLiveMerchant: {
            identifier: 'paytm_live_merchant',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the live merchant ID')
            }]
          },
          paytmLiveSecret: {
            identifier: 'paytm_live_secret',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the secret live key')
            }]
          },
          paytmSandboxMerchant: {
            identifier: 'paytm_sandbox_merchant',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the test merchant ID')
            }]
          },
          paytmSandboxSecret: {
            identifier: 'paytm_sandbox_secret',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter the secret test key')
            }]
          }
        }
      };
    },
    isCheckedStripe: Ember.computed(function () {
      return this.settings.stripeClientId || this.settings.stripeTestClientId;
    }),
    stripeMode: Ember.computed(function () {
      return _environment.default.environment === 'development' || _environment.default.environment === 'test' ? 'debug' : 'production';
    }),
    isCheckedPaypal: Ember.computed(function () {
      return this.settings.paypalSandboxClient || this.settings.paypalClient;
    }),
    isCheckedOmise: Ember.computed(function () {
      return this.settings.omiseTestPublic || this.settings.omiseLivePublic;
    }),
    isCheckedAliPay: Ember.computed('settings.alipaySecretKey', 'settings.alipayPublishableKey', function () {
      return this.settings.alipaySecretKey && this.settings.alipayPublishableKey;
    }),
    actions: {
      submit: function submit() {
        var _this = this;
        this.onValid(function () {
          if (_this.isCheckedStripe === false) {
            _this.settings.setProperties({
              'stripeClientId': null,
              'stripeSecretKey': null,
              'stripePublishableKey': null
            });
          }
          if (_this.isCheckedPaypal === false) {
            _this.settings.setProperties({
              'paypalSandboxClient': null,
              'paypalSandboxSecret': null,
              'paypalSecret': null,
              'paypalClient': null
            });
          }
          if (!_this.isCheckedAliPay) {
            _this.settings.setProperties({
              'aliPaySecretKey': null,
              'aliPayPublishableKey': null
            });
          }
          if (_this.isCheckedOmise === false) {
            _this.settings.setProperties({
              'omiseTestPublic': null,
              'omiseTestSecret': null,
              'omiseLivePublic': null,
              'omiseLiveSecret': null
            });
          }
          _this.sendAction('save');
        });
      }
    }
  });
  _exports.default = _default;
});