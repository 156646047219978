define("open-event-frontend/templates/components/ui-table/cell/cell-validity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span>
    {{general-date this.record 'date-time-short'}}
    <br>To<br>
    {{general-date this.extraRecords.validTill 'date-time-short'}}
  </span>
  
  */
  {
    "id": "KoTMxmWi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"record\"]],\"date-time-short\"],null]],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"To\"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"extraRecords\",\"validTill\"]],\"date-time-short\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"general-date\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-validity.hbs"
    }
  });
  _exports.default = _default;
});