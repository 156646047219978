define("open-event-frontend/templates/admin/sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid stackable">
    <div class="row">
      <div class="sixteen wide column">
        <TabbedNavigation @isNonPointing={{true}}>
          <LinkTo @route="admin.sessions.list" @model="all" class="item">
            {{t 'All'}}
          </LinkTo>
          {{#each this.model.sessionStates as |state|}}
            <LinkTo @route="admin.sessions.list" @model={{state}} class="item">
              {{t-var (capitalize state)}}
            </LinkTo>
          {{/each}}
          <LinkTo @route="admin.sessions.list" @model="deleted" class="item">
            {{t 'Deleted'}}
          </LinkTo>
        </TabbedNavigation>
      </div>
    </div>
    <div class="row">
      {{outlet}}
    </div>
  </div>
  
  */
  {
    "id": "wCU4jr2q",
    "block": "{\"symbols\":[\"state\"],\"statements\":[[10,\"div\"],[14,0,\"ui grid stackable\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[8,\"tabbed-navigation\",[],[[\"@isNonPointing\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.sessions.list\",\"all\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,2],[\"All\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"model\",\"sessionStates\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.sessions.list\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,0],[[32,1]],null]],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.sessions.list\",\"deleted\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,2],[\"Deleted\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"capitalize\",\"t-var\",\"t\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/sessions.hbs"
    }
  });
  _exports.default = _default;
});