define("open-event-frontend/components/modals/confirm-modal", ["exports", "open-event-frontend/components/modals/modal-base"], function (_exports, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modalBase.default.extend({
    isSmall: true,
    options: {
      closable: false
    },
    actions: {
      savePublicName: function savePublicName() {
        var _this$confirm$pending;
        this.authManager.currentUser.save();
        (_this$confirm$pending = this.confirm.pendingPromise) === null || _this$confirm$pending === void 0 ? void 0 : _this$confirm$pending.resolve();
        this.set('isOpen', false);
      },
      deny: function deny() {
        if (this.confirm.pendingPromise.reject) {
          this.confirm.pendingPromise.reject();
        }
        this.set('isOpen', false);
      },
      confirm: function confirm() {
        if (this.confirm.pendingPromise.resolve) {
          this.confirm.pendingPromise.resolve();
        }
        this.set('isOpen', false);
      }
    }
  });
  _exports.default = _default;
});