define("open-event-frontend/templates/admin/users/view/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui container">
    <h1 class="ui header">{{t 'Account'}}</h1>
    <div class="row">
      <div class="sixteen wide column">
        <TabbedNavigation>
          <LinkTo @route="admin.users.view.account.profile" @model={{this.model.id}} class="item">
            {{t 'Profile Info'}}
          </LinkTo>
          <LinkTo @route="admin.users.view.account.email-preferences" @model={{this.model.id}} class="item">
            {{t 'Email-Preferences'}}
          </LinkTo>
          <LinkTo @route="admin.users.view.account.applications" @model={{this.model.id}} class="item">
            {{t 'Applications'}}
          </LinkTo>
        </TabbedNavigation>
      </div>
    </div>
    <div class="ui segment">
      {{outlet}}
    </div>
  </div>
  
  */
  {
    "id": "N9W3Gvy6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,[30,[36,0],[\"Account\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.account.profile\",[32,0,[\"model\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Profile Info\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.account.email-preferences\",[32,0,[\"model\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Email-Preferences\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.account.applications\",[32,0,[\"model\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Applications\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui segment\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/users/view/account.hbs"
    }
  });
  _exports.default = _default;
});