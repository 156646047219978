define("open-event-frontend/utils/dictionary/wiki-scholarship", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.wikiScholarship = void 0;
  var wikiScholarship = [{
    name: _text.tn.t('No, I haven\'t'),
    position: 1
  }, {
    name: _text.tn.t('Yes, from the Wikimedia Foundation'),
    position: 2
  }, {
    name: _text.tn.t('Yes, from a Wikimedia affiliate'),
    position: 3
  }];
  _exports.wikiScholarship = wikiScholarship;
});