define("open-event-frontend/helpers/currency-symbol", ["exports", "lodash-es", "open-event-frontend/utils/dictionary/payment"], function (_exports, _lodashEs, _payment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencySymbol = currencySymbol;
  _exports.default = void 0;
  function currencySymbol(params) {
    var currency = (0, _lodashEs.find)(_payment.paymentCurrencies, ['code', params[0]]);
    return currency ? currency.symbol : params[0];
  }
  var _default = Ember.Helper.helper(currencySymbol);
  _exports.default = _default;
});