define("open-event-frontend/templates/components/forms/orders/cancel-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui segments">
      <div class="ui secondary segment aligned">
      <h3 class="weight-400">{{t 'Ticket cancellation'}}</h3>
      </div>
      <div class="ui padded segment">
      {{t 'You can cancel this order with the button below. This will void all tickets in this order.'}}
      <br>
      <button class="ui red {{if (eq @order.status 'cancelled') 'disabled'}} button mt-4" {{action (confirm (t "This will void all tickets in this order. Are you sure you want to cancel this order?") (action @cancelOrder @order.id))}}>{{t 'Cancel Order'}}</button>
      </div>
  </div>
  */
  {
    "id": "ZXiIXgNm",
    "block": "{\"symbols\":[\"@order\",\"@cancelOrder\"],\"statements\":[[10,\"div\"],[14,0,\"ui segments\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui secondary segment aligned\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"weight-400\"],[12],[1,[30,[36,0],[\"Ticket cancellation\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui padded segment\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"You can cancel this order with the button below. This will void all tickets in this order.\"],null]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"ui red \",[30,[36,2],[[30,[36,1],[[32,1,[\"status\"]],\"cancelled\"],null],\"disabled\"],null],\" button mt-4\"]]],[4,[38,3],[[32,0],[30,[36,4],[[30,[36,0],[\"This will void all tickets in this order. Are you sure you want to cancel this order?\"],null],[30,[36,3],[[32,0],[32,2],[32,1,[\"id\"]]],null]],null]],null],[12],[1,[30,[36,0],[\"Cancel Order\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"action\",\"confirm\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/orders/cancel-order.hbs"
    }
  });
  _exports.default = _default;
});