define("open-event-frontend/templates/events/view/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui container">
    <h2 class="weight-300">{{t 'Editing'}} {{this.model.event.name}}</h2>
    <div class="ui one column centered stacked grid event wizard">
      <div class="column mobile hidden">
        <Widgets::StepsIndicator @steps={{this.model.steps}} @autoSteps={{true}} @enableAll={{true}} />
      </div>
      <div class="column">
        {{outlet}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "Vyx3ILiG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"weight-300\"],[12],[1,[30,[36,0],[\"Editing\"],null]],[2,\" \"],[1,[32,0,[\"model\",\"event\",\"name\"]]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui one column centered stacked grid event wizard\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column mobile hidden\"],[12],[2,\"\\n      \"],[8,\"widgets/steps-indicator\",[],[[\"@steps\",\"@autoSteps\",\"@enableAll\"],[[32,0,[\"model\",\"steps\"]],true,true]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/edit.hbs"
    }
  });
  _exports.default = _default;
});