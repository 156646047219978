define("open-event-frontend/models/sponsor", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    name: (0, _attr.default)('string'),
    level: (0, _attr.default)('number'),
    type: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    logoUrl: (0, _attr.default)('string'),
    event: (0, _relationships.belongsTo)('event')
  });
  _exports.default = _default;
});