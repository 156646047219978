define("open-event-frontend/models/activity", ["exports", "ember-data/attr", "moment-timezone", "open-event-frontend/models/base"], function (_exports, _attr, _momentTimezone, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var detectedTimezone = _momentTimezone.default.tz.guess();
  var _default = _base.default.extend({
    actor: (0, _attr.default)('string'),
    time: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return _momentTimezone.default.tz(detectedTimezone);
      }
    }),
    action: (0, _attr.default)('string')
  });
  _exports.default = _default;
});