define("open-event-frontend/templates/components/ui-table/cell/cell-sponsor-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <img style="max-width:80px; max-height: 40px;" class="ui image" src="{{if this.record this.record '/images/placeholders/Other.jpg'}}" alt="Event logo">
  
  */
  {
    "id": "w0syoOeC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,5,\"max-width:80px; max-height: 40px;\"],[14,0,\"ui image\"],[15,\"src\",[31,[[30,[36,0],[[32,0,[\"record\"]],[32,0,[\"record\"]],\"/images/placeholders/Other.jpg\"],null]]]],[14,\"alt\",\"Event logo\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-sponsor-image.hbs"
    }
  });
  _exports.default = _default;
});