define("open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <UiDropdown @class="ui floating dropdown button w-content-button">
    <div class="ui {{this.color}} empty circular label"></div>
    {{t-var (capitalize this.extraRecords.status)}}
    <div class="menu">
      {{#each this.states as |state|}}
        <div class="item" role="button" {{action this.props.actions.changeState this.extraRecords.id state.name}}>
          <div class="ui {{state.color}} empty circular label"></div>
          {{t-var (capitalize state.name)}}
        </div>
      {{/each}}
    </div>
  </UiDropdown>
  
  */
  {
    "id": "mNtGrKfK",
    "block": "{\"symbols\":[\"state\"],\"statements\":[[8,\"ui-dropdown\",[],[[\"@class\"],[\"ui floating dropdown button w-content-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"ui \",[32,0,[\"color\"]],\" empty circular label\"]]],[12],[13],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[[32,0,[\"extraRecords\",\"status\"]]],null]],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"states\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"item\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],[32,0,[\"props\",\"actions\",\"changeState\"]],[32,0,[\"extraRecords\",\"id\"]],[32,1,[\"name\"]]],null],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"ui \",[32,1,[\"color\"]],\" empty circular label\"]]],[12],[13],[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"name\"]]],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"capitalize\",\"t-var\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-buttons.hbs"
    }
  });
  _exports.default = _default;
});