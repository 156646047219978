define("open-event-frontend/utils/dictionary/native-language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nativeLanguage = void 0;
  var nativeLanguage = [{
    name: 'عربي',
    code: 'ar',
    isChecked: false
  }, {
    name: 'Bahasa Indonesia',
    code: 'id',
    isChecked: false
  }, {
    name: 'English',
    code: 'en-US',
    isChecked: false
  }, {
    name: 'Español',
    code: 'es-ES',
    isChecked: false
  }, {
    name: 'Français',
    code: 'fr-FR',
    isChecked: false
  }, {
    name: '中文',
    code: 'zh-CN',
    isChecked: false
  }, {
    name: 'Other',
    code: 'other',
    isChecked: false
  }];
  _exports.nativeLanguage = nativeLanguage;
});