define("open-event-frontend/templates/components/ui-table/cell/admin/users/cell-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if (eq this.record 'active')}}
    <div class="ui green label">{{t 'Active'}}</div>
  {{else if (eq this.record 'inactive')}}
    <div class="ui yellow label">{{t 'Inactive'}}</div>
  {{else}}
    <div class="ui red label">{{t 'Deleted'}}</div>
  {{/if}}
  
  */
  {
    "id": "K0DRGLRS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"record\"]],\"active\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui green label\"],[12],[1,[30,[36,0],[\"Active\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"record\"]],\"inactive\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui yellow label\"],[12],[1,[30,[36,0],[\"Inactive\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui red label\"],[12],[1,[30,[36,0],[\"Deleted\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/users/cell-status.hbs"
    }
  });
  _exports.default = _default;
});