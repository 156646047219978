define("open-event-frontend/templates/admin/reports/kubernetes-server-logs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="muted text">
    {{t 'Coming Soon'}}
  </div>
  {{outlet}}
  
  */
  {
    "id": "d0txxZ3T",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"muted text\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Coming Soon\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/reports/kubernetes-server-logs.hbs"
    }
  });
  _exports.default = _default;
});