define("open-event-frontend/templates/components/ui-table/cell/events/view/speakers/speaker-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.record}}
    {{this.record}}
  {{else}}
    {{t 'Not Provided'}}
  {{/if}}
  */
  {
    "id": "vnlcTmVV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"record\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[32,0,[\"record\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"Not Provided\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/speakers/speaker-mobile.hbs"
    }
  });
  _exports.default = _default;
});