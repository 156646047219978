define("open-event-frontend/templates/components/tables/utilities/add-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <UiDropdown @class="ui small selection search dropdown mr-1" @onChange={{action 'onSelectTag'}}>
      <div class="default text">
          {{t 'Select Tag'}}
      </div>
      <i class="dropdown icon"></i>
      <div class="menu">
          {{#each this.tags as |tag|}}
          <div class="item" data-value={{tag.id}} >
              {{tag.name}}
          </div>
          {{/each}}
      </div>
  </UiDropdown>
  
  */
  {
    "id": "wkO+vijN",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[8,\"ui-dropdown\",[],[[\"@class\",\"@onChange\"],[\"ui small selection search dropdown mr-1\",[30,[36,0],[[32,0],\"onSelectTag\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"default text\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"Select Tag\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"item\"],[15,\"data-value\",[32,1,[\"id\"]]],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/utilities/add-tag.hbs"
    }
  });
  _exports.default = _default;
});