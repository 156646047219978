define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if (eq @extraRecords.order.status 'completed')}}
    {{general-date @extraRecords.order.completedAt 'date-time-short'}}
  {{else}}
    {{general-date @extraRecords.order.createdAt 'date-time-short'}}
  {{/if}}
  
  */
  {
    "id": "qgMIwoh+",
    "block": "{\"symbols\":[\"@extraRecords\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"order\",\"status\"]],\"completed\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1,[\"order\",\"completedAt\"]],\"date-time-short\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1,[\"order\",\"createdAt\"]],\"date-time-short\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"general-date\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-date.hbs"
    }
  });
  _exports.default = _default;
});