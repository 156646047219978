define("open-event-frontend/models/setting", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    /**
     * Attributes
     */

    appEnvironment: (0, _attr.default)('string'),
    appName: (0, _attr.default)('string'),
    orderExpiryTime: (0, _attr.default)('number', {
      defaultValue: 15
    }),
    tagline: (0, _attr.default)('string'),
    storagePlace: (0, _attr.default)('string'),
    awsKey: (0, _attr.default)('string'),
    awsSecret: (0, _attr.default)('string'),
    awsBucketName: (0, _attr.default)('string'),
    awsRegion: (0, _attr.default)('string'),
    gsKey: (0, _attr.default)('string'),
    gsSecret: (0, _attr.default)('string'),
    gsBucketName: (0, _attr.default)('string'),
    googleClientId: (0, _attr.default)('string'),
    googleClientSecret: (0, _attr.default)('string'),
    fbClientId: (0, _attr.default)('string'),
    fbClientSecret: (0, _attr.default)('string'),
    twConsumerKey: (0, _attr.default)('string'),
    twConsumerSecret: (0, _attr.default)('string'),
    inClientId: (0, _attr.default)('string'),
    startPgEventId: (0, _attr.default)('string'),
    inClientSecret: (0, _attr.default)('string'),
    paypalMode: (0, _attr.default)('string'),
    paypalClient: (0, _attr.default)('string'),
    paypalSecret: (0, _attr.default)('string'),
    paypalSandboxClient: (0, _attr.default)('string'),
    paypalSandboxSecret: (0, _attr.default)('string'),
    alipaySecretKey: (0, _attr.default)('string'),
    alipayPublishableKey: (0, _attr.default)('string'),
    omiseMode: (0, _attr.default)('string'),
    omiseTestPublic: (0, _attr.default)('string'),
    omiseTestSecret: (0, _attr.default)('string'),
    omiseLivePublic: (0, _attr.default)('string'),
    omiseLiveSecret: (0, _attr.default)('string'),
    paytmMode: (0, _attr.default)('string'),
    paytmLiveMerchant: (0, _attr.default)('string'),
    paytmLiveSecret: (0, _attr.default)('string'),
    paytmSandboxMerchant: (0, _attr.default)('string'),
    paytmSandboxSecret: (0, _attr.default)('string'),
    stripeClientId: (0, _attr.default)('string'),
    stripeSecretKey: (0, _attr.default)('string'),
    stripePublishableKey: (0, _attr.default)('string'),
    stripeTestClientId: (0, _attr.default)('string'),
    stripeTestSecretKey: (0, _attr.default)('string'),
    stripeTestPublishableKey: (0, _attr.default)('string'),
    stripeMode: (0, _attr.default)('string'),
    isAlipayActivated: (0, _attr.default)('boolean'),
    isPaypalActivated: (0, _attr.default)('boolean'),
    isStripeActivated: (0, _attr.default)('boolean'),
    isOmiseActivated: (0, _attr.default)('boolean'),
    isPaytmActivated: (0, _attr.default)('boolean'),
    isBillingPaypalActivated: (0, _attr.default)('boolean'),
    emailService: (0, _attr.default)('string'),
    emailFrom: (0, _attr.default)('string'),
    emailFromName: (0, _attr.default)('string'),
    sendgridKey: (0, _attr.default)('string'),
    smtpHost: (0, _attr.default)('string'),
    smtpUsername: (0, _attr.default)('string'),
    smtpPassword: (0, _attr.default)('string'),
    smtpPort: (0, _attr.default)('string'),
    smtpEncryption: (0, _attr.default)('string'),
    analyticsKey: (0, _attr.default)('string'),
    googleUrl: (0, _attr.default)('string'),
    githubUrl: (0, _attr.default)('string'),
    twitterUrl: (0, _attr.default)('string'),
    supportUrl: (0, _attr.default)('string'),
    facebookUrl: (0, _attr.default)('string'),
    instagramUrl: (0, _attr.default)('string'),
    weblateUrl: (0, _attr.default)('string'),
    patreonUrl: (0, _attr.default)('string'),
    gitterUrl: (0, _attr.default)('string'),
    telegramUrl: (0, _attr.default)('string'),
    youtubeUrl: (0, _attr.default)('string'),
    androidAppUrl: (0, _attr.default)('string'),
    frontendUrl: (0, _attr.default)('string'),
    webAppUrl: (0, _attr.default)('string'),
    rocketChatUrl: (0, _attr.default)('string'),
    rocketChatRegistrationSecret: (0, _attr.default)('string'),
    staticDomain: (0, _attr.default)('string'),
    cookiePolicy: (0, _attr.default)('string'),
    cookiePolicyLink: (0, _attr.default)('string'),
    invoiceSendingDay: (0, _attr.default)('number'),
    invoiceSendingTimezone: (0, _attr.default)('string'),
    adminBillingContactName: (0, _attr.default)('string'),
    adminBillingPhone: (0, _attr.default)('string'),
    adminBillingEmail: (0, _attr.default)('string'),
    adminBillingState: (0, _attr.default)('string'),
    adminBillingCountry: (0, _attr.default)('string'),
    adminBillingTaxInfo: (0, _attr.default)('string'),
    adminCompany: (0, _attr.default)('string'),
    adminBillingAddress: (0, _attr.default)('string'),
    adminBillingCity: (0, _attr.default)('string'),
    adminBillingZip: (0, _attr.default)('string'),
    adminBillingAdditionalInfo: (0, _attr.default)('string'),
    adminBillingPaypalEmail: (0, _attr.default)('string'),
    adminBillingLogo: (0, _attr.default)('string'),
    isGoogleRecaptchaEnabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    startPgEnabled: (0, _attr.default)('string'),
    googleRecaptchaSite: (0, _attr.default)('string'),
    googleRecaptchaSecret: (0, _attr.default)('string'),
    logoSize: (0, _attr.default)('number', {
      defaultValue: 1000
    }),
    imageSize: (0, _attr.default)('number', {
      defaultValue: 10000
    }),
    slideSize: (0, _attr.default)('number', {
      defaultValue: 20000
    })
  });
  _exports.default = _default;
});