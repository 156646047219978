define("open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if (includes this.props.options.ratedSessions this.extraRecords.id)}}
    {{#each this.extraRecords.feedbacks as |feedback|}}
      {{#if (eq feedback.user.email this.authManager.currentUser.email)}}
        <UiRating
          @initialRating={{feedback.rating}}
          @rating={{feedback.rating}}
          @maxRating={{5}}
          @onRate={{pipe-action (action (mut feedback.rating)) (action this.props.actions.updateRating feedback.rating feedback)}}
          @clearable={{true}} 
          @class="mt-8" />
      {{/if}}
    {{/each}}
  {{else}}
    <UiRating
      @initialRating={{0}}
      @rating={{this.rating}}
      @maxRating={{5}}
      @onRate={{pipe-action (action (mut this.rating)) (action this.props.actions.addRating this.rating this.extraRecords.id)}}
      @clearable={{true}}
      @class="mt-8" />
  {{/if}}
  <div class="item text center aligned">
    {{this.record}} ({{this.extraRecords.feedbacks.length}})
  </div>
  
  */
  {
    "id": "a1AMY4Cv",
    "block": "{\"symbols\":[\"feedback\"],\"statements\":[[6,[37,4],[[30,[36,7],[[32,0,[\"props\",\"options\",\"ratedSessions\"]],[32,0,[\"extraRecords\",\"id\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"extraRecords\",\"feedbacks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,1,[\"user\",\"email\"]],[32,0,[\"authManager\",\"currentUser\",\"email\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui-rating\",[],[[\"@initialRating\",\"@rating\",\"@maxRating\",\"@onRate\",\"@clearable\",\"@class\"],[[32,1,[\"rating\"]],[32,1,[\"rating\"]],5,[30,[36,2],[[30,[36,0],[[32,0],[30,[36,1],[[32,1,[\"rating\"]]],null]],null],[30,[36,0],[[32,0],[32,0,[\"props\",\"actions\",\"updateRating\"]],[32,1,[\"rating\"]],[32,1]],null]],null],true,\"mt-8\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"ui-rating\",[],[[\"@initialRating\",\"@rating\",\"@maxRating\",\"@onRate\",\"@clearable\",\"@class\"],[0,[32,0,[\"rating\"]],5,[30,[36,2],[[30,[36,0],[[32,0],[30,[36,1],[[32,0,[\"rating\"]]],null]],null],[30,[36,0],[[32,0],[32,0,[\"props\",\"actions\",\"addRating\"]],[32,0,[\"rating\"]],[32,0,[\"extraRecords\",\"id\"]]],null]],null],true,\"mt-8\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"item text center aligned\"],[12],[2,\"\\n  \"],[1,[32,0,[\"record\"]]],[2,\" (\"],[1,[32,0,[\"extraRecords\",\"feedbacks\",\"length\"]]],[2,\")\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"mut\",\"pipe-action\",\"eq\",\"if\",\"-track-array\",\"each\",\"includes\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-rating.hbs"
    }
  });
  _exports.default = _default;
});