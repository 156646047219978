define("open-event-frontend/components/account/contact-info-section", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/utils/validators"], function (_exports, _form, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    email: '',
    phone: '',
    isLoading: false,
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          email: {
            identifier: 'email',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your email ID')
            }, {
              type: 'email',
              prompt: this.l10n.t('Please enter a valid email address')
            }]
          },
          phone: {
            identifier: 'phone',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a phone number.')
            }, {
              type: 'regExp',
              value: _validators.validPhoneNumber,
              prompt: this.l10n.t('Please enter a valid phone number.')
            }]
          }
        }
      };
    },
    actions: {
      submit: function submit() {
        var _this = this;
        this.onValid(function () {
          _this.sendAction('submit');
        });
      }
    }
  });
  _exports.default = _default;
});