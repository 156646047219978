define("open-event-frontend/templates/components/event-invoice/event-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui segments">
    <div class="ui orange inverted segment center aligned">
      <div class="ui inverted mini statistic horizontal">
        <div class="value">
          {{t 'Event Information'}}
        </div>
      </div>
    </div>
    <div class="ui secondary segment">
      <h3 class="weight-400">{{t 'When & Where'}}</h3>
    </div>
    <div class="ui padded segment">
      <strong>{{t 'At'}} {{this.event.locationName}}</strong>
      <br>
      <strong>{{t 'From'}}:</strong> {{general-date this.event.startsAt 'date-time-long'}}
      <br>
      <strong>{{t 'To'}}:</strong> {{general-date this.event.endsAt 'date-time-long'}}
      {{#if this.event.ownerName}}
        <br>
        <strong>{{t 'Organized By'}}:</strong> {{this.event.ownerName}}
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "zqXABHp7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui segments\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui orange inverted segment center aligned\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui inverted mini statistic horizontal\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"value\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Event Information\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui secondary segment\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"weight-400\"],[12],[1,[30,[36,0],[\"When & Where\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui padded segment\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"At\"],null]],[2,\" \"],[1,[32,0,[\"event\",\"locationName\"]]],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"From\"],null]],[2,\":\"],[13],[2,\" \"],[1,[30,[36,1],[[32,0,[\"event\",\"startsAt\"]],\"date-time-long\"],null]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"To\"],null]],[2,\":\"],[13],[2,\" \"],[1,[30,[36,1],[[32,0,[\"event\",\"endsAt\"]],\"date-time-long\"],null]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"event\",\"ownerName\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"br\"],[12],[13],[2,\"\\n      \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"Organized By\"],null]],[2,\":\"],[13],[2,\" \"],[1,[32,0,[\"event\",\"ownerName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"general-date\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/event-invoice/event-info.hbs"
    }
  });
  _exports.default = _default;
});