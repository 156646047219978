define("open-event-frontend/utils/dictionary/tzAbbr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tzAbbr = void 0;
  var tzAbbr = {
    'America/Asuncion': 'PYST',
    'America/Bogota': 'COT',
    'America/Caracas': 'VET',
    'America/Cayenne': 'GFT',
    'America/Godthab': 'WGT',
    'America/Guayaquil': 'ECT',
    'America/Guyana': 'GYT',
    'America/La_Paz': 'BOT',
    'America/Lima': 'PET',
    'America/Miquelon': 'PMST',
    'America/Montevideo': 'UYT',
    'America/Paramaribo': 'SRT',
    'America/Scoresbysund': 'EGT',
    'Antarctica/Casey': 'CAST',
    'Antarctica/Davis': 'DAVT',
    'Antarctica/DumontDUrville': 'DDUT',
    'Antarctica/Macquarie': 'AEDT',
    'Antarctica/Mawson': 'MAWT',
    'Antarctica/Rothera': 'ROTT',
    'Antarctica/Syowa': 'SYOT',
    'Antarctica/Troll': 'GMT',
    'Antarctica/Vostok': 'VOST',
    'Asia/Almaty': 'ALMT',
    'Asia/Anadyr': 'ANAT',
    'Asia/Ashgabat': 'TMT',
    'Asia/Ashkhabad': 'TMT',
    'Asia/Baku': 'AZT',
    'Asia/Bishkek': 'KGT',
    'Asia/Brunei': 'WITA',
    'Asia/Choibalsan': 'CHOT',
    'Asia/Colombo': 'IST',
    'Asia/Dili': 'TLT',
    'Asia/Dushanbe': 'TJT',
    'Asia/Hovd': 'HOVT',
    'Asia/Irkutsk': 'IRKT',
    'Asia/Kabul': 'AFT',
    'Asia/Kamchatka': 'PETT',
    'Asia/Kathmandu': 'NPT',
    'Asia/Katmandu': 'NPT',
    'Asia/Magadan': 'MAGT',
    'Asia/Manila': 'PHST',
    'Asia/Novosibirsk': 'NOVT',
    'Asia/Omsk': 'OMST',
    'Asia/Oral': 'ORAT',
    'Asia/Qyzylorda': 'MSK+2',
    'Asia/Sakhalin': 'SAKT',
    'Asia/Singapore': 'SGT',
    'Asia/Srednekolymsk': 'SRET',
    'Asia/Tbilisi': 'GET',
    'Asia/Thimbu': 'BTT',
    'Asia/Thimphu': 'BTT',
    'Asia/Ulaanbaatar': 'ULAT',
    'Asia/Ulan_Bator': 'ULAT',
    'Asia/Yekaterinburg': 'YEKT',
    'Atlantic/Azores': 'AZOT',
    'Atlantic/Cape_Verde': 'CVT',
    'Atlantic/Stanley': 'FKST',
    'Australia/Eucla': 'ACWST',
    'Australia/LHI': 'LHDT',
    'Australia/Lord_Howe': 'LHDT',
    'Europe/Samara': 'SAMT',
    'Europe/Volgograd': 'VOLT',
    'Indian/Chagos': 'IOT',
    'Indian/Christmas': 'CXT',
    'Indian/Cocos': 'CCT',
    'Indian/Kerguelen': 'TFT',
    'Indian/Mahe': 'SCT',
    'Indian/Maldives': 'MVT',
    'Indian/Mauritius': 'MUT',
    'Indian/Reunion': 'RET',
    'NZ-CHAT ': 'NZDT',
    'Pacific/Apia': 'WST',
    'Pacific/Chatham': 'CHADT',
    'Pacific/Efate': 'VUT',
    'Pacific/Enderbury': 'PHOT',
    'Pacific/Fakaofo': 'TKT',
    'Pacific/Fiji': 'FJT',
    'Pacific/Funafuti': 'TVT',
    'Pacific/Galapagos': 'GALT',
    'Pacific/Gambier': 'GAMT',
    'Pacific/Guadalcanal': 'SBT',
    'Pacific/Kiritimati': 'LINT',
    'Pacific/Kosrae': 'KOST',
    'Pacific/Majuro': 'MHT',
    'Pacific/Nauru': 'NRT',
    'Pacific/Niue': 'NUT',
    'Pacific/Norfolk': 'NFT',
    'Pacific/Noumea': 'NCT',
    'Pacific/Palau': 'PWT',
    'Pacific/Pitcairn': 'PST',
    'Pacific/Pohnpei': 'PONT',
    'Pacific/Ponape': 'PONT',
    'Pacific/Port_Moresby': 'PGT',
    'Pacific/Rarotonga': 'CKT',
    'Pacific/Tahiti': 'TAHT',
    'Pacific/Tarawa': 'GILT',
    'Pacific/Tongatapu': 'TOT',
    'Pacific/Wake': 'WAKT',
    'Pacific/Wallis': 'WFT',
    'Singapore': 'SGT'
  };
  _exports.tzAbbr = tzAbbr;
});