define("open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/notification-logs/cell-sanitize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{sanitize this.record}}
  
  */
  {
    "id": "6kxEAzGr",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"record\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sanitize\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/notification-logs/cell-sanitize.hbs"
    }
  });
  _exports.default = _default;
});