define("open-event-frontend/components/events/view/export/api-response", ["exports", "open-event-frontend/utils/url", "open-event-frontend/config/environment"], function (_exports, _url, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.makeRequest();
    },
    isLoading: false,
    baseUrl: Ember.computed('eventId', function () {
      return "".concat("".concat(_environment.default.APP.apiHost, "/").concat(_environment.default.APP.apiNamespace, "/events/"), this.eventId);
    }),
    displayUrl: Ember.computed('eventId', function () {
      return "".concat("".concat(_environment.default.APP.apiHost, "/").concat(_environment.default.APP.apiNamespace, "/events/"), this.eventId);
    }),
    toggleSwitches: {
      sessions: false,
      microlocations: false,
      tracks: false,
      speakers: false,
      sponsors: false,
      tickets: false
    },
    makeRequest: function makeRequest() {
      var _this$event,
        _this = this;
      this.set('isLoading', true);
      if (((_this$event = this.event) === null || _this$event === void 0 ? void 0 : _this$event.state) === 'draft') {
        this.set('json', 'You need to publish event in order to access event information via REST API');
        this.set('isLoading', false);
        return;
      }
      this.loader.load(this.displayUrl, {
        isExternal: true
      }).then(function (json) {
        json = JSON.stringify(json, null, 2);
        _this.set('json', Ember.String.htmlSafe(syntaxHighlight(json)));
      }).catch(function (e) {
        if (_this.isDestroyed) {
          return;
        }
        console.error('Error while fetching export JSON from server', e);
        _this.notify.error(_this.l10n.t('Could not fetch from the server'), {
          id: 'server_fetch_error'
        });
        _this.set('json', 'Could not fetch from the server');
      }).finally(function () {
        if (_this.isDestroyed) {
          return;
        }
        _this.set('isLoading', false);
      });
    },
    buildDisplayUrl: function buildDisplayUrl() {
      var newUrl = this.baseUrl;
      var include = [];
      for (var key in this.toggleSwitches) {
        if (Object.prototype.hasOwnProperty.call(this.toggleSwitches, key)) {
          this.toggleSwitches[key] && include.push(key);
        }
      }
      this.set('displayUrl', (0, _url.buildUrl)(newUrl, {
        include: include.length > 0 ? include : undefined
      }, true));
    },
    actions: {
      checkboxChange: function checkboxChange(data) {
        this.set("toggleSwitches.".concat(data), !this.get("toggleSwitches.".concat(data)));
        this.buildDisplayUrl();
        this.makeRequest();
      }
    }
  });
  _exports.default = _default;
  function syntaxHighlight(json) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][/+\-/]?\d+)?)/g, function (match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return "<span class=\" ".concat(cls, "\"> ").concat(match, "</span>");
    });
  }
});