define("open-event-frontend/utils/url", ["exports", "url-parse", "query-string", "lodash-es"], function (_exports, _urlParse, _queryString, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildUrlViaUrlParse = _exports.buildUrlViaQueryString = _exports.buildUrl = void 0;
  _exports.extractYoutubeUrl = extractYoutubeUrl;
  /**
   * Build a URL with query string
   *
   * @param baseUrl
   * @param queryParams - An object with query params
   * @param stringifyArray - Should an array be converted to csv
   * @return {string}
   */
  var buildUrlViaUrlParse = function buildUrlViaUrlParse(baseUrl, queryParams) {
    var stringifyArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var parsedUrl = new _urlParse.default(baseUrl, true);
    if (stringifyArray) {
      queryParams = (0, _lodashEs.mapValues)(queryParams, function (value) {
        if ((0, _lodashEs.isArray)(value)) {
          return value.join(',');
        }
        return value;
      });
    }
    parsedUrl.set('query', (0, _lodashEs.merge)(parsedUrl.query, queryParams));
    return parsedUrl.toString();
  };

  /**
   * Build a URL with query string
   *
   * @param baseUrl
   * @param queryParams - An object with query params
   * @param stringifyArray - Should an array be converted to csv
   * @return {string}
   */
  _exports.buildUrlViaUrlParse = buildUrlViaUrlParse;
  var buildUrlViaQueryString = function buildUrlViaQueryString(baseUrl, queryParams) {
    var stringifyArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var parsedUrl = _queryString.default.parseUrl(baseUrl, {
      arrayFormat: 'bracket'
    });
    if (stringifyArray) {
      queryParams = (0, _lodashEs.mapValues)(queryParams, function (value) {
        if ((0, _lodashEs.isArray)(value)) {
          return value.join(',');
        }
        return value;
      });
    }
    // eslint-disable-next-line prefer-template
    return parsedUrl.url + '?' + _queryString.default.stringify((0, _lodashEs.merge)(parsedUrl.query, queryParams), {
      arrayFormat: 'bracket'
    });
  };
  _exports.buildUrlViaQueryString = buildUrlViaQueryString;
  var buildUrl = buildUrlViaQueryString;
  _exports.buildUrl = buildUrl;
  function extractYoutubeUrl(url) {
    if (!url) {
      return null;
    }
    var parsedUrl = new _urlParse.default(url, true);
    if (['youtube.com', 'www.youtube.com'].includes(parsedUrl.hostname)) {
      var _parsedUrl$query;
      return (_parsedUrl$query = parsedUrl.query) === null || _parsedUrl$query === void 0 ? void 0 : _parsedUrl$query.v;
    } else if (['youtu.be', 'www.youtu.be'].includes(parsedUrl.hostname)) {
      return parsedUrl.pathname.split('/')[1];
    }
    return null;
  }
});