define("open-event-frontend/helpers/warning-badge-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.warningBadgeTicket = warningBadgeTicket;
  function warningBadgeTicket(data) {
    var field = '';
    var fieldName = '';
    if (data[0]) {
      field = "'".concat(data[0], "' is not available in ticket");
    }
    if (data[1]) {
      fieldName = "".concat(field, " '").concat(data[1], "'");
    }
    return Ember.String.htmlSafe("<span>".concat(fieldName, "</span>"));
  }
  var _default = Ember.Helper.helper(warningBadgeTicket);
  _exports.default = _default;
});