define("open-event-frontend/templates/components/modals/proposal-withdraw-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="header">
    {{t 'Are you sure you would like to withdraw this proposal?'}}
    <div class="muted small text">
      {{t 'This action is final and cannot be reverted.'}}
    </div>
  </div>
  <div class="content">
    <div class="field">
      <div class="label">
        {{t 'Proceeding forward will withdraw the proposal titled:'}} {{this.proposalName}}
      </div>
    </div>
  </div>
  <div class="actions">
    <button type="button" class="ui black button" {{action 'close'}}>
      {{t 'Cancel'}}
    </button>
    <button type="button" class="ui red button" {{action this.withdrawProposal}}>
      {{t 'Withdraw Proposal'}}
    </button>
  </div>
  
  */
  {
    "id": "8vGUfIAl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Are you sure you would like to withdraw this proposal?\"],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"muted small text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"This action is final and cannot be reverted.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Proceeding forward will withdraw the proposal titled:\"],null]],[2,\" \"],[1,[32,0,[\"proposalName\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui black button\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Cancel\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"ui red button\"],[24,4,\"button\"],[4,[38,1],[[32,0],[32,0,[\"withdrawProposal\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Withdraw Proposal\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/proposal-withdraw-modal.hbs"
    }
  });
  _exports.default = _default;
});