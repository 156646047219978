define("open-event-frontend/templates/public/session/view/user/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui header">
    <img
      class="ui tiny avatar image mr-2 mb-2"
      src={{or this.model.user.thumbnailImageUrl this.model.user.avatarUrl '/images/placeholders/avatar.png'}} />
    {{this.model.user.resolvedName}}'s {{t 'schedule'}}
  </div>
  
  {{#each this.model.sessions as |session|}}
    <Public::SessionItem @session={{session}} @event={{this.model.event}} @timezone={{this.model.event.timezone}} />
  {{/each}}
  
  */
  {
    "id": "mmQDuS6Y",
    "block": "{\"symbols\":[\"session\"],\"statements\":[[10,\"div\"],[14,0,\"ui header\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"ui tiny avatar image mr-2 mb-2\"],[15,\"src\",[30,[36,0],[[32,0,[\"model\",\"user\",\"thumbnailImageUrl\"]],[32,0,[\"model\",\"user\",\"avatarUrl\"]],\"/images/placeholders/avatar.png\"],null]],[12],[13],[2,\"\\n  \"],[1,[32,0,[\"model\",\"user\",\"resolvedName\"]]],[2,\"'s \"],[1,[30,[36,1],[\"schedule\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"model\",\"sessions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"public/session-item\",[],[[\"@session\",\"@event\",\"@timezone\"],[[32,1],[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"event\",\"timezone\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/session/view/user/view.hbs"
    }
  });
  _exports.default = _default;
});