define("open-event-frontend/templates/components/orders/document-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui segments">
      <div class="ui orange inverted segment center aligned">
        <div class="ui inverted mini statistic horizontal">
          <div class="value">
            {{t 'Event Documents'}}
          </div>
        </div>
      </div>
      {{#if @event.isDocumentEnabled}}
        <div class="ui segment">
          {{#each @event.documentLinks as |document| }}
            <div class="column mb-2 right aligned">
             <a href="{{ document.link }}" target="_blank" rel="noopener"
              class="ui labeled icon blue button">
               <i class="file alternate icon"></i>
              {{document.name}}
             </a>
            </div>
          {{/each}}
        </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "bn3isRKX",
    "block": "{\"symbols\":[\"document\",\"@event\"],\"statements\":[[10,\"div\"],[14,0,\"ui segments\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui orange inverted segment center aligned\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui inverted mini statistic horizontal\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"value\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[\"Event Documents\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[32,2,[\"isDocumentEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"ui segment\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"documentLinks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"column mb-2 right aligned\"],[12],[2,\"\\n           \"],[10,\"a\"],[15,6,[31,[[32,1,[\"link\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[14,0,\"ui labeled icon blue button\"],[12],[2,\"\\n             \"],[10,\"i\"],[14,0,\"file alternate icon\"],[12],[13],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n           \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/orders/document-info.hbs"
    }
  });
  _exports.default = _default;
});