define("open-event-frontend/models/users-groups-role", ["exports", "open-event-frontend/models/base", "ember-data/relationships", "ember-data/attr"], function (_exports, _base, _relationships, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    email: (0, _attr.default)('string'),
    accepted: (0, _attr.default)('boolean'),
    token: (0, _attr.default)('string'),
    group: (0, _relationships.belongsTo)('group'),
    role: (0, _relationships.belongsTo)('role'),
    user: (0, _relationships.belongsTo)('user')
  });
  _exports.default = _default;
});