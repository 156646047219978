define("open-event-frontend/templates/account/danger-zone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Account::DangerZone @data={{this.model}} />
  
  */
  {
    "id": "BbFCS7Wc",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/danger-zone\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/account/danger-zone.hbs"
    }
  });
  _exports.default = _default;
});