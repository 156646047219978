define("open-event-frontend/templates/components/forms/admin/settings/analytics-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <form class="ui form text-cursor" {{action 'submit' on='submit'}}>
    <h3 class="ui header">
      {{t 'Google Analytics'}}
      <div class="sub header">
        ({{t 'See'}}
        <a href="https://support.google.com/analytics/answer/1008080" target="_blank" rel="noopener nofollow">
          {{t 'here'}}
        </a>
        {{t 'on how to get this tracking ID.'}})
      </div>
    </h3>
    <div class="field">
      <label>
        {{t 'Tracking ID'}}
      </label>
      <Input @type="text" @name="google_analytics_id" @value={{this.settings.analyticsKey}} />
    </div>
    <button class="ui teal wide button" type="submit">
      {{t 'Save'}}
    </button>
  </form>
  
  */
  {
    "id": "G5j4a1Cq",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[24,0,\"ui form text-cursor\"],[4,[38,0],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"ui header\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"Google Analytics\"],null]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sub header\"],[12],[2,\"\\n      (\"],[1,[30,[36,1],[\"See\"],null]],[2,\"\\n      \"],[10,\"a\"],[14,6,\"https://support.google.com/analytics/answer/1008080\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"here\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,1],[\"on how to get this tracking ID.\"],null]],[2,\")\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"Tracking ID\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@name\",\"@value\"],[\"text\",\"google_analytics_id\",[32,0,[\"settings\",\"analyticsKey\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"ui teal wide button\"],[14,4,\"submit\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"Save\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/admin/settings/analytics-form.hbs"
    }
  });
  _exports.default = _default;
});