define("open-event-frontend/templates/groups/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <GroupHeader @isRoomModalOpen={{this.isRoomModalOpen}} />
  <div class="row">
    <div class="sixteen column wide">
      {{#each this.model as |group|}}
        <GroupCard @group={{group}} @deleteGroup={{action 'deleteGroup'}} />
        <div class="ui hidden divider"></div>      
      {{else}}
        <div class="ui disabled header">{{t 'No groups found for the events.'}}</div>
      {{/each}}
      <InfinityLoader @infinityModel={{this.model}}>
        <div class="ui loading very padded basic segment">
        </div>
        {{this.infintyModel.reachedInfinity}}
      </InfinityLoader>
    </div>
  </div>
  
  */
  {
    "id": "I/0JXAch",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[8,\"group-header\",[],[[\"@isRoomModalOpen\"],[[32,0,[\"isRoomModalOpen\"]]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen column wide\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"group-card\",[],[[\"@group\",\"@deleteGroup\"],[[32,1],[30,[36,1],[[32,0],\"deleteGroup\"],null]]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui hidden divider\"],[12],[13],[2,\"      \\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"ui disabled header\"],[12],[1,[30,[36,0],[\"No groups found for the events.\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"infinity-loader\",[],[[\"@infinityModel\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui loading very padded basic segment\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[32,0,[\"infintyModel\",\"reachedInfinity\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/groups/list.hbs"
    }
  });
  _exports.default = _default;
});