define("open-event-frontend/templates/components/ui-table/cell/admin/sales/cell-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{currency-symbol @extraRecords.paymentCurrency}} {{format-money @record}}
  */
  {
    "id": "9aWcY8BZ",
    "block": "{\"symbols\":[\"@extraRecords\",\"@record\"],\"statements\":[[1,[30,[36,0],[[32,1,[\"paymentCurrency\"]]],null]],[2,\" \"],[1,[30,[36,1],[[32,2]],null]]],\"hasEval\":false,\"upvars\":[\"currency-symbol\",\"format-money\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/sales/cell-amount.hbs"
    }
  });
  _exports.default = _default;
});