define("open-event-frontend/templates/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.success}}
    <div class="ui {{if this.isLoading 'loading'}} icon info message eight wide column center aligned">
      <p>{{t 'Thank you, your email has been verified successfully! You now have full access to all features.'}} {{#if (not this.session.isAuthenticated)}} {{t 'Please login to continue.'}} {{/if}}</p>
    </div>
  {{else}}
    <div class="ui {{if this.isLoading 'loading'}} icon error message eight wide column center aligned">
      <p>{{t 'Error'}}: {{this.error}}</p>
    </div>
  {{/if}}
  
  */
  {
    "id": "EK3ZmTnz",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"success\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null],\" icon info message eight wide column center aligned\"]]],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"Thank you, your email has been verified successfully! You now have full access to all features.\"],null]],[2,\" \"],[6,[37,0],[[30,[36,2],[[32,0,[\"session\",\"isAuthenticated\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\" \"],[1,[30,[36,1],[\"Please login to continue.\"],null]],[2,\" \"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null],\" icon error message eight wide column center aligned\"]]],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"Error\"],null]],[2,\": \"],[1,[32,0,[\"error\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"not\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/verify.hbs"
    }
  });
  _exports.default = _default;
});