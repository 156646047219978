define("open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-video-recording", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if @record}}
    <div class="ui action input" style="width: 100%; height: 36px">
      <input class="truncate" style="flex: 1 0 !important" type="text" value={{@record}} placeholder={{@record}} readonly>
      <UiPopup @on="click" @content={{t "Link copied to clipboard"}} @position="bottom right">
        <CopyButton @clipboardText={{@record}} @class="ui grey icon button rounded-none m-0">
          <i class="copy icon"></i>
        </CopyButton>
      </UiPopup>
      <a 
        id="stream_url"
        href={{@record}} 
        target="_blank" rel="noopener">
        <div  class="ui blue icon button ml-auto rounded-l-none m-0">
          <i class="share icon" ></i>
        </div>
      </a>
    </div>
  {{/if}}
  
  */
  {
    "id": "bm/IeTzG",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui action input\"],[14,5,\"width: 100%; height: 36px\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"truncate\"],[14,5,\"flex: 1 0 !important\"],[15,2,[32,1]],[15,\"placeholder\",[32,1]],[14,\"readonly\",\"\"],[14,4,\"text\"],[12],[13],[2,\"\\n    \"],[8,\"ui-popup\",[],[[\"@on\",\"@content\",\"@position\"],[\"click\",[30,[36,0],[\"Link copied to clipboard\"],null],\"bottom right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"copy-button\",[],[[\"@clipboardText\",\"@class\"],[[32,1],\"ui grey icon button rounded-none m-0\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[14,0,\"copy icon\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"a\"],[14,1,\"stream_url\"],[15,6,[32,1]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui blue icon button ml-auto rounded-l-none m-0\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"share icon\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-video-recording.hbs"
    }
  });
  _exports.default = _default;
});