define("open-event-frontend/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{! This will be automatically rendered if any model fetch fails }}
  <div class="ui container">
    {{#if (eq this.model.errors.0.status 404)}}
      <Errors::NotFound />
    {{else if (eq this.model.errors.0.status 403)}}
      <Errors::ForbiddenError />
    {{else if (eq this.model.errors.0.status 500)}}
      <Errors::ServerError />
    {{else}}
      <Errors::GenericError />
    {{/if}}
  </div>
  
  */
  {
    "id": "zbbsEO4q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,0,[\"model\",\"errors\",\"0\",\"status\"]],404],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"errors/not-found\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"model\",\"errors\",\"0\",\"status\"]],403],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"errors/forbidden-error\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"model\",\"errors\",\"0\",\"status\"]],500],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"errors/server-error\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"errors/generic-error\",[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/error.hbs"
    }
  });
  _exports.default = _default;
});