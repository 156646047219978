define("open-event-frontend/templates/components/public/sponsor-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <a href="{{this.sponsor.url}}" target="_blank" rel="noopener nofollow">
    <img src="{{this.sponsor.logoUrl}}" height="250" width="250" class="ui image sponsor-image" alt="{{this.sponsor.name}}">
  </a>
  
  */
  {
    "id": "HkndwRdN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[31,[[32,0,[\"sponsor\",\"url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"src\",[31,[[32,0,[\"sponsor\",\"logoUrl\"]]]]],[14,\"height\",\"250\"],[14,\"width\",\"250\"],[14,0,\"ui image sponsor-image\"],[15,\"alt\",[31,[[32,0,[\"sponsor\",\"name\"]]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/sponsor-item.hbs"
    }
  });
  _exports.default = _default;
});