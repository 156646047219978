define("open-event-frontend/mixins/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = Ember.Mixin.create({
    actions: {
      markAsRead: function markAsRead(notification) {
        var _this = this;
        notification.set('isRead', true);
        notification.save().then(function () {
          _this.notify.success(_this.l10n.t('Marked as Read successfully'));
        }).catch(function () {
          _this.notify.error(_this.l10n.t('An unexpected error has occurred.'));
        });
      },
      markAllAsRead: function markAllAsRead(notifications) {
        var _this2 = this;
        var bulkPromises = [];
        var _iterator = _createForOfIteratorHelper(notifications ? notifications.toArray() : []),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var notification = _step.value;
            notification.set('isRead', true);
            bulkPromises.push(notification.save());
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        Promise.all(bulkPromises).then(function () {
          _this2.notify.success(_this2.l10n.t('Marked all as Read successfully'));
        }).catch(function (e) {
          console.error('Error while marking all notifications as read', e);
          _this2.notify.error(_this2.l10n.t('An unexpected error has occurred.'));
        });
      }
    }
  });
  _exports.default = _default;
});