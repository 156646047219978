define("open-event-frontend/templates/components/group-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="row">
    <div class="column">
      <div class="ui warning message mt-4 mb--1">
        <div class="header">
          {{t 'This feature is still in alpha stage.'}}
        </div>
        {{t 'Please use with care.'}} {{t 'Thank You.'}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="sixteen wide column">
      <div class="ui grid space-between items-center mt-4 mb-4">
        <h2>
          {{t 'Groups'}}
        </h2>
        <LinkTo @route="groups.create" class="item">
          <button class="ui blue button" {{action (mut @isRoomModalOpen) true}}>
            {{t 'Create Group'}}
          </button>
        </LinkTo>
      </div>
      <TabbedNavigation>
        <LinkTo @route="my-groups.list" class="item">
          {{t 'My Groups'}}
        </LinkTo>
        <LinkTo @route="my-groups.following" class="item">
          {{t 'Following'}}
        </LinkTo>
      </TabbedNavigation>
    </div>
  </div>
  */
  {
    "id": "FthNIPLp",
    "block": "{\"symbols\":[\"@isRoomModalOpen\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui warning message mt-4 mb--1\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"This feature is still in alpha stage.\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[30,[36,0],[\"Please use with care.\"],null]],[2,\" \"],[1,[30,[36,0],[\"Thank You.\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui grid space-between items-center mt-4 mb-4\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Groups\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"groups.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"button\"],[24,0,\"ui blue button\"],[4,[38,2],[[32,0],[30,[36,1],[[32,1]],null],true],null],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"Create Group\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"my-groups.list\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"My Groups\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"my-groups.following\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"Following\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/group-header.hbs"
    }
  });
  _exports.default = _default;
});