define("open-event-frontend/templates/events/view/tickets/attendees/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="sixteen column wide">
    <Tables::Default
      @tags={{this.model.tags}}
      @selectTag={{this.selectTag}}
      @columns={{this.columns}}
      @rows={{this.model.attendees.data}}
      @currentPage={{this.page}}
      @pageSize={{this.per_page}}
      @searchQuery={{this.search}}
      @sortBy={{this.sort_by}}
      @sortDir={{this.sort_dir}}
      @selectAll={{this.selectAll}}
      @metaData={{this.model.attendees.meta}}
      @filterOptions={{this.filterOptions}}
      @widthConstraint="eq-container"
      @resizeMode="fluid"
      @fillMode="equal-column" />
  </div>
  
  */
  {
    "id": "q7Fp+Owm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen column wide\"],[12],[2,\"\\n  \"],[8,\"tables/default\",[],[[\"@tags\",\"@selectTag\",\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@sortBy\",\"@sortDir\",\"@selectAll\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"model\",\"tags\"]],[32,0,[\"selectTag\"]],[32,0,[\"columns\"]],[32,0,[\"model\",\"attendees\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"selectAll\"]],[32,0,[\"model\",\"attendees\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/tickets/attendees/list.hbs"
    }
  });
  _exports.default = _default;
});