define("open-event-frontend/models/admin-statistics-user", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    superAdmin: (0, _attr.default)('number'),
    admin: (0, _attr.default)('number'),
    verified: (0, _attr.default)('number'),
    unverified: (0, _attr.default)('number'),
    owner: (0, _attr.default)('number'),
    organizer: (0, _attr.default)('number'),
    coorganizer: (0, _attr.default)('number'),
    attendee: (0, _attr.default)('number'),
    trackOrganizer: (0, _attr.default)('number'),
    total: Ember.computed('superAdmin', 'admin', 'verified', 'unverified', function () {
      return this.superAdmin + this.admin + this.verified + this.unverified;
    })
  });
  _exports.default = _default;
});