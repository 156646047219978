define("open-event-frontend/components/public/event-date-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @event.isSingleDay}}
    {{general-date @event.startsAt "dddd, D MMMM, YYYY" tz=@event.timezone}}
    {{#unless @noBreak}}
      <br>
    {{/unless}}
    {{general-date @event.startsAt 'time-short' tz=@event.timezone}} {{t 'to'}} {{general-date @event.endsAt 'time-short' tz=@event.timezone}} 
  {{else}}
    {{general-date @event.startsAt 'date-time-long' tz=@event.timezone}} {{t 'to'}} {{general-date @event.endsAt 'date-time-long' tz=@event.timezone}} 
  {{/if}}
  {{general-date @event.startsAt 'tz' tz=@event.timezone}}
  
  */
  {
    "id": "221E2fM8",
    "block": "{\"symbols\":[\"@event\",\"@noBreak\"],\"statements\":[[6,[37,3],[[32,1,[\"isSingleDay\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1,[\"startsAt\"]],\"dddd, D MMMM, YYYY\"],[[\"tz\"],[[32,1,[\"timezone\"]]]]]],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,0],[[32,1,[\"startsAt\"]],\"time-short\"],[[\"tz\"],[[32,1,[\"timezone\"]]]]]],[2,\" \"],[1,[30,[36,1],[\"to\"],null]],[2,\" \"],[1,[30,[36,0],[[32,1,[\"endsAt\"]],\"time-short\"],[[\"tz\"],[[32,1,[\"timezone\"]]]]]],[2,\" \\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,1,[\"startsAt\"]],\"date-time-long\"],[[\"tz\"],[[32,1,[\"timezone\"]]]]]],[2,\" \"],[1,[30,[36,1],[\"to\"],null]],[2,\" \"],[1,[30,[36,0],[[32,1,[\"endsAt\"]],\"date-time-long\"],[[\"tz\"],[[32,1,[\"timezone\"]]]]]],[2,\" \\n\"]],\"parameters\":[]}]]],[1,[30,[36,0],[[32,1,[\"startsAt\"]],\"tz\"],[[\"tz\"],[[32,1,[\"timezone\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"general-date\",\"t\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/components/public/event-date-time.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});