define("open-event-frontend/models/badge", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    /**
     * Attributes
     */
    badgeID: (0, _attr.default)('string'),
    /**
     * Relationships
     */
    event: (0, _relationships.belongsTo)('event'),
    ticket: (0, _relationships.hasMany)('ticket')
  });
  _exports.default = _default;
});