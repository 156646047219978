define("open-event-frontend/templates/account/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Account::PasswordSection
      @changePassword={{action "changePassword"}}
      @passwordCurrent={{this.passwordCurrent}}
      @passwordNew={{this.passwordNew}}
      @passwordRepeat={{this.passwordRepeat}}
      @isLoading={{this.isLoading}} />
  
  */
  {
    "id": "CWrF+Sx+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/password-section\",[],[[\"@changePassword\",\"@passwordCurrent\",\"@passwordNew\",\"@passwordRepeat\",\"@isLoading\"],[[30,[36,0],[[32,0],\"changePassword\"],null],[32,0,[\"passwordCurrent\"]],[32,0,[\"passwordNew\"]],[32,0,[\"passwordRepeat\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/account/password.hbs"
    }
  });
  _exports.default = _default;
});