define("open-event-frontend/templates/components/ui-table/cell/cell-event-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{get this.record this.column.propertyName}}
  */
  {
    "id": "F/ZrIBoO",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"record\"]],[32,0,[\"column\",\"propertyName\"]]],null]]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-event-state.hbs"
    }
  });
  _exports.default = _default;
});