define("open-event-frontend/templates/components/ui-table/cell/cell-event-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.record}}
    {{#if this.extraRecords.endsAt}}
      <div>
        {{general-date this.record 'date-time-tz-long' tz=this.extraRecords.timezone}}
      </div>
      ({{t 'to'}})
      <div>
        {{general-date this.extraRecords.endsAt 'date-time-tz-long' tz=this.extraRecords.timezone}}
      </div>
    {{else}}
      <span>
        {{t 'No dates available.'}}
      </span>
    {{/if}}
  {{else}}
    <span>
      {{t 'No dates available.'}}
    </span>
  {{/if}}
  
  */
  {
    "id": "9s2yv5Hz",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"record\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,0,[\"extraRecords\",\"endsAt\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"record\"]],\"date-time-tz-long\"],[[\"tz\"],[[32,0,[\"extraRecords\",\"timezone\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n    (\"],[1,[30,[36,0],[\"to\"],null]],[2,\")\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"extraRecords\",\"endsAt\"]],\"date-time-tz-long\"],[[\"tz\"],[[32,0,[\"extraRecords\",\"timezone\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"No dates available.\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"No dates available.\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"general-date\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-event-date.hbs"
    }
  });
  _exports.default = _default;
});