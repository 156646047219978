define("open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/activity-logs/cell-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{moment-from-now this.record}}
  
  */
  {
    "id": "5/sAERjA",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"record\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-from-now\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/activity-logs/cell-time.hbs"
    }
  });
  _exports.default = _default;
});