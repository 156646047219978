define("open-event-frontend/templates/components/tables/headers/select-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="{{if this.column.color (concat 'ui inverted segment ' this.column.color)}}">
    {{yield}}
    <UiCheckbox class=""
      @checked={{@selectAll}}
      @onChange={{action 'toggleSelectAll'}}/>
  </div>
  
  */
  {
    "id": "IsAy8ehV",
    "block": "{\"symbols\":[\"&default\",\"@selectAll\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,0,[\"column\",\"color\"]],[30,[36,0],[\"ui inverted segment \",[32,0,[\"column\",\"color\"]]],null]],null]]]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n  \"],[8,\"ui-checkbox\",[[24,0,\"\"]],[[\"@checked\",\"@onChange\"],[[32,2],[30,[36,2],[[32,0],\"toggleSelectAll\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/headers/select-all.hbs"
    }
  });
  _exports.default = _default;
});