define("open-event-frontend/templates/components/country-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <form class="field">
      <label class={{if @required 'required'}}>{{@placeholder}}</label>
      <UiDropdown
          @class="search selection"
          @selected={{@val}}
          @forceSelection={{false}}
          @fullTextSearch={{true}}
          @showOnFocus={{false}}>
      <Input
          @type="hidden"
          @autocomplete="no"
          @value={{@val}}
          @id={{@id}} />
      <i class="dropdown icon"></i>
      <div class="default text">{{t 'Select country'}}</div>
      <div class="menu">
          {{#each (if @paymentCountries this.paymentCountries this.countries) as |country|}}
              <div class="item" data-value="{{country.name}}">
              <i class="{{lowercase country.code}} flag"></i>
              {{t-var country.name}}
              </div>
          {{/each}}
      </div>
      </UiDropdown>
  </form>
  
  */
  {
    "id": "lHnbATXN",
    "block": "{\"symbols\":[\"country\",\"@required\",\"@placeholder\",\"@val\",\"@id\",\"@paymentCountries\"],\"statements\":[[10,\"form\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,0,[30,[36,2],[[32,2],\"required\"],null]],[12],[1,[32,3]],[13],[2,\"\\n    \"],[8,\"ui-dropdown\",[],[[\"@class\",\"@selected\",\"@forceSelection\",\"@fullTextSearch\",\"@showOnFocus\"],[\"search selection\",[32,4],false,true,false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@autocomplete\",\"@value\",\"@id\"],[\"hidden\",\"no\",[32,4],[32,5]]],null],[2,\"\\n    \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"default text\"],[12],[1,[30,[36,3],[\"Select country\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[30,[36,2],[[32,6],[32,0,[\"paymentCountries\"]],[32,0,[\"countries\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"item\"],[15,\"data-value\",[31,[[32,1,[\"name\"]]]]],[12],[2,\"\\n            \"],[10,\"i\"],[15,0,[31,[[30,[36,0],[[32,1,[\"code\"]]],null],\" flag\"]]],[12],[13],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"name\"]]],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lowercase\",\"t-var\",\"if\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/country-dropdown.hbs"
    }
  });
  _exports.default = _default;
});