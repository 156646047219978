define("open-event-frontend/templates/account/email-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Account::EmailPreferencesSection @preferences={{this.model}} />
  
  */
  {
    "id": "UrezPumd",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/email-preferences-section\",[],[[\"@preferences\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/account/email-preferences.hbs"
    }
  });
  _exports.default = _default;
});