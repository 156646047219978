define("open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-session-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span>
    <div class="ui {{this.color}} label">{{t-var (capitalize this.record)}}</div>
  </span>
  
  */
  {
    "id": "nu9Ip2Lb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"ui \",[32,0,[\"color\"]],\" label\"]]],[12],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"record\"]]],null]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"capitalize\",\"t-var\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/sessions/cell-session-state.hbs"
    }
  });
  _exports.default = _default;
});