define("open-event-frontend/templates/public/speaker/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui container">
    {{#if this.showEditSection}}
    <div class="ui row mb-8">
      <LinkTo
        @route="public.cfs.edit-speaker"
        @models={{array this.model.event.id this.model.id}}>
        <button
          class="ui blue button {{if this.device.isMobile 'fluid' 'right floated'}}">{{t 'Edit Speaker'}}</button>
        {{#if this.device.isMobile}}
          <div class="ui hidden fitted divider"></div>
        {{/if}}
      </LinkTo>
    </div>
    {{/if}}
    <div class="ui row">
      <Public::SpeakerItem
        @speaker={{this.model}}
      />
    </div>
  </div>
  
  */
  {
    "id": "+9P1VUkE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"showEditSection\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui row mb-8\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"public.cfs.edit-speaker\",[30,[36,0],[[32,0,[\"model\",\"event\",\"id\"]],[32,0,[\"model\",\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"button\"],[15,0,[31,[\"ui blue button \",[30,[36,1],[[32,0,[\"device\",\"isMobile\"]],\"fluid\",\"right floated\"],null]]]],[12],[1,[30,[36,2],[\"Edit Speaker\"],null]],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"device\",\"isMobile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"ui hidden fitted divider\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"ui row\"],[12],[2,\"\\n    \"],[8,\"public/speaker-item\",[],[[\"@speaker\"],[[32,0,[\"model\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"array\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/speaker/view.hbs"
    }
  });
  _exports.default = _default;
});