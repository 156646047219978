define("open-event-frontend/utils/dictionary/home-wikis", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.homeWikis = void 0;
  var homeWikis = [{
    item: _text.tn.t('Wikipedia')
  }, {
    item: _text.tn.t('Wiktionary')
  }, {
    item: _text.tn.t('Wikiquote')
  }, {
    item: _text.tn.t('Wikinews')
  }, {
    item: _text.tn.t('Wikisource')
  }, {
    item: _text.tn.t('Wikibooks')
  }, {
    item: _text.tn.t('Wikiversity')
  }, {
    item: _text.tn.t('Wikivoyage')
  }, {
    item: _text.tn.t('Wikimedia Commons')
  }, {
    item: _text.tn.t('Wikidata')
  }, {
    item: _text.tn.t('Wikispecies')
  }, {
    item: _text.tn.t('Meta-Wiki')
  }];
  _exports.homeWikis = homeWikis;
});