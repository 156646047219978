define("open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-stream-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if @record}}
    <div class="ui action input" style="width: 100%; height: 36px">
      <input class="truncate" style="flex: 1 0 !important" type="text" value={{this.streamUrl}} placeholder={{this.streamUrl}} readonly>
      <UiPopup @on="click" @content={{t "Link copied to clipboard"}} @position="bottom right">
        <CopyButton @clipboardText={{this.streamUrl}} @class="ui grey icon button rounded-none m-0">
          <i class="copy icon"></i>
        </CopyButton>
      </UiPopup>
      <a 
        id="stream_url"
        href="{{href-to 'public.stream.view' (or @extraRecords.identifier @extraRecords.event.identifier) @record.slugName @record}}" 
        target="_blank" rel="noopener">
        <div  class="ui blue icon button ml-auto rounded-l-none m-0">
          <i class="share icon" ></i>
        </div>
      </a>
    </div>
  {{/if}}
  
  */
  {
    "id": "9E/VUPDP",
    "block": "{\"symbols\":[\"@record\",\"@extraRecords\"],\"statements\":[[6,[37,3],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui action input\"],[14,5,\"width: 100%; height: 36px\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,0,\"truncate\"],[14,5,\"flex: 1 0 !important\"],[15,2,[32,0,[\"streamUrl\"]]],[15,\"placeholder\",[32,0,[\"streamUrl\"]]],[14,\"readonly\",\"\"],[14,4,\"text\"],[12],[13],[2,\"\\n    \"],[8,\"ui-popup\",[],[[\"@on\",\"@content\",\"@position\"],[\"click\",[30,[36,0],[\"Link copied to clipboard\"],null],\"bottom right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"copy-button\",[],[[\"@clipboardText\",\"@class\"],[[32,0,[\"streamUrl\"]],\"ui grey icon button rounded-none m-0\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[14,0,\"copy icon\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"a\"],[14,1,\"stream_url\"],[15,6,[31,[[30,[36,2],[\"public.stream.view\",[30,[36,1],[[32,2,[\"identifier\"]],[32,2,[\"event\",\"identifier\"]]],null],[32,1,[\"slugName\"]],[32,1]],null]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui blue icon button ml-auto rounded-l-none m-0\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"share icon\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"href-to\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-stream-url.hbs"
    }
  });
  _exports.default = _default;
});