define("open-event-frontend/helpers/session-color", ["exports", "open-event-frontend/utils/dictionary/sessions"], function (_exports, _sessions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sessionColor = sessionColor;
  function sessionColor(params) {
    return _sessions.stateColorMap[params[0]];
  }
  var _default = Ember.Helper.helper(sessionColor);
  _exports.default = _default;
});