define("open-event-frontend/templates/components/ui-table/cell/cell-channel-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui horizontal compact basic buttons">
    <UiPopup @content={{t "Edit "}} @class="ui icon button" @click={{action @props.actions.editChannel @record}} @position="left center">
      <i class="edit icon"></i>
    </UiPopup>
    <UiPopup @content={{t "Delete "}} @click={{action (confirm (t "Are you sure you would like to delete this video channel?") (action @props.actions.deleteChannel @record))}} @class="ui icon button" @position="left center">
      <i class="trash icon"></i>
    </UiPopup>
  </div>
  
  */
  {
    "id": "FMJKeuMy",
    "block": "{\"symbols\":[\"@record\",\"@props\"],\"statements\":[[10,\"div\"],[14,0,\"ui horizontal compact basic buttons\"],[12],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@class\",\"@click\",\"@position\"],[[30,[36,0],[\"Edit \"],null],\"ui icon button\",[30,[36,1],[[32,0],[32,2,[\"actions\",\"editChannel\"]],[32,1]],null],\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"edit icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@click\",\"@class\",\"@position\"],[[30,[36,0],[\"Delete \"],null],[30,[36,1],[[32,0],[30,[36,2],[[30,[36,0],[\"Are you sure you would like to delete this video channel?\"],null],[30,[36,1],[[32,0],[32,2,[\"actions\",\"deleteChannel\"]],[32,1]],null]],null]],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"trash icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"confirm\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-channel-action.hbs"
    }
  });
  _exports.default = _default;
});