define("open-event-frontend/helpers/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;
  function includes(params) {
    if (params[0] && typeof params[0].includes === 'function') {
      return params[0].includes(params[1]);
    }
    return false;
  }
  var _default = Ember.Helper.helper(includes);
  _exports.default = _default;
});