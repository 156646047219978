define("open-event-frontend/components/forms/session-section", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/utils/dictionary/languages", "lodash-es", "open-event-frontend/utils/dictionary/levels"], function (_exports, _form, _languages, _lodashEs, _levels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = Ember.Component.extend(_form.default, {
    languages: (0, _lodashEs.orderBy)(_languages.languages, 'name'),
    levels: (0, _lodashEs.orderBy)(_levels.levels, 'position'),
    actions: {
      removeDocument: function removeDocument(document) {
        this.data.session.slides = this.data.session.slides.filter(function (dl) {
          return dl !== document;
        });
      },
      addSessionDocument: function addSessionDocument() {
        if (this.data.session.slides) {
          this.data.session.slides = [].concat(_toConsumableArray(this.data.session.slides), [{
            name: '',
            link: ''
          }]);
        } else {
          this.data.session.slides = [{
            name: '',
            link: ''
          }];
        }
      }
    }
  });
  _exports.default = _default;
});