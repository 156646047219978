define("open-event-frontend/serializers/event", ["exports", "open-event-frontend/serializers/application", "open-event-frontend/mixins/custom-primary-key"], function (_exports, _application, _customPrimaryKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend(_customPrimaryKey.default, {
    primaryKey: 'attributes.identifier',
    attrs: {
      type: 'event-type',
      topic: 'event-topic',
      subTopic: 'event-sub-topic',
      copyright: 'event-copyright'
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      var relationships = json.data.relationships;
      // We are deleting read only relationships so that they don't
      // break the server. Since these relationships are not always
      // present, we catch and ignore the error
      try {
        delete relationships['general-statistics'];
      } catch (_unused) {} // eslint-disable-line no-empty
      try {
        delete relationships['order-statistics'];
      } catch (_unused2) {} // eslint-disable-line no-empty

      return json;
    }
  });
  _exports.default = _default;
});