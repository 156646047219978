define("open-event-frontend/templates/admin/content/system-images/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid segment">
    {{#each this.data as |subTopic|}}
      <div class="eight wide column">
        <h4>{{subTopic.name}}</h4>
        <Widgets::SafeImage @isAvatar={{true}} @class="ui big image" @src={{if subTopic.placeholder.originalImageUrl subTopic.placeholder.originalImageUrl "/images/placeholders/avatar.png"}} />
        <div class="ui hidden divider"></div>
        <button class="ui button primary" {{action 'openModal' subTopic.placeholder}} id="changebutton">{{t 'Change'}}</button>
      </div>
    {{else}}
      <div class="ui disabled header">
        {{t 'No images to show'}}
      </div>
    {{/each}}
  </div>
  <Modals::ChangeImageModal @isOpen={{this.isModalOpen}} @placeholder={{this.selectedPlaceholder}} />
  
  */
  {
    "id": "Esv0nSpk",
    "block": "{\"symbols\":[\"subTopic\"],\"statements\":[[10,\"div\"],[14,0,\"ui grid segment\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"data\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"eight wide column\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n      \"],[8,\"widgets/safe-image\",[],[[\"@isAvatar\",\"@class\",\"@src\"],[true,\"ui big image\",[30,[36,1],[[32,1,[\"placeholder\",\"originalImageUrl\"]],[32,1,[\"placeholder\",\"originalImageUrl\"]],\"/images/placeholders/avatar.png\"],null]]],null],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui hidden divider\"],[12],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"ui button primary\"],[24,1,\"changebutton\"],[4,[38,2],[[32,0],\"openModal\",[32,1,[\"placeholder\"]]],null],[12],[1,[30,[36,0],[\"Change\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui disabled header\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"No images to show\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[8,\"modals/change-image-modal\",[],[[\"@isOpen\",\"@placeholder\"],[[32,0,[\"isModalOpen\"]],[32,0,[\"selectedPlaceholder\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/content/system-images/list.hbs"
    }
  });
  _exports.default = _default;
});