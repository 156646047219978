define("open-event-frontend/templates/admin/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui grid">
    <div class="row">
      <div class="sixteen wide column">
        <TabbedNavigation @isNonPointing={{true}}>
          <LinkTo @route="admin.content.index" class="item">
            {{t 'Social Links'}}
          </LinkTo>
          <LinkTo @route="admin.content.pages" class="item">
            {{t 'Pages'}}
          </LinkTo>
          <LinkTo @route="admin.content.system-images" class="item">
            {{t 'System Images'}}
          </LinkTo>
          <LinkTo @route="admin.content.translations" class="item">
            {{t 'Translations'}}
          </LinkTo>
          <LinkTo @route="admin.content.events" class="item">
            {{t 'Events'}}
          </LinkTo>
        </TabbedNavigation>
      </div>
    </div>
    <div class="row">
      <div class="sixteen wide column">
        {{outlet}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "h3mEUJj1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[8,\"tabbed-navigation\",[],[[\"@isNonPointing\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.content.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Social Links\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.content.pages\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Pages\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.content.system-images\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"System Images\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.content.translations\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Translations\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"admin.content.events\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"Events\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/content.hbs"
    }
  });
  _exports.default = _default;
});