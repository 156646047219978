define("open-event-frontend/components/forms/admin/content/translation-form", ["exports", "open-event-frontend/mixins/form"], function (_exports, _form) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          file: {
            identifier: 'file',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please upload a file')
            }, {
              type: 'regExp',
              value: '/^(.*.((po)$))?[^.]*$/i',
              prompt: this.l10n.t('Please upload a file in suggested format')
            }]
          },
          language: {
            identifier: 'language',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select a language')
            }]
          }
        }
      };
    },
    actions: {
      submit: function submit() {
        this.onValid(function () {});
      }
    }
  });
  _exports.default = _default;
});