define("open-event-frontend/templates/components/tables/utilities/add-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui {{if (eq @size "large") '' 'small'}} icon input search-box {{if this.device.isMobile 'w-full' ''}}">
    <button class="ui primary button fs-15">
      {{t 'Add Tags'}}
    </button>
  </div>
  
  */
  {
    "id": "hHVVPWAb",
    "block": "{\"symbols\":[\"@size\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,0],[[30,[36,1],[[32,1],\"large\"],null],\"\",\"small\"],null],\" icon input search-box \",[30,[36,0],[[32,0,[\"device\",\"isMobile\"]],\"w-full\",\"\"],null]]]],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"ui primary button fs-15\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"Add Tags\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"eq\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/utilities/add-tags.hbs"
    }
  });
  _exports.default = _default;
});