define("open-event-frontend/components/forms/user-profile-form", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/utils/validators"], function (_exports, _form, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, {
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          name: {
            identifier: 'name',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your name')
            }]
          },
          family: {
            identifier: 'last_name',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your family name')
            }]
          },
          email: {
            identifier: 'email',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your email ID')
            }, {
              type: 'email',
              prompt: this.l10n.t('Please enter a valid email address')
            }]
          },
          phone: {
            identifier: 'phone',
            optional: true,
            rules: [{
              type: 'regExp',
              value: _validators.validPhoneNumber,
              prompt: this.l10n.t('Please enter a valid phone number')
            }]
          }
        }
      };
    },
    actions: {
      submit: function submit() {
        var _this = this;
        this.onValid(function () {
          _this.set('isLoading', true);
          _this.user.save().then(function () {
            _this.notify.success(_this.l10n.t('Your profile has been updated'), {
              id: 'profi_update'
            });
          }).catch(function (e) {
            console.error('Error while  updating profile.', e);
            _this.authManager.currentUser.rollbackAttributes();
            _this.notify.error(_this.l10n.t('Sorry, an unexpected error has occurred. Our developers will fix this.'), {
              id: 'profi_error'
            });
          }).finally(function () {
            _this.set('isLoading', false);
            _this.l10n.switchLanguage(_this.user.languagePrefrence);
            _this.cookies.write('current_locale', _this.user.languagePrefrence, {
              path: '/'
            });
          });
        });
      }
    }
  });
  _exports.default = _default;
});