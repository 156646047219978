define("open-event-frontend/services/storefront", ["exports", "ember-data-storefront/services/storefront"], function (_exports, _storefront) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _storefront.default;
    }
  });
});