define("open-event-frontend/templates/events/view/speakers/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="column sixteen wide">
    <h2 class="ui header center aligned">
      {{t 'Create a Speaker'}}
    </h2>
    <div class="ui container">
      <Forms::SessionSpeakerForm
        @fields={{this.model.form}}
        @data={{this.model}}
        @event={{this.model.event}}
        @sessions={{this.model.sessions}}
        @sessionDetails={{this.sessionDetails}}
        @save={{action "save" this.sessionDetails}}
        @includeSpeaker={{true}}
        @isSessionSpeaker={{true}}
        @isLoading={{this.isLoading}} />
    </div>
  </div>
  
  */
  {
    "id": "ls8IKJZu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Create a Speaker\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n    \"],[8,\"forms/session-speaker-form\",[],[[\"@fields\",\"@data\",\"@event\",\"@sessions\",\"@sessionDetails\",\"@save\",\"@includeSpeaker\",\"@isSessionSpeaker\",\"@isLoading\"],[[32,0,[\"model\",\"form\"]],[32,0,[\"model\"]],[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"sessions\"]],[32,0,[\"sessionDetails\"]],[30,[36,1],[[32,0],\"save\",[32,0,[\"sessionDetails\"]]],null],true,true,[32,0,[\"isLoading\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/speakers/create.hbs"
    }
  });
  _exports.default = _default;
});