define("open-event-frontend/transforms/moment", ["exports", "ember-data/transform", "moment-timezone"], function (_exports, _transform, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }
      return (0, _momentTimezone.default)(serialized);
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }
      return (0, _momentTimezone.default)(deserialized).toISOString();
    }
  });
  _exports.default = _default;
});