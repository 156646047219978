define("open-event-frontend/templates/components/events/view/overview/event-sponsors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="content d-flex" style="align-items: center;">
    <div class="header">{{t 'Supporters'}}</div>
    <LinkTo
    @route="events.view.edit.sponsors"
    @tagName="button" class="ui right floated blue button item ml-auto">
      {{t 'Edit'}}
    </LinkTo>
  </div>
  
  <div class="content">
    <div style="width: 95%;margin-left:2.5%">
      <Tables::Default
        @columns={{this.columns}}
        @rows={{this.data.data}}
        @currentPage={{this.page}}
        @pageSize={{this.per_page}}
        @searchQuery={{this.search}}
        @hideSearchBox={{true}}
        @sortBy={{this.sort_by}}
        @sortDir={{this.sort_dir}}
        @metaData={{this.data.meta}}
        @filterOptions={{this.filterOptions}}
        @widthConstraint="eq-container"
        @resizeMode="fluid"
        @fillMode="equal-column" />
    </div>
  </div>
  
  */
  {
    "id": "uVXefitr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content d-flex\"],[14,5,\"align-items: center;\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[1,[30,[36,0],[\"Supporters\"],null]],[13],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"ui right floated blue button item ml-auto\"]],[[\"@route\",\"@tagName\"],[\"events.view.edit.sponsors\",\"button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Edit\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,5,\"width: 95%;margin-left:2.5%\"],[12],[2,\"\\n    \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@hideSearchBox\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"data\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],true,[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"data\",\"meta\"]],[32,0,[\"filterOptions\"]],\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/events/view/overview/event-sponsors.hbs"
    }
  });
  _exports.default = _default;
});