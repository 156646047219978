define("open-event-frontend/utils/dictionary/age-groups", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ageGroups = void 0;
  var ageGroups = [{
    age: _text.tn.t('Under 18'),
    position: 1
  }, {
    age: '18-24',
    position: 2
  }, {
    age: '25-34',
    position: 3
  }, {
    age: '35-44',
    position: 4
  }, {
    age: '45-54',
    position: 5
  }, {
    age: '55-64',
    position: 6
  }, {
    age: '65-74',
    position: 7
  }, {
    age: '75-84',
    position: 8
  }, {
    age: '85+',
    position: 9
  }, {
    age: _text.tn.t('I prefer not to say'),
    position: 10
  }];
  _exports.ageGroups = ageGroups;
});