define("open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/mail-logs/cell-mail-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <h5 class="ui header">
    {{this.extraRecords.subject}}
  </h5>
  <span>
    {{sanitize this.record}}
  </span>
  
  */
  {
    "id": "PNCaGCj5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h5\"],[14,0,\"ui header\"],[12],[2,\"\\n  \"],[1,[32,0,[\"extraRecords\",\"subject\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"record\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sanitize\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/reports/system-logs/mail-logs/cell-mail-message.hbs"
    }
  });
  _exports.default = _default;
});