define("open-event-frontend/mixins/form", ["exports", "jquery", "lodash-es", "moment-timezone", "open-event-frontend/utils/dictionary/date-time"], function (_exports, _jquery, _lodashEs, _momentTimezone, _dateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    actions: {
      mutateOne: function mutateOne(param, value) {
        this.set(param, value[0]);
      }
    },
    autoScrollToErrors: true,
    autoScrollSpeed: 200,
    getForm: function getForm() {
      return this.$form;
    },
    onValid: function onValid(callback) {
      this.getForm().form('validate form');
      if (this.getForm().form('is valid')) {
        callback();
      }
    },
    didRender: function didRender() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.debounce(this, function () {
        var defaultFormRules = {
          onFailure: function onFailure(formErrors) {
            if (_this.autoScrollToErrors) {
              // Scroll to the first error message
              if (formErrors.length > 0) {
                (0, _jquery.default)('html,body').animate({
                  scrollTop: (0, _jquery.default)("div:contains('".concat(formErrors[0], "')"), _this.element).offset().top
                }, _this.autoScrollSpeed);
              }
            }
          }
        };
        var $popUps = (0, _jquery.default)('.has.popup', _this.element);
        if ($popUps) {
          $popUps.popup({
            hoverable: true
          });
        }
        var $checkBoxes = (0, _jquery.default)('.ui.checkbox:not(.ember-view)', _this.element);
        if ($checkBoxes) {
          $checkBoxes.checkbox();
        }
        var $form;
        if (_this.tagName && _this.tagName.toLowerCase() === 'form' || _this.$() && _this.$().prop('tagName').toLowerCase() === 'form') {
          $form = _this.$();
          $form.addClass('ui form');
        } else {
          $form = _this.$('.ui.form');
        }
        if ($form) {
          $form = $form.first();
          if (_this.getValidationRules && $form) {
            $form.form((0, _lodashEs.merge)(defaultFormRules, _this.getValidationRules()));
          }
          if ($form && _this) {
            _this.set('$form', $form);
          }
        }
      }, 400);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.$.fn.form.settings.rules.date = function (value) {
        var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _dateTime.FORM_DATE_FORMAT;
        if (value && value.length > 0 && format) {
          return (0, _momentTimezone.default)(value, format).isValid();
        }
        return true;
      };
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var $popUps = (0, _jquery.default)('.has.popup', this.element);
      if ($popUps) {
        $popUps.popup('destroy');
      }
    }
  });
  _exports.default = _default;
});