define("open-event-frontend/serializers/ticket", ["exports", "open-event-frontend/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    normalize: function normalize() {
      var payload = this._super.apply(this, arguments);
      payload.data = this.addLinks(payload.data);
      return payload;
    },
    addLinks: function addLinks(ticket) {
      ticket.relationships.orderStatistics = {
        links: {
          related: "/v1/tickets/".concat(ticket.id, "/order-statistics"),
          self: "/v1/tickets/".concat(ticket.id, "/order-statistics")
        }
      };
      ticket.relationships.attendees = {
        links: {
          related: "/v1/tickets/".concat(ticket.id, "/attendees"),
          self: "/v1/tickets/".concat(ticket.id, "/attendees")
        }
      };
      return ticket;
    }
  });
  _exports.default = _default;
});