define("open-event-frontend/utils/dictionary/boolean_complex", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.booleanComplex = void 0;
  var booleanComplex = [{
    name: _text.tn.t('Yes'),
    position: 1
  }, {
    name: _text.tn.t('No'),
    position: 2
  }, {
    name: _text.tn.t('Not sure yet'),
    position: 3
  }];
  _exports.booleanComplex = booleanComplex;
});