define("open-event-frontend/helpers/currency-name", ["exports", "lodash-es", "open-event-frontend/utils/dictionary/payment"], function (_exports, _lodashEs, _payment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currencyName = currencyName;
  _exports.default = void 0;
  function currencyName(params) {
    return (0, _lodashEs.find)(_payment.paymentCurrencies, ['code', params[0]]).name;
  }
  var _default = Ember.Helper.helper(currencyName);
  _exports.default = _default;
});