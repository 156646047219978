define("open-event-frontend/templates/components/tables/utilities/search-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.device.isMobile}}
    <div class="ui {{if (eq @size 'large') '' 'small'}} icon input search-box-mobile w-full relative h-full mt-0">
    <Input @type="text" @value={{readonly this.searchQuery}} @keyUp={{action "setSearchQuery" value="target.value"}} placeholder="{{t 'Search'}} ..." />
    <i class="search icon"></i>
    </div>
  {{else}}
    <div class="ui {{if (eq @size "large") '' 'small'}} icon input search-box">
      <Input @type="text" @value={{readonly this.searchQuery}} @keyUp={{action 'setSearchQuery' value="target.value"}} placeholder="{{t "Search"}} ..." />
      <i class="search icon"></i>
    </div>
  {{/if}}
  
  
  */
  {
    "id": "9f5qGLwt",
    "block": "{\"symbols\":[\"@size\"],\"statements\":[[6,[37,1],[[32,0,[\"device\",\"isMobile\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,1],[[30,[36,0],[[32,1],\"large\"],null],\"\",\"small\"],null],\" icon input search-box-mobile w-full relative h-full mt-0\"]]],[12],[2,\"\\n  \"],[8,\"input\",[[16,\"placeholder\",[31,[[30,[36,4],[\"Search\"],null],\" ...\"]]]],[[\"@type\",\"@value\",\"@keyUp\"],[\"text\",[30,[36,2],[[32,0,[\"searchQuery\"]]],null],[30,[36,3],[[32,0],\"setSearchQuery\"],[[\"value\"],[\"target.value\"]]]]],null],[2,\"\\n  \"],[10,\"i\"],[14,0,\"search icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,1],[[30,[36,0],[[32,1],\"large\"],null],\"\",\"small\"],null],\" icon input search-box\"]]],[12],[2,\"\\n    \"],[8,\"input\",[[16,\"placeholder\",[31,[[30,[36,4],[\"Search\"],null],\" ...\"]]]],[[\"@type\",\"@value\",\"@keyUp\"],[\"text\",[30,[36,2],[[32,0,[\"searchQuery\"]]],null],[30,[36,3],[[32,0],\"setSearchQuery\"],[[\"value\"],[\"target.value\"]]]]],null],[2,\"\\n    \"],[10,\"i\"],[14,0,\"search icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"readonly\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tables/utilities/search-box.hbs"
    }
  });
  _exports.default = _default;
});