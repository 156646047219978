define("open-event-frontend/components/-ember-table-private/simple-checkbox", ["exports", "ember-table/components/-private/simple-checkbox"], function (_exports, _simpleCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _simpleCheckbox.default;
    }
  });
});