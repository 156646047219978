define("open-event-frontend/helpers/general-date", ["exports", "moment-timezone", "open-event-frontend/utils/dictionary/tzAbbr"], function (_exports, _momentTimezone, _tzAbbr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.generalDate = generalDate;
  var dateFormats = {
    'date-time-long': 'dddd, D MMMM, YYYY h:mm A',
    'date-time-tz-long': 'dddd, D MMMM, YYYY h:mm A',
    'date-time-short': 'D MMM, YYYY h:mm A',
    'date-time-tz-short': 'D MMM, YYYY h:mm A',
    'date-short': 'D MMM, YYYY',
    'time-short': 'h:mm A',
    'time-tz-short': 'h:mm A',
    'tz': ' '
  };
  var locales12Hours = new Set(['en', 'bn', 'hi', 'id', 'ja', 'run', 'th', 'vi', 'ko']);
  function generalDate(params, _ref) {
    var tz = _ref.tz;
    var timezone = tz || _momentTimezone.default.tz.guess();
    var local = (0, _momentTimezone.default)(params[0]).tz(timezone).locale();
    var format = dateFormats[params[1]] || params[1] || 'h:mm A, MMMM Do YYYY';
    if (!locales12Hours.has(local)) {
      format = format.replace(/h/g, 'H');
      format = format.replace(' A', '');
      format = format.replace(' a', '');
    }
    var dateTime = (0, _momentTimezone.default)(params[0]).tz(timezone).format(format);
    var timezoneAbbr = _tzAbbr.tzAbbr[timezone] || (0, _momentTimezone.default)(params[0]).tz(timezone).format('z');
    if (!params[1] || params[1].includes('tz')) {
      dateTime += " (".concat(timezoneAbbr, ")");
    }
    return dateTime;
  }
  var _default = Ember.Helper.helper(generalDate);
  _exports.default = _default;
});