define("open-event-frontend/helpers/not-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notIncludes = notIncludes;
  function notIncludes(params) {
    if (params[0] && typeof params[0].includes === 'function') {
      return !params[0].includes(params[1]);
    }
    return true;
  }
  var _default = Ember.Helper.helper(notIncludes);
  _exports.default = _default;
});