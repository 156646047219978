define("open-event-frontend/models/speakers-call", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships", "ember-uuid", "open-event-frontend/utils/computed-helpers", "moment-timezone"], function (_exports, _attr, _base, _relationships, _emberUuid, _computedHelpers, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var detectedTimezone = _momentTimezone.default.tz.guess();
  var _default = _base.default.extend({
    announcement: (0, _attr.default)('string'),
    startsAt: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return _momentTimezone.default.tz(detectedTimezone).subtract(3, 'days').startOf('day');
      }
    }),
    softEndsAt: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    endsAt: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return _momentTimezone.default.tz(detectedTimezone).subtract(2, 'days').startOf('day');
      }
    }),
    privacy: (0, _attr.default)('string', {
      defaultValue: 'public'
    }),
    hash: (0, _attr.default)('string', {
      defaultValue: (0, _emberUuid.v4)()
    }),
    event: (0, _relationships.belongsTo)('event'),
    startsAtDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('startsAt', 'date', 'endsAt'),
    startsAtTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('startsAt', 'time', 'endsAt'),
    softEndsAtDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('softEndsAt', 'date'),
    softEndsAtTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('softEndsAt', 'time'),
    endsAtDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('endsAt', 'date'),
    endsAtTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('endsAt', 'time'),
    isOpen: Ember.computed('startsAt', 'endsAt', function () {
      return (0, _momentTimezone.default)().isAfter(this.startsAt) && (0, _momentTimezone.default)().isBefore(this.endsAt);
    }),
    isInFuture: Ember.computed('startsAt', function () {
      return (0, _momentTimezone.default)(this.startsAt).isAfter();
    })
  });
  _exports.default = _default;
});