define("open-event-frontend/mixins/custom-primary-key", ["exports", "lodash-es", "open-event-frontend/utils/internal", "ember-data/attr"], function (_exports, _lodashEs, _internal, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    originalId: (0, _attr.default)(),
    extractId: function extractId(modelClass, resourceHash) {
      var id = (0, _lodashEs.get)(resourceHash, this.primaryKey);
      return (0, _internal.coerceId)(id);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      if (this.primaryKey !== 'id') {
        (0, _lodashEs.unset)(json, ['data', this.primaryKey]); // Remove the custom primary key
        json.data.id = json.data.attributes['original-id']; // Restore the original from copy
        (0, _lodashEs.unset)(json, 'data.attributes.original-id'); // Remove the original's copy
      }

      return json;
    },
    extractAttributes: function extractAttributes(modelClass, resourceHash) {
      var attributes = this._super.apply(this, arguments);
      if (this.primaryKey !== 'id') {
        attributes.originalId = resourceHash.id;
      }
      return attributes;
    }
  });
  _exports.default = _default;
});