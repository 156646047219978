define("open-event-frontend/templates/components/ui-table/cell/admin/users/cell-created-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span data-tooltip={{this.record}}>
    {{moment-from-now this.record}}
  </span>
  
  */
  {
    "id": "AxXAEwXz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,\"data-tooltip\",[32,0,[\"record\"]]],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"record\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"moment-from-now\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/users/cell-created-at.hbs"
    }
  });
  _exports.default = _default;
});