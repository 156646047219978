define("open-event-frontend/templates/components/ui-table/cell/cell-sponsor-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui {{if this.device.isMobile 'horizontal' 'vertical'}} compact basic buttons">
    <UiPopup @content={{t "Edit"}} @click={{action this.props.actions.editSponsor}} @class="ui icon button" @position="left center">
      <i class="edit icon"></i>
    </UiPopup>
    <UiPopup @content={{t "Delete"}} @click={{action (confirm (t "Are you sure you would like to delete this Supporter?") (action this.props.actions.deleteSponsor this.record))}} @class="ui icon button" @position="left center">
      <i class="trash icon"></i>
    </UiPopup>
  </div>
  
  */
  {
    "id": "U1hI4Uha",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"ui \",[30,[36,0],[[32,0,[\"device\",\"isMobile\"]],\"horizontal\",\"vertical\"],null],\" compact basic buttons\"]]],[12],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@click\",\"@class\",\"@position\"],[[30,[36,1],[\"Edit\"],null],[30,[36,2],[[32,0],[32,0,[\"props\",\"actions\",\"editSponsor\"]]],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"edit icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@click\",\"@class\",\"@position\"],[[30,[36,1],[\"Delete\"],null],[30,[36,2],[[32,0],[30,[36,3],[[30,[36,1],[\"Are you sure you would like to delete this Supporter?\"],null],[30,[36,2],[[32,0],[32,0,[\"props\",\"actions\",\"deleteSponsor\"]],[32,0,[\"record\"]]],null]],null]],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"trash icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"action\",\"confirm\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-sponsor-options.hbs"
    }
  });
  _exports.default = _default;
});