define("open-event-frontend/templates/components/ui-table/cell/cell-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <a href="{{@record}}" target="_blank" rel="nofollow noreferrer">{{@record}}</a>
  
  */
  {
    "id": "8RSXG8uq",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[10,\"a\"],[15,6,[31,[[32,1]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"nofollow noreferrer\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-url.hbs"
    }
  });
  _exports.default = _default;
});