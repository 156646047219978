define("open-event-frontend/templates/my-tickets/past", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="row">
    <div class="sixteen wide column">
      <TabbedNavigation @isNonPointing={{true}}>
        <LinkTo @route="my-tickets.past" @model="completed" class="item">
          {{t 'Completed Orders'}}
        </LinkTo>
      </TabbedNavigation>
      <br>
      {{#each this.model as |order|}}
        <OrderCard @order={{order}} @restrictTicketCancellation={{true}}>
        </OrderCard>
        <div class="ui hidden divider"></div>
      {{else}}
        <div class="ui disabled header">{{t 'No tickets found'}}</div>
      {{/each}}
      <InfinityLoader @infinityModel={{this.model}}>
        <div class="ui loading very padded basic segment">
        </div>
        {{this.infintyModel.reachedInfinity}}
      </InfinityLoader>
    </div>
  </div>
  */
  {
    "id": "64xBmVUY",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"tabbed-navigation\",[],[[\"@isNonPointing\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"my-tickets.past\",\"completed\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"Completed Orders\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"order-card\",[],[[\"@order\",\"@restrictTicketCancellation\"],[[32,1],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui hidden divider\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"ui disabled header\"],[12],[1,[30,[36,0],[\"No tickets found\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"infinity-loader\",[],[[\"@infinityModel\"],[[32,0,[\"model\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui loading very padded basic segment\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[32,0,[\"infintyModel\",\"reachedInfinity\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/my-tickets/past.hbs"
    }
  });
  _exports.default = _default;
});