define("open-event-frontend/templates/components/ui-table/cell/cell-simple-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.extraRecords.timezone}}
    <span>
      {{general-date this.record 'date-time-tz-short' tz=this.extraRecords.timezone}}
    </span>
  {{else if this.record}}
    <span>
      {{general-date this.record (if this.props.options.dateFormat this.props.options.dateFormat 'date-time-short')}}
    </span>
  {{/if}}
  
  */
  {
    "id": "mjvxqnNX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"extraRecords\",\"timezone\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"record\"]],\"date-time-tz-short\"],[[\"tz\"],[[32,0,[\"extraRecords\",\"timezone\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"record\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"record\"]],[30,[36,0],[[32,0,[\"props\",\"options\",\"dateFormat\"]],[32,0,[\"props\",\"options\",\"dateFormat\"]],\"date-time-short\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"general-date\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-simple-date.hbs"
    }
  });
  _exports.default = _default;
});