define("open-event-frontend/templates/components/events/view/export/download-common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <h3 class="ui header">
    <div class="content">
      {{t 'Download Event as {{file}}' file=this.downloadType}}
    </div>
    <div class="sub header">
      {{t 'Once the event is live and the schedule is published, {{file}} version of the schedule will be available at:' file=this.downloadType}}
    </div>
  </h3>
  <div class="ui secondary segment url x-scrollable">
    <pre>{{this.displayUrl}}</pre>
  </div>
  <a class="ui link" href="#" onclick={{action 'startExportTask'}}> {{t 'Alternatively, you can download the {{file}} here' file=this.downloadType}}</a>
  <div class="ui basic segment no left padding">
    <a href="{{this.eventDownloadUrl}}" class="ui blue button {{if this.isDownloadDisabled 'disabled' ''}} {{if this.isLoading 'loading'}}">
      {{t 'Download'}} {{this.downloadType}}
    </a>
  </div>
  */
  {
    "id": "eLamSKmg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"ui header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Download Event as {{file}}\"],[[\"file\"],[[32,0,[\"downloadType\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sub header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Once the event is live and the schedule is published, {{file}} version of the schedule will be available at:\"],[[\"file\"],[[32,0,[\"downloadType\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui secondary segment url x-scrollable\"],[12],[2,\"\\n  \"],[10,\"pre\"],[12],[1,[32,0,[\"displayUrl\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"a\"],[14,0,\"ui link\"],[14,6,\"#\"],[15,\"onclick\",[30,[36,1],[[32,0],\"startExportTask\"],null]],[12],[2,\" \"],[1,[30,[36,0],[\"Alternatively, you can download the {{file}} here\"],[[\"file\"],[[32,0,[\"downloadType\"]]]]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui basic segment no left padding\"],[12],[2,\"\\n  \"],[10,\"a\"],[15,6,[31,[[32,0,[\"eventDownloadUrl\"]]]]],[15,0,[31,[\"ui blue button \",[30,[36,2],[[32,0,[\"isDownloadDisabled\"]],\"disabled\",\"\"],null],\" \",[30,[36,2],[[32,0,[\"isLoading\"]],\"loading\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Download\"],null]],[2,\" \"],[1,[32,0,[\"downloadType\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/events/view/export/download-common.hbs"
    }
  });
  _exports.default = _default;
});