define("open-event-frontend/templates/components/ui-table/cell/admin/sales/status/cell-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <span>
    US$ {{format-money this.record}}
  </span>
  
  */
  {
    "id": "RsZiPmGB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[12],[2,\"\\n  US$ \"],[1,[30,[36,0],[[32,0,[\"record\"]]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"format-money\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/sales/status/cell-amount.hbs"
    }
  });
  _exports.default = _default;
});