define("open-event-frontend/models/discount-code", ["exports", "ember-data/attr", "open-event-frontend/models/base", "moment-timezone", "ember-data/relationships", "open-event-frontend/utils/computed-helpers"], function (_exports, _attr, _base, _momentTimezone, _relationships, _computedHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Two different forms of discount code can exist. (Both use the same model)
   *
   * 1. Created by the super admin and can be applied to whole events when creating an event.
   *    (this discount is on the total ticket fees that goes to eventyay)
   *
   * 2. Created by an event's organizer to be used on that event's tickets
   */
  var _default = _base.default.extend({
    code: (0, _attr.default)('string'),
    type: (0, _attr.default)('string', {
      defaultValue: 'amount'
    }),
    value: (0, _attr.default)('number'),
    ticketsNumber: (0, _attr.default)('number', {
      defaultValue: 10
    }),
    // For form (1) this holds the max. times this can be used for events
    minQuantity: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    // Not of any significance for form (1)
    maxQuantity: (0, _attr.default)('number', {
      defaultValue: 100000
    }),
    validFrom: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return (0, _momentTimezone.default)().startOf('day');
      }
    }),
    discountUrl: (0, _attr.default)('string'),
    validTill: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return (0, _momentTimezone.default)().add(1, 'months').startOf('day');
      }
    }),
    usedFor: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    createdAt: (0, _attr.default)('moment'),
    tickets: (0, _relationships.hasMany)('ticket'),
    orders: (0, _relationships.hasMany)('order'),
    isExpired: Ember.computed('validTill', function () {
      return new Date() > new Date(this.validTill);
    }),
    event: (0, _relationships.belongsTo)('event', {
      inverse: 'discountCodes'
    }),
    // The event that this discount code belongs to [Form (2)]
    events: (0, _relationships.hasMany)('event', {
      inverse: 'discountCode'
    }),
    // The events that this discount code has been applied to [Form (1)]
    marketer: (0, _relationships.belongsTo)('user'),
    validFromDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('validFrom', 'date', 'validTill'),
    validFromTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('validFrom', 'time', 'validTill'),
    validTillDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('validTill', 'date'),
    validTillTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('validTill', 'time')
  });
  _exports.default = _default;
});