define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui tiny header">
    <LinkTo @route="orders.view" @model={{this.record.identifier}}>{{this.record.identifier}}</LinkTo>
    <div class="ui basic mini {{order-color this.record.status}} label attendees-margin">
      {{this.record.status}}
    </div>
    <div class="sub header">
      {{#if this.record.paidVia}}
        <span class="weight-800">
          {{t 'Payment via'}} {{this.record.paidVia}}
        </span>
      {{/if}}
      {{#if (eq this.record.status 'completed')}}
        {{general-date this.record.completedAt 'date-time-short'}}
        {{moment-from-now this.record.completedAt}}
      {{else}}
        {{general-date this.record.createdAt 'date-time-short'}}
        {{moment-from-now this.record.createdAt}}
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "qZCX2FM7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui tiny header\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\",\"@model\"],[\"orders.view\",[32,0,[\"record\",\"identifier\"]]]],[[\"default\"],[{\"statements\":[[1,[32,0,[\"record\",\"identifier\"]]]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"ui basic mini \",[30,[36,3],[[32,0,[\"record\",\"status\"]]],null],\" label attendees-margin\"]]],[12],[2,\"\\n    \"],[1,[32,0,[\"record\",\"status\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sub header\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"record\",\"paidVia\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"weight-800\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"Payment via\"],null]],[2,\" \"],[1,[32,0,[\"record\",\"paidVia\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,5],[[32,0,[\"record\",\"status\"]],\"completed\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,0,[\"record\",\"completedAt\"]],\"date-time-short\"],null]],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"record\",\"completedAt\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,0,[\"record\",\"createdAt\"]],\"date-time-short\"],null]],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"record\",\"createdAt\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"general-date\",\"moment-from-now\",\"t\",\"order-color\",\"if\",\"eq\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-order.hbs"
    }
  });
  _exports.default = _default;
});