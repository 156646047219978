define("open-event-frontend/components/forms/wizard/badge-form", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/mixins/event-wizard", "lodash-es", "open-event-frontend/utils/dictionary/badge-field", "open-event-frontend/utils/dictionary/badge-custom-fields"], function (_exports, _form, _eventWizard, _lodashEs, _badgeField, _badgeCustomFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import QRCode from 'qrcode';
  var _default = Ember.Component.extend(_form.default, _eventWizard.default, {
    currentSelected: [],
    ignoreCustomField: [],
    isExpandedBadge: true,
    init: function init() {
      this._super.apply(this, arguments);
      this.removeBadgeField = this.removeBadgeField.bind(this);
      this.currentSelected = this.selectedTicket;
      this.badgeForms = this.data;
      this.ignoreCustomField = [];
    },
    ticketsEnable: Ember.computed('tickets', 'selectedTicket.@each', function () {
      return (0, _lodashEs.union)(this.tickets || [], this.selectedTicket || []);
    }),
    fixedFields: Ember.computed('data.customForms.@each', function () {
      var _this$data$customForm;
      return (_this$data$customForm = this.data.customForms) === null || _this$data$customForm === void 0 ? void 0 : _this$data$customForm.filter(function (field) {
        return field.isFixed;
      });
    }),
    badgeFields: Ember.computed('data.badgeFields.@each.is_deleted', function () {
      var _this$data$badgeField;
      return (_this$data$badgeField = this.data.badgeFields) === null || _this$data$badgeField === void 0 ? void 0 : _this$data$badgeField.filter(function (field) {
        return !field.is_deleted;
      });
    }),
    includeCustomField: Ember.computed('ignoreCustomField.@each', 'selectedTicket.@each', 'data.badgeFields.@each.is_deleted', 'data.badgeFields.@each', function () {
      var _this = this;
      return this.customFormsValid.filter(function (item) {
        return !_this.ignoreCustomField.includes(item.name) && !_this.data.badgeFields.map(function (field) {
          return !field.is_deleted && field.custom_field;
        }).includes(item.name);
      });
    }),
    customFormsValid: Ember.computed('selectedTicket.@each', 'data.badgeFields.@each', function () {
      var formIds = this.selectedTicket.map(function (item) {
        return item.formID;
      });
      var validForms = this.customForms.filter(function (form) {
        return formIds.includes(form.formID) && form.isIncluded || form.isFixed;
      });
      validForms.push({
        'name': 'QR',
        'field_identifier': 'qr'
      });
      return (0, _lodashEs.union)((0, _lodashEs.sortBy)(validForms));
    }),
    qrFields: Ember.computed('selectedTicket.@each', 'data.badgeFields.@each', function () {
      // return sortBy(this.data.badgeFields.filter(field => !field.is_deleted && field.custom_field !== 'QR').map(field => field.custom_field));
      var formIds = this.selectedTicket.map(function (item) {
        return item.formID;
      });
      var validForms = this.customForms.filter(function (form) {
        return formIds.includes(form.formID) && form.isIncluded || form.isFixed;
      });
      return (0, _lodashEs.union)((0, _lodashEs.sortBy)(validForms));
    }),
    ticketNames: Ember.computed('selectedTicket.@each', function () {
      var ticketNames = '';
      this.selectedTicket.forEach(function (ticket) {
        if (ticketNames) {
          ticketNames += ', ' + ticket.name;
        } else {
          ticketNames = ticket.name;
        }
      });
      return ticketNames.trim();
    }),
    selectChanges: Ember.observer('selectedTicket.@each', function () {
      var ticketsDetails = this.selectedTicket;
      var currentSelected = this.currentSelected;
      var added = currentSelected.length < ticketsDetails.length;
      var changed = [];
      if (added) {
        changed = ticketsDetails.filter(function (item) {
          return !currentSelected.includes(item);
        });
      } else {
        changed = currentSelected.filter(function (item) {
          return !ticketsDetails.includes(item);
        });
      }
      this.currentSelected = ticketsDetails;
      this.get('onFormUpdateTicket')({
        added: added,
        changed: changed,
        formID: this.id
      });
    }),
    editableFields: Ember.computed('data.customForms.@each', function () {
      var _this$customForms;
      var filteredFields = (_this$customForms = this.customForms) === null || _this$customForms === void 0 ? void 0 : _this$customForms.filter(function (field) {
        return !field.isFixed;
      });
      var fields = (0, _lodashEs.sortBy)(filteredFields, ['isComplex', 'name']);
      return (0, _lodashEs.sortBy)(fields, ['position']);
    }),
    revertChanges: Ember.observer('event.isBadgesEnabled', function () {
      if (!this.event.isBadgesEnabled) {
        this.editableFields.forEach(function (field) {
          return field.set('isRequired', false);
        });
      }
    }),
    showEditColumn: Ember.computed('editableFields.@each', function () {
      var _this$editableFields;
      return (_this$editableFields = this.editableFields) === null || _this$editableFields === void 0 ? void 0 : _this$editableFields.some(function (field) {
        return field.isComplex;
      });
    }),
    disableAddBadgeField: Ember.computed('badgeForms.badgeFields.@each.is_deleted', function () {
      var _this$badgeForms$badg, _this$customForms2;
      return ((_this$badgeForms$badg = this.badgeForms.badgeFields) === null || _this$badgeForms$badg === void 0 ? void 0 : _this$badgeForms$badg.filter(function (field) {
        return !field.is_deleted;
      }).length) - 1 === ((_this$customForms2 = this.customForms) === null || _this$customForms2 === void 0 ? void 0 : _this$customForms2.filter(function (field) {
        return field.isIncluded;
      }).length);
    }),
    removeBadgeField: function removeBadgeField(badgeField) {
      this.ignoreCustomField.removeObject(badgeField.custom_field);
    },
    onSelectedCustomField: function onSelectedCustomField(old_code, new_code) {
      if (old_code) {
        this.ignoreCustomField.removeObject(old_code);
      }
      if (new_code) {
        this.ignoreCustomField.pushObject(new_code);
      }
    },
    getsampleData: Ember.computed('sampleData', function () {
      return {
        name: 'Barack Obama',
        organisation: 'US',
        position: 'President'
      };
    }),
    getBadgeSize: Ember.computed('badgeForms.badgeSize', 'badgeForms.badgeOrientation', function () {
      var _this2 = this;
      var height = 4;
      var lineHeight = 3;
      var selectedSize = _badgeField.badgeSize.filter(function (badge) {
        return badge.name === _this2.badgeForms.badgeSize;
      })[0];
      if (selectedSize) {
        var _ref = [selectedSize.height, selectedSize.lineHeight];
        height = _ref[0];
        lineHeight = _ref[1];
      }
      if (this.badgeForms.badgeOrientation === 'Landscape') {
        var _ref2 = [lineHeight, height];
        height = _ref2[0];
        lineHeight = _ref2[1];
      }
      return {
        height: height,
        lineHeight: lineHeight
      };
    }),
    getBadgeImgOrColor: Ember.computed('badgeForms.badgeColor', 'badgeForms.badgeImageURL', function () {
      var style = '';
      if (this.badgeForms.badgeColor) {
        style += "background-color: ".concat(this.badgeForms.badgeColor);
      }
      if (this.badgeForms.badgeImageURL) {
        style += "background-image: url(".concat(this.badgeForms.badgeImageURL, "); background-size: cover;");
      }
      return Ember.String.htmlSafe(style);
    }),
    // generateQRCode(element) {
    //   const text = 'Hello world'; // get the text to encode from the component argument or use a default value
    //   const test = document.getElementById('badge_qr');
    //   QRCode.toCanvas(test, text, function(error) { // use the qrcode method to create a canvas element with the QR code
    //     if (error) {
    //       console.error(error); // handle any errors
    //     } else {
    //       console.log('QR code generated!'); // log success
    //     }
    //   });
    // },

    actions: {
      removeField: function removeField(field) {
        field.deleteRecord();
      },
      addBadgeField: function addBadgeField() {
        this.badgeForms.badgeFields.pushObject(this.store.createRecord('badge-field-form', {
          badge_id: this.data.badgeID,
          is_deleted: false,
          badgeCustomFields: _badgeCustomFields.badgeCustomFields
        }));
      },
      mutateBadgeSize: function mutateBadgeSize(value) {
        var _this3 = this;
        _badgeField.badgeSize.forEach(function (badge) {
          if (badge.name === value) {
            _this3.badgeForms.badgeSize = badge.name;
          }
        });
      },
      onChildChangeCustomField: function onChildChangeCustomField(old_code, new_code) {
        this.onSelectedCustomField(old_code, new_code);
      },
      toggleBadge: function toggleBadge() {
        if (!this.isExpandedBadge) {
          this.set('isExpandedBadge', true);
        } else {
          this.set('isExpandedBadge', false);
        }
      },
      mutatetickets: function mutatetickets(ticket) {
        this.selectedTicket.pushObject(ticket);
      }
    }
  });
  _exports.default = _default;
});