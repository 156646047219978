define("open-event-frontend/templates/public/coc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <h1 class="ui header">
    {{t 'Code of Conduct'}}
  </h1>
  <div class="ui divider"></div>
  {{sanitize this.model.codeOfConduct}}
  
  */
  {
    "id": "GzODDzw8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"ui header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"Code of Conduct\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui divider\"],[12],[13],[2,\"\\n\"],[1,[30,[36,1],[[32,0,[\"model\",\"codeOfConduct\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"sanitize\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/coc.hbs"
    }
  });
  _exports.default = _default;
});