define("open-event-frontend/utils/errors", ["exports", "lodash-es"], function (_exports, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getErrorMessage = void 0;
  /**
   * A recursive method to parse and retrieve an error string from a BE response
   * Since the BE seems to be having different response format for errors in
   * different endpoints
   *
   * Usage: import { getErrorMessage } from 'open-event-frontend/utils/errors';
   *
   * @param input {any}
   * @param defaultError {string}
   * @param attempt
   */
  var getErrorMessage = function getErrorMessage(input) {
    var defaultError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Unable to load data';
    var attempt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    try {
      if (!input) {
        return defaultError;
      }
      if (attempt > 2) {
        return JSON.stringify(input);
      }
      if ((0, _lodashEs.isString)(input) || (0, _lodashEs.isNumber)(input)) {
        var error = input;
        try {
          error = JSON.parse(input);
        } catch (ignored) {/* ignored */}
        if ((0, _lodashEs.isString)(error) || (0, _lodashEs.isNumber)(_lodashEs.isNumber)) {
          return error;
        }
        return getErrorMessage(error, ++attempt);
      }
      if (!(0, _lodashEs.isArray)(input) && (0, _lodashEs.isObjectLike)(input)) {
        input = input.error ? input.error : input.message ? input.message : input.messages ? input.messages : input;
      } else if ((0, _lodashEs.isArray)(input)) {
        return input.join('. ');
      }
      return getErrorMessage(input, ++attempt);
    } catch (e) {
      console.warn('utils/errors.getErrorMessage', e);
      return defaultError;
    }
  };
  _exports.getErrorMessage = getErrorMessage;
});