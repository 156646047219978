define("open-event-frontend/models/badge-form", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    /**
     * Attributes
     */
    badgeID: (0, _attr.default)('string'),
    isShowingSampleData: (0, _attr.default)('boolean'),
    badgeSize: (0, _attr.default)('string', {
      defaultValue: '4 x 6 inch (101.6 x 152.4 mm)'
    }),
    badgeColor: (0, _attr.default)('string'),
    badgeImageUrl: (0, _attr.default)('string'),
    badgeOrientation: (0, _attr.default)('string', {
      defaultValue: 'Portrait'
    }),
    badgeQRFields: (0, _attr.default)(),
    badgeFields: (0, _attr.default)(),
    selectedImage: (0, _attr.default)('string'),
    imageUrl: (0, _attr.default)('string'),
    /**
     * Relationships
     */
    event: (0, _relationships.belongsTo)('event'),
    ticket: (0, _relationships.hasMany)('ticket')
  });
  _exports.default = _default;
});