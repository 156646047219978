define("open-event-frontend/templates/admin/users/view/tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui container">
    <h1 class="ui header">{{t 'My Tickets'}}</h1>
    <div class="ui grid">
      <div class="row">
        <div class="sixteen wide column">
          <TabbedNavigation>
            <LinkTo @route="admin.users.view.tickets.list" @model="upcoming" class="item">
              {{t 'Upcoming Events'}}
            </LinkTo>
            <LinkTo @route="admin.users.view.tickets.list" @model="past" class="item">
              {{t 'Past Events'}}
            </LinkTo>
          </TabbedNavigation>
        </div>
      </div>
      {{outlet}}
    </div>
  </div>
  
  */
  {
    "id": "AgOxXPzh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,[30,[36,0],[\"My Tickets\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui grid\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n        \"],[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.tickets.list\",\"upcoming\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Upcoming Events\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\",\"@model\"],[\"admin.users.view.tickets.list\",\"past\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"Past Events\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/users/view/tickets.hbs"
    }
  });
  _exports.default = _default;
});