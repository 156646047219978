define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/orders/cell-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.record}}
    <div>
      {{general-date this.record 'date-time-short'}}
    </div>
  {{else}}
    <div>
      {{general-date this.extraRecords.createdAt 'date-time-short'}}
    </div>
  {{/if}}
  
  */
  {
    "id": "nDkuIaM2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"record\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"record\"]],\"date-time-short\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"extraRecords\",\"createdAt\"]],\"date-time-short\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"general-date\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/orders/cell-date.hbs"
    }
  });
  _exports.default = _default;
});