define("open-event-frontend/templates/events/view/tickets/discount-codes/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <div class="ui stackable grid">
    <div class="row">
      <h2 class="ui header column">{{t 'Edit a Discount Code'}}</h2>
    </div>
    <div class="row">
      <div class="sixteen wide column">
        <Forms::Events::View::CreateDiscountCode @save="saveCode" @data={{this.model.discountCode}} @event={{this.model.event}} @tickets={{this.model.tickets}} />
      </div>
    </div>
  </div>
  
  */
  {
    "id": "AzGuDuoR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui stackable grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header column\"],[12],[1,[30,[36,0],[\"Edit a Discount Code\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[8,\"forms/events/view/create-discount-code\",[],[[\"@save\",\"@data\",\"@event\",\"@tickets\"],[\"saveCode\",[32,0,[\"model\",\"discountCode\"]],[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"tickets\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/tickets/discount-codes/edit.hbs"
    }
  });
  _exports.default = _default;
});