define("open-event-frontend/models/order-statistics-ticket", ["exports", "ember-data/attr", "open-event-frontend/models/base"], function (_exports, _attr, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    orders: (0, _attr.default)(),
    tickets: (0, _attr.default)(),
    sales: (0, _attr.default)()
  });
  _exports.default = _default;
});