define("open-event-frontend/models/role-invite", ["exports", "ember-data/attr", "open-event-frontend/models/base", "moment-timezone", "ember-data/relationships"], function (_exports, _attr, _base, _momentTimezone, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var detectedTimezone = _momentTimezone.default.tz.guess();
  var _default = _base.default.extend({
    email: (0, _attr.default)('string'),
    hash: (0, _attr.default)('string'),
    status: (0, _attr.default)('string', {
      defaultValue: 'pending'
    }),
    createdAt: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return _momentTimezone.default.tz(detectedTimezone);
      }
    }),
    roleName: (0, _attr.default)('string'),
    event: (0, _relationships.belongsTo)('event'),
    role: (0, _relationships.belongsTo)('role')
  });
  _exports.default = _default;
});