define("open-event-frontend/models/attendee", ["exports", "ember-data/attr", "ember-data/relationships", "open-event-frontend/models/base"], function (_exports, _attr, _relationships, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    /**
     * Attributes
     */
    sameAsBuyer: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    city: (0, _attr.default)('string'),
    firstname: (0, _attr.default)('string'),
    lastname: (0, _attr.default)('string'),
    isCheckedIn: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    checkinTimes: (0, _attr.default)('string'),
    checkoutTimes: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    pdfUrl: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    jobTitle: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    company: (0, _attr.default)('string'),
    taxBusinessInfo: (0, _attr.default)('string'),
    billingAddress: (0, _attr.default)('string'),
    homeAddress: (0, _attr.default)('string'),
    shippingAddress: (0, _attr.default)('string'),
    workAddress: (0, _attr.default)('string'),
    workPhone: (0, _attr.default)('string'),
    website: (0, _attr.default)('string'),
    blog: (0, _attr.default)('string'),
    twitter: (0, _attr.default)('string'),
    facebook: (0, _attr.default)('string'),
    github: (0, _attr.default)('string'),
    instagram: (0, _attr.default)('string'),
    linkedin: (0, _attr.default)('string'),
    gender: (0, _attr.default)('string'),
    ageGroup: (0, _attr.default)('string'),
    acceptReceiveEmails: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    acceptVideoRecording: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    acceptShareDetails: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    birthDate: (0, _attr.default)('moment'),
    complexFieldValues: (0, _attr.default)(),
    is_consent_of_refund_policy: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    native_language: (0, _attr.default)('string'),
    fluent_language: (0, _attr.default)('string'),
    home_wiki: (0, _attr.default)('string'),
    is_consent_form_field: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    is_consent_form_field_photo: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    is_consent_form_field_email: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    wiki_scholarship: (0, _attr.default)('string'),
    tagId: (0, _attr.default)('number'),
    /**
     * Relationships
     */
    event: (0, _relationships.belongsTo)('event'),
    order: (0, _relationships.belongsTo)('order'),
    ticket: (0, _relationships.belongsTo)('ticket'),
    user: (0, _relationships.belongsTo)('user'),
    ready: function ready() {
      if (!this.complexFieldValues) {
        this.complexFieldValues = {};
      }
    }
  });
  _exports.default = _default;
});