define("open-event-frontend/utils/dictionary/translate-language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.translateLanguages = _exports.LANGUAGE_CODE_ENUM = void 0;
  var LANGUAGE_CODE_ENUM = {
    ENGLISH: 'en',
    FRENCH: 'fr',
    GERMAN: 'de',
    JAPANESE: 'ja',
    RUSSIAN: 'ru',
    CHINESE_SIMPLIFIED: 'zh_Hans',
    CHINESE_TRADITIONAL: 'zh_Hant',
    INDONESIAN: 'id',
    POLSKI: 'pl',
    BENGALI: 'bn',
    SPANISH: 'es',
    THAILAND: 'th',
    VIETNAMESE: 'vi',
    KOREAN: 'ko',
    ARABIC: 'ar',
    HINDI: 'hi',
    SWEDISH: 'sv',
    NORWEGIAN: 'nb_NO'
  };
  _exports.LANGUAGE_CODE_ENUM = LANGUAGE_CODE_ENUM;
  var translateLanguages = [{
    name: 'عربي',
    code: LANGUAGE_CODE_ENUM.ARABIC,
    isChecked: false
  }, {
    name: 'বাংলা',
    code: LANGUAGE_CODE_ENUM.BENGALI,
    isChecked: false
  }, {
    name: 'Deutsch',
    code: LANGUAGE_CODE_ENUM.GERMAN,
    isChecked: false
  }, {
    name: 'English',
    code: LANGUAGE_CODE_ENUM.ENGLISH,
    isChecked: false
  }, {
    name: 'Español',
    code: LANGUAGE_CODE_ENUM.SPANISH,
    isChecked: false
  }, {
    name: 'Français',
    code: LANGUAGE_CODE_ENUM.FRENCH,
    isChecked: false
  }, {
    name: 'हिंदी',
    code: LANGUAGE_CODE_ENUM.HINDI,
    isChecked: false
  }, {
    name: 'Bahasa Indonesia',
    code: LANGUAGE_CODE_ENUM.INDONESIAN,
    isChecked: false
  }, {
    name: '日本語',
    code: LANGUAGE_CODE_ENUM.JAPANESE,
    isChecked: false
  }, {
    name: '한국어',
    code: LANGUAGE_CODE_ENUM.KOREAN,
    isChecked: false
  }, {
    name: 'Bokmål',
    code: LANGUAGE_CODE_ENUM.NORWEGIAN,
    isChecked: false
  }, {
    name: 'Polski',
    code: LANGUAGE_CODE_ENUM.POLSKI,
    isChecked: false
  }, {
    name: 'Русский',
    code: LANGUAGE_CODE_ENUM.RUSSIAN,
    isChecked: false
  }, {
    name: 'Svenska',
    code: LANGUAGE_CODE_ENUM.SWEDISH,
    isChecked: false
  }, {
    name: 'ไทย',
    code: LANGUAGE_CODE_ENUM.THAILAND,
    isChecked: false
  }, {
    name: 'Tiếng Việt',
    code: LANGUAGE_CODE_ENUM.VIETNAMESE,
    isChecked: false
  }, {
    name: '中文（简体)',
    code: LANGUAGE_CODE_ENUM.CHINESE_SIMPLIFIED,
    isChecked: false
  }, {
    name: '中文（繁體)',
    code: LANGUAGE_CODE_ENUM.CHINESE_TRADITIONAL,
    isChecked: false
  }];
  _exports.translateLanguages = translateLanguages;
});