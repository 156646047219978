define("open-event-frontend/utils/dictionary/genders", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.genders = void 0;
  var genders = [{
    name: _text.tn.t('Man'),
    code: 'man',
    isChecked: false
  }, {
    name: _text.tn.t('Woman'),
    code: 'woman',
    isChecked: false
  }, {
    name: _text.tn.t('Transgender'),
    code: 'transgender',
    isChecked: false
  }, {
    name: _text.tn.t('Non-binary'),
    code: 'non-binary',
    isChecked: false
  }, {
    name: _text.tn.t('Genderqueer'),
    code: 'genderqueer',
    isChecked: false
  }, {
    name: _text.tn.t('An identity not listed here'),
    code: 'identity-not-listed-here',
    isChecked: false
  }, {
    name: _text.tn.t('I prefer not to say'),
    code: 'prefer-not-to-say',
    isChecked: false
  }];
  _exports.genders = genders;
});