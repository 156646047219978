define("open-event-frontend/templates/admin/settings/images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Forms::Admin::Settings::ImagesForm @image={{this.model}} @save="saveImages" @isLoading={{this.isLoading}} />
  
  */
  {
    "id": "LXpyFXrs",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/admin/settings/images-form\",[],[[\"@image\",\"@save\",\"@isLoading\"],[[32,0,[\"model\"]],\"saveImages\",[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/settings/images.hbs"
    }
  });
  _exports.default = _default;
});