define("open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if (eq this.extraRecords.order.status 'completed')}}
    <UiDropdown @class="d-flex items-center pl-4 fluid multiple selection">
      <div class="default">
        {{t 'Checkin'}}
      </div>
      <i class="dropdown icon"></i>
      <div class="menu">
        {{#each this.allDates as |date|}}
          <UiCheckbox 
            @class="item"
            @label={{date}}
            @checked={{if (includes this.currentlyChecked date) true}}
            @onChange={{action this.props.actions.toggleCheckIn this.record date (includes this.currentlyChecked date)}}
          />
        {{/each}}
      </div>
    </UiDropdown>
  {{/if}}
  
  */
  {
    "id": "dicOMchv",
    "block": "{\"symbols\":[\"date\"],\"statements\":[[6,[37,1],[[30,[36,6],[[32,0,[\"extraRecords\",\"order\",\"status\"]],\"completed\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui-dropdown\",[],[[\"@class\"],[\"d-flex items-center pl-4 fluid multiple selection\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"default\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"Checkin\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"allDates\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"ui-checkbox\",[],[[\"@class\",\"@label\",\"@checked\",\"@onChange\"],[\"item\",[32,1],[30,[36,1],[[30,[36,0],[[32,0,[\"currentlyChecked\"]],[32,1]],null],true],null],[30,[36,2],[[32,0],[32,0,[\"props\",\"actions\",\"toggleCheckIn\"]],[32,0,[\"record\"]],[32,1],[30,[36,0],[[32,0,[\"currentlyChecked\"]],[32,1]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"includes\",\"if\",\"action\",\"t\",\"-track-array\",\"each\",\"eq\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/tickets/attendees/cell-action.hbs"
    }
  });
  _exports.default = _default;
});