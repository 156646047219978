define("open-event-frontend/components/forms/wizard/custom-form-ticket", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/mixins/event-wizard", "lodash-es"], function (_exports, _form, _eventWizard, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_form.default, _eventWizard.default, {
    currentSelected: [],
    trackedfieldEditing: [],
    init: function init() {
      this._super.apply(this, arguments);
      this.currentSelected = this.data.ticketsDetails;
    },
    ticketsEnable: Ember.computed('tickets', 'ticketsDetails', function () {
      return (0, _lodashEs.union)(this.tickets || [], this.data.ticketsDetails || []);
    }),
    fixedFields: Ember.computed('data.customForms.@each', function () {
      var _this$data$customForm;
      return (_this$data$customForm = this.data.customForms) === null || _this$data$customForm === void 0 ? void 0 : _this$data$customForm.filter(function (field) {
        return field.isFixed;
      });
    }),
    selectChanges: Ember.observer('data.ticketsDetails', function () {
      var ticketsDetails = this.data.ticketsDetails;
      var currentSelected = this.currentSelected;
      var added = currentSelected.length < ticketsDetails.length;
      var changed = [];
      if (added) {
        changed = ticketsDetails.filter(function (item) {
          return !currentSelected.includes(item);
        });
      } else {
        changed = currentSelected.filter(function (item) {
          return !ticketsDetails.includes(item);
        });
      }
      this.currentSelected = ticketsDetails;
      this.get('onFormUpdateTicket')({
        added: added,
        changed: changed,
        formID: this.id
      });
    }),
    editableFields: Ember.computed('data.customForms.@each', function () {
      var _this$data$customForm2;
      var filteredFields = (_this$data$customForm2 = this.data.customForms) === null || _this$data$customForm2 === void 0 ? void 0 : _this$data$customForm2.filter(function (field) {
        return !field.isFixed;
      });
      var fields = (0, _lodashEs.sortBy)(filteredFields, ['isComplex', 'name']);
      return (0, _lodashEs.sortBy)(fields, ['position']);
    }),
    revertChanges: Ember.observer('data.event.isTicketFormEnabled', function () {
      if (!this.event.isTicketFormEnabled) {
        this.editableFields.forEach(function (field) {
          return field.set('isRequired', false);
        });
      }
    }),
    showEditColumn: Ember.computed('editableFields.@each', function () {
      var _this$editableFields;
      return (_this$editableFields = this.editableFields) === null || _this$editableFields === void 0 ? void 0 : _this$editableFields.some(function (field) {
        return field.isComplex;
      });
    }),
    getEditingField: Ember.computed('fieldEditing', function () {
      return this.fieldEditing;
    }),
    actions: {
      removeField: function removeField(field) {
        field.deleteRecord();
      },
      setEditingField: function setEditingField(name) {
        this.set('fieldEditing', name);
      }
    }
  });
  _exports.default = _default;
});