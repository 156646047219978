define("open-event-frontend/templates/components/public/copyright-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.copyright.logoUrl}}
    <img src="{{this.copyright.logoUrl}}" class="copyright-image" alt="{{this.copyright.licence}}">
    <br>
  {{/if}}
  <div class='copyright text'>
    {{#if this.copyright.licence}}
      <p>{{t 'This event is licenced under'}} <a href="{{this.copyright.licenceUrl}}"> {{this.copyright.licence}} </a>.</p>
    {{/if}}
  </div>
  
  */
  {
    "id": "KH7BO4j8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"copyright\",\"logoUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"src\",[31,[[32,0,[\"copyright\",\"logoUrl\"]]]]],[14,0,\"copyright-image\"],[15,\"alt\",[31,[[32,0,[\"copyright\",\"licence\"]]]]],[12],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"copyright text\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"copyright\",\"licence\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"This event is licenced under\"],null]],[2,\" \"],[10,\"a\"],[15,6,[31,[[32,0,[\"copyright\",\"licenceUrl\"]]]]],[12],[2,\" \"],[1,[32,0,[\"copyright\",\"licence\"]]],[2,\" \"],[13],[2,\".\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/copyright-item.hbs"
    }
  });
  _exports.default = _default;
});