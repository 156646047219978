define("open-event-frontend/utils/dictionary/event", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventTypes = _exports.eventTopics = void 0;
  var eventTypes = [_text.tn.t('Appearance or Signing'), _text.tn.t('Attraction'), _text.tn.t('Camp, Trip, or Retreat'), _text.tn.t('Class, Training, or Workshop'), _text.tn.t('Concert or Performance'), _text.tn.t('Conference'), _text.tn.t('Convention'), _text.tn.t('Dinner or Gala'), _text.tn.t('Festival or Fair'), _text.tn.t('Game or Competition'), _text.tn.t('Meeting or Networking Event'), _text.tn.t('Other'), _text.tn.t('Party or Social Gathering'), _text.tn.t('Race or Endurance Event'), _text.tn.t('Rally'), _text.tn.t('Screening'), _text.tn.t('Seminar or Talk'), _text.tn.t('Tour'), _text.tn.t('Tournament'), _text.tn.t('Tradeshow, Consumer Show, or Expo')];
  _exports.eventTypes = eventTypes;
  var eventTopics = {
    'Auto, Boat & Air': [_text.tn.t('Air'), _text.tn.t('Auto'), _text.tn.t('Boat'), _text.tn.t('Motorcycle/ATV'), _text.tn.t('Other')],
    'Business & Professional': [_text.tn.t('Career'), _text.tn.t('Design'), _text.tn.t('Educators'), _text.tn.t('Environment & Sustainability'), _text.tn.t('Finance'), _text.tn.t('Media'), _text.tn.t('Non Profit & NGOs'), _text.tn.t('Other'), _text.tn.t('Real Estate'), _text.tn.t('Sales & Marketing'), _text.tn.t('Startups & Small Business')],
    'Charity & Causes': [_text.tn.t('Animal Welfare'), _text.tn.t('Disaster Relief'), _text.tn.t('Education'), _text.tn.t('Environment'), _text.tn.t('Healthcare'), _text.tn.t('Human Rights'), _text.tn.t('International Aid'), _text.tn.t('Other'), _text.tn.t('Poverty')],
    'Community & Culture': [_text.tn.t('City/Town'), _text.tn.t('County'), _text.tn.t('Heritage'), _text.tn.t('LGBT'), _text.tn.t('Language'), _text.tn.t('Medieval'), _text.tn.t('Nationality'), _text.tn.t('Other'), _text.tn.t('Renaissance'), _text.tn.t('State')],
    'Family & Education': [_text.tn.t('Alumni'), _text.tn.t('Baby'), _text.tn.t('Children & Youth'), _text.tn.t('Education'), _text.tn.t('Other'), _text.tn.t('Parenting'), _text.tn.t('Parents Association'), _text.tn.t('Reunion')],
    'Fashion & Beauty': [_text.tn.t('Accessories'), _text.tn.t('Beauty'), _text.tn.t('Bridal'), _text.tn.t('Fashion'), _text.tn.t('Other')],
    'Film, Media & Entertainment': [_text.tn.t('Adult'), _text.tn.t('Anime'), _text.tn.t('Comedy'), _text.tn.t('Comics'), _text.tn.t('Film'), _text.tn.t('Gaming'), _text.tn.t('Other'), _text.tn.t('TV')],
    'Food & Drink': [_text.tn.t('Beer'), _text.tn.t('Food'), _text.tn.t('Other'), _text.tn.t('Spirits'), _text.tn.t('Wine')],
    'Government & Politics': [_text.tn.t('County/Municipal Government'), _text.tn.t('Democratic Party'), _text.tn.t('Federal Government'), _text.tn.t('Non-partisan'), _text.tn.t('Other'), _text.tn.t('Other Party'), _text.tn.t('Republican Party'), _text.tn.t('State Government')],
    'Health & Wellness': [_text.tn.t('Medical'), _text.tn.t('Mental health'), _text.tn.t('Other'), _text.tn.t('Personal health'), _text.tn.t('Spa'), _text.tn.t('Yoga')],
    'Hobbies & Special Interest': [_text.tn.t('Adult'), _text.tn.t('Anime/Comics'), _text.tn.t('Books'), _text.tn.t('DIY'), _text.tn.t('Drawing & Painting'), _text.tn.t('Gaming'), _text.tn.t('Knitting'), _text.tn.t('Other'), _text.tn.t('Photography')],
    'Home & Lifestyle': [_text.tn.t('Dating'), _text.tn.t('Home & Garden'), _text.tn.t('Other'), _text.tn.t('Pets & Animals')],
    'Music': [_text.tn.t('Alternative'), _text.tn.t('Blues & Jazz'), _text.tn.t('Classical'), _text.tn.t('Country'), _text.tn.t('Cultural'), _text.tn.t('EDM / Electronic'), _text.tn.t('Folk'), _text.tn.t('Hip Hop / Rap'), _text.tn.t('Indie'), _text.tn.t('Latin'), _text.tn.t('Metal'), _text.tn.t('Opera'), _text.tn.t('Other'), _text.tn.t('Pop'), _text.tn.t('R&B'), _text.tn.t('Reggae'), _text.tn.t('Religious/Spiritual'), _text.tn.t('Rock'), _text.tn.t('Top 40')],
    'Other': [_text.tn.t('Avatar'), _text.tn.t('Logo')],
    'Performing & Visual Arts': [_text.tn.t('Ballet'), _text.tn.t('Comedy'), _text.tn.t('Craft'), _text.tn.t('Dance'), _text.tn.t('Fine Art'), _text.tn.t('Literary Arts'), _text.tn.t('Musical'), _text.tn.t('Opera'), _text.tn.t('Orchestra'), _text.tn.t('Other'), _text.tn.t('Theatre')],
    'Religion & Spirituality': [_text.tn.t('Buddhism'), _text.tn.t('Christianity'), _text.tn.t('Eastern Religion'), _text.tn.t('Islam'), _text.tn.t('Judaism'), _text.tn.t('Mormonism'), _text.tn.t('Mysticism and Occult'), _text.tn.t('New Age'), _text.tn.t('Other'), _text.tn.t('Sikhism')],
    'Science & Technology': [_text.tn.t('Biotech'), _text.tn.t('High Tech'), _text.tn.t('Medicine'), _text.tn.t('Mobile'), _text.tn.t('Other'), _text.tn.t('Robotics'), _text.tn.t('Science'), _text.tn.t('Social Media')],
    'Seasonal & Holiday': [_text.tn.t('Channukah'), _text.tn.t('Christmas'), _text.tn.t('Easter'), _text.tn.t('Fall events'), _text.tn.t('Halloween/Haunt'), _text.tn.t('Independence Day'), _text.tn.t('New Years Eve'), _text.tn.t('Other'), _text.tn.t('St Patricks Day'), _text.tn.t('Thanksgiving')],
    'Sports & Fitness': [_text.tn.t('Baseball'), _text.tn.t('Basketball'), _text.tn.t('Cycling'), _text.tn.t('Exercise'), _text.tn.t('Fighting & Martial Arts'), _text.tn.t('Football'), _text.tn.t('Golf'), _text.tn.t('Hockey'), _text.tn.t('Motorsports'), _text.tn.t('Mountain Biking'), _text.tn.t('Obstacles'), _text.tn.t('Other'), _text.tn.t('Rugby'), _text.tn.t('Running'), _text.tn.t('Snow Sports'), _text.tn.t('Soccer'), _text.tn.t('Swimming & Water Sports'), _text.tn.t('Tennis'), _text.tn.t('Volleyball'), _text.tn.t('Walking'), _text.tn.t('Yoga')],
    'Travel & Outdoor': [_text.tn.t('Canoeing'), _text.tn.t('Climbing'), _text.tn.t('Hiking'), _text.tn.t('Kayaking'), _text.tn.t('Other'), _text.tn.t('Rafting'), _text.tn.t('Travel')]
  };
  _exports.eventTopics = eventTopics;
});