define("open-event-frontend/templates/events/view/exhibitors/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    <Forms::Events::View::Exhibitors::ExhibitorForm
    @event={{this.model.event}}
    @exhibitor={{this.model.exhibitor}}
    @sessions={{this.model.sessions}}
    @sessionsDetails={{this.model.exhibitor.sessions}}
    @isLoading={{this.isLoading}} />
  
  */
  {
    "id": "A9qk2NFk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"forms/events/view/exhibitors/exhibitor-form\",[],[[\"@event\",\"@exhibitor\",\"@sessions\",\"@sessionsDetails\",\"@isLoading\"],[[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"exhibitor\"]],[32,0,[\"model\",\"sessions\"]],[32,0,[\"model\",\"exhibitor\",\"sessions\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/exhibitors/edit.hbs"
    }
  });
  _exports.default = _default;
});