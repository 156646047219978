define("open-event-frontend/models/event-role-permission", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _base.default.extend({
    canDelete: (0, _attr.default)('boolean'),
    canUpdate: (0, _attr.default)('boolean'),
    canCreate: (0, _attr.default)('boolean'),
    canRead: (0, _attr.default)('boolean'),
    role: (0, _relationships.belongsTo)('role'),
    service: (0, _relationships.belongsTo)('service'),
    serviceName: Ember.computed.alias('service.name')
  });
  _exports.default = _default;
});