define("open-event-frontend/torii-providers/stripe", ["exports", "torii/providers/stripe-connect", "torii/configuration", "open-event-frontend/config/environment"], function (_exports, _stripeConnect, _configuration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _stripeConnect.default.extend({
    settings: Ember.inject.service(),
    clientId: _environment.default.environment === 'development' || _environment.default.environment === 'test' ? Ember.computed.alias('settings.stripeTestClientId') : Ember.computed.alias('settings.stripeClientId'),
    redirectUri: (0, _configuration.configurable)('redirectUri', function () {
      return "".concat(window.location.origin, "/torii/redirect.html");
    })
  });
  _exports.default = _default;
});